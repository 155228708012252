import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url = environment.path;
  private auth = this._storage.loadTokenData();
  headers: any;

  constructor(private _http: HttpClient, private _storage: StorageService) {
    this.headers = { 'token': this.auth };
  }

  actualizarHeader() {
    this.auth = this._storage.loadTokenData();
    this.headers = { 'token': this.auth };
  }

  login(datos): Observable<any> {
    return this._http.post(this.url + 'login', datos);
  }

  register(datos): Observable<any> {
    return this._http.post(this.url + 'user', datos);
  }

  getNiveles(): Observable<any> {
    return this._http.get(this.url + 'niveles');
  }

  loginGoogle(id_token: any): Observable<any> {
    let val_sess_ang = environment.auth;
    return this._http.post(this.url + 'logingoogle', { id_token, val_sess_ang });
  }

  registerGoogle(id_token: any): Observable<any> {
    let val_sess_ang = environment.auth;
    return this._http.post(this.url + 'userGoogle', { id_token, val_sess_ang });
  }

  editPiloto(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.put(this.url + 'editpiloto/', datos, { 'headers': this.headers })
  }

  editPilotoNomina(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.put(this.url + 'editpilotonomina/', datos, { 'headers': this.headers })
  }

  getUser(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'user', { 'headers': this.headers })
  }

  getPiloto(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'piloto', { 'headers': this.headers })
  }

  forgotPassword(datos: any): Observable<any> {
    return this._http.put(this.url + 'cambiar-password/', datos)
  }

  newPassword(datos: any): Observable<any> {
    return this._http.put(this.url + 'nuevo-password/', datos)
  }
}
