<div class="container-fluid">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">
            <fieldset>

                <legend>
                    Crear Cuenta
                </legend>

                <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="register();" novalidate="novalidate">

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="text" placeholder="Nombre" formControlName="nombre">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="text" placeholder="Primer Apellido"
                                formControlName="apellido1">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="text" placeholder="Segundo Apellido"
                                formControlName="apellido2">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="text" placeholder="Correo Electrónico"
                                formControlName="email">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="password" placeholder="Contraseña" formControlName="password"
                                name="password" id="password">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="password" placeholder="Repetir contraseña"
                                formControlName="pass2" name="pass2" id="pass2">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <span class="profile-text">Perfil:</span>
                            <select formControlName="nivel" name="nivel" id="nivel">
                                <option *ngFor="let nivel of niveles" [value]="nivel.id">{{nivel.nivel}}</option>
                            </select>
                        </div>
                        <div class="col-6 d-flex justify-content-center align-items-center">
                            <span class="profile-text">Flota:</span>
                            <select formControlName="flota" name="flota" id="flota">
                                <option *ngFor="let flota of flotas" [value]="flota">{{flota}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12 d-flex justify-content-start align-items-center">
                            <input class="disclaimer-input" formControlName="disclaimer" type="checkbox"
                                id="disclaimer">
                            <p class="disclaimer-text">
                                Acepto las condiciones de uso.
                            </p>
                            <span class="disclaimer-link" (click)="verDisclaimer();">Ver</span>
                        </div>
                    </div>

                    <div class="row send-row">
                        <div class="col-12 d-flex justify-content-center">
                            <button
                                [ngClass]="{'btnBack': validarFormulario(), 'btnEnviarDisabled':!validarFormulario()}"
                                type="submit" [disabled]="!validarFormulario()">Enviar</button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 d-flex flex-column align-items-center">
                            <div *ngIf="!validarFormulario()" class="error-info-container d-flex justify-content-start">
                                <i class="error-icon bi bi-x"></i>
                                <p class="error-info">
                                    Es obligatorio rellenar todos los campos.
                                </p>
                            </div>
                            <div *ngIf="!registerForm.controls.email.valid"
                                class="error-info-container d-flex justify-content-start">
                                <i class="error-icon bi bi-x"></i>
                                <p class="error-info">
                                    El email no tiene un formato valido.
                                </p>
                            </div>
                            <div *ngIf="!registerForm.controls.password.valid"
                                class="error-info-container d-flex justify-content-start">
                                <i class="error-icon bi bi-x"></i>
                                <p class="error-info">
                                    La contraseña debe tener mínimo 6 caracteres.
                                </p>
                            </div>
                            <div *ngIf="!passIgual" class="error-info-container d-flex justify-content-start">
                                <i class="error-icon bi bi-x"></i>
                                <p class="error-info">
                                    Las contraseñas deben coincidir.
                                </p>
                            </div>
                            <div *ngIf="!registerForm.controls.disclaimer.value == true"
                                class="error-info-container d-flex justify-content-start">
                                <i class="error-icon bi bi-x"></i>
                                <p class="error-info">
                                    Debes aceptar las condiciones de uso.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row back-row">
                        <div class="col-12 d-flex justify-content-end">
                            <a class="btnBack" [routerLink]="backRoute">Volver</a>
                        </div>
                    </div>

                </form>

            </fieldset>
        </div>
    </div>

</div>