<div #i class="containerCalendario container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-calendar-fill iconoLegend"></i> Calendario
                </legend>

                <div class="rowFiltro row d-flex justify-content-center">
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnIM" (click)="seleccionarEventos('IM')">
                            <i class="fa fa-paper-plane"></i>
                            <br>
                            IM
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnLILNLD" (click)="seleccionarEventos('LI-LN-LD')">
                            <i class="fa fa-bed"></i>
                            <br>
                            LI-LN-LD
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnFR" (click)="seleccionarEventos('FR')">
                            <i class="fa fa-phone"></i>
                            <br>
                            FR
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnSA" (click)="seleccionarEventos('SA')">
                            <i class="fa fa-times-rectangle"></i>
                            <br>
                            SA
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnRV" (click)="seleccionarEventos('RV')">
                            <i class="bi bi-phone-fill"></i>
                            <br>
                            RV
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnSR" (click)="seleccionarEventos('SR')">
                            <i class="fa fa-vcard"></i>
                            <br>
                            SR
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnVA" (click)="seleccionarEventos('VA')">
                            <i class="bi bi-sun-fill"></i>
                            <br>
                            VA
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnERTE" (click)="seleccionarEventos('RT')">
                            <i class="fa fa-handshake-o"></i>
                            <br>
                            ERTE
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnVuelos" (click)="seleccionarEventos('VUELOS')">
                            <i class="fa fa-plane"></i>
                            <br>
                            Vuelos
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnTodos" (click)="seleccionarEventos('TODOS')">
                            <i class="fa fa-align-justify"></i>
                            <br>
                            Todos
                        </button>
                    </div>
                    <div class="colFiltro col-4 col-md-2 d-flex justify-content-center">
                        <button type="button" class="btnFiltro btnEliminar" (click)="eliminarMes()">
                            <i class="bi bi-trash"></i>
                        </button>
                    </div>
                </div>

                <div class="rowCalendario row">
                    <div class="col-12">
                        <div id='calendar' class=""></div>
                        <div [ngClass]="{'mitooltip' : textoPopper != ''}" id="tooltip" role="tooltip">
                            {{textoPopper}}
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12">
                        <span style="color: #2E5EF6; font-weight: bold;">Nacional</span>
                    </div>
                    <div class="col-12">
                        <span style="color: #F4D03F; font-weight: bold;">Internacional</span>
                    </div>
                    <div class="col-12">
                        <span style="color: #900C3F; font-weight: bold;">Larga distancia</span>
                    </div>
                    <div class="col-12">
                        <span style="color: #000000; font-weight: bold;">🚩Inserte hora de FDP</span>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>

<modal #modalEvento>
    <modal-header>
        <p class="tituloModal">
            <i class="bi bi-calendar-range-fill iconoTitulo"></i> Editar Evento
        </p>
    </modal-header>
    <modal-content>
        <form [formGroup]="eventoForm">
            <div class="alert alert-danger"> Fechas / Horas en UTC</div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">cod_fun</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="cod_fun" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Código</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="codigo" type="text">
                </div>
            </div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">extra</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="extra" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha inicio FDP</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_inicio" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora inicio FDP</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_inicio" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Origen</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_inicio" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha final FDP</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_final" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora final FDP</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_final" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Destino</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_final" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Nº Vuelo</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha Block Off</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_fecha_inicio" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora Block Off</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_hora_inicio" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha Block On</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_fecha_final" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora Block On</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_hora_final" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">¿Es charter?</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <label class="p-2">
                        <input type="radio" value="true" formControlName="charter">
                        <span>Sí</span>
                    </label>
                    <label class="p-2">
                        <input type="radio" value="false" formControlName="charter">
                        <span>No</span>
                    </label>
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Bandera</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <label class="p-2">
                        <input type="radio" value="true" formControlName="alerta">
                        <span>Sí</span>
                    </label>
                    <label class="p-2">
                        <input type="radio" value="false" formControlName="alerta">
                        <span>No</span>
                    </label>
                </div>
            </div>
            <div class="row btnRow">
                <div class="col-12 d-flex justify-content-between">
                    <button type="button" class="btnEliminar" (click)="eliminarEvento()">
                        <i class="bi bi-trash-fill"></i>
                    </button>
                    <button class="btnEnviar" (click)="enviar()">
                        <i class="bi bi-calendar-range-fill iconoBoton"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    </modal-content>
</modal>

<modal #modalCrearEvento>
    <modal-header>
        <p class="tituloModal">
            <i class="bi bi-calendar-plus-fill iconoTitulo"></i> Crear Evento
        </p>
    </modal-header>
    <modal-content>
        <form [formGroup]="eventoForm">
            <div class="alert alert-danger"> Fechas / Horas en UTC</div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">cod_fun</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="cod_fun" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Código</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="codigo" type="text">
                </div>
            </div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">extra</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="extra" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha inicio</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_inicio" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora inicio FDP</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_inicio" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Origen</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_inicio" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha final</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_final" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora final</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_final" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Destino</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_final" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Nº Vuelo</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha Block Off</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_fecha_inicio" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora Block Off</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_hora_inicio" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha Block On</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_fecha_final" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora Block On</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo_hora_final" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">¿Es charter?</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <label>
                        <input type="radio" value="true" formControlName="charter">
                        <span>Sí</span>
                    </label>
                    <label>
                        <input type="radio" value="false" formControlName="charter">
                        <span>No</span>
                    </label>
                </div>
            </div>
            <div class="row btnRow">
                <div class="col-6 d-flex justify-content-end">
                    <button class="btnEnviar" (click)="insertarSA()">
                        <i class="bi bi-calendar-plus-fill iconoBoton"></i>
                        Insertar SA
                    </button>
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <button class="{{!eventoForm.valid ? 'btnEnviarDisabled' : 'btnEnviar'}}" (click)="crearEvento()"
                        [disabled]="!eventoForm.valid">
                        <i class="bi bi-calendar-plus-fill iconoBoton"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    </modal-content>
</modal>