import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../providers/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-destacamento',
  templateUrl: './lista-destacamento.component.html',
  styleUrls: ['../dashboard.component.scss', './lista-destacamento.component.scss']
})
export class ListaDestacamentoComponent implements OnInit {

  posiblesDestacamentos = [];
  destacamentos = {};
  uuids = [];
  visual = true;
  constructor(
    private readonly apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.apiService.getEventosPosiblesDestacamentos().subscribe({
      next: (result: any) => {
        if (result.status) {
          this.posiblesDestacamentos = result.eventos;
        }
      },
      error: () => { }
    });
    this.apiService.getEventosDestacamento().subscribe({
      next: (result: any) => {
        if (result.status) {
          this.uuids = result.eventos.map((evento: any) => evento.uuid);
          const dataArr = new Set(this.uuids);
          this.uuids = [...dataArr];
          for (let u = 0; u < this.uuids.length; u++) {
            const uuid = this.uuids[u];
            this.destacamentos[uuid] = [];
          }
          for (let x = 0; x < result.eventos.length; x++) {
            const evento = result.eventos[x];
            this.destacamentos[evento.uuid].push(evento);
          }
        }
      },
      error: () => { }
    });
  }

  modificarPiloto(i: any, tipo: any) {
    this.posiblesDestacamentos[i].tipoPiloto = tipo;
  }

  modificarVoluntario(i: any) {
    this.posiblesDestacamentos[i].voluntario = !this.posiblesDestacamentos[i].voluntario;
  }

  guardarDestacamento(i: any) {
    this.apiService.setEventosDestacamento(this.posiblesDestacamentos[i]).subscribe({
      next: (result: any) => {
        if (result.status) {
          this.posiblesDestacamentos.splice(i, 1);
          this.lanzarAlertaExito("Se ha guardado correctamente", () => { })
          this.ngOnInit();
        } else {
          this.lanzarAlertaFracaso("Error al guardar");
          this.ngOnInit();
        }
      },
      error: () => { }
    });
  }

  modificarPilotoGuardado(uuid: any, tipo: any) {
    for (let d = 0; d < this.destacamentos[uuid].length; d++) {
      let destacamento = this.destacamentos[uuid][d];
      destacamento.tipoPiloto = tipo;
    }
  }

  modificarVoluntarioGuardado(uuid: any) {
    for (let d = 0; d < this.destacamentos[uuid].length; d++) {
      let destacamento = this.destacamentos[uuid][d];
      destacamento.voluntario = !destacamento.voluntario;
    }
  }

  guardarDestacamentoGuardado(uuid: any) {
    this.apiService.editEventosDestacamento(this.destacamentos[uuid]).subscribe({
      next: (result: any) => {
        if (result.status) {
          this.lanzarAlertaExito("Se ha guardado correctamente", () => { });
          this.ngOnInit();
        } else {
          this.lanzarAlertaFracaso("Error al guardar");
          this.ngOnInit();
        }
      },
      error: () => { }
    });
  }

  eliminarDestacamentoGuardado(uuid: any) {
    this.apiService.deleteEventosDestacamento(uuid).subscribe({
      next: (result: any) => {
        if (result.status) {
          this.lanzarAlertaExito("Se ha eliminado correctamente", () => { });
          this.destacamentos = {};
          location.reload()
        } else {
          this.lanzarAlertaFracaso("Error al eliminado");
          this.ngOnInit();
        }
      },
      error: () => { }
    });
  }

  // ALERTAS GENERALES

  lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true,
      timer: 4000
    }).then(f)
  }

  lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true,
      timer: 2500
    });
  }
}
