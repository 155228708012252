<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <div>
                        <i class="bi bi-gear-wide-connected iconoLegend"></i> Nómina
                    </div>
                </legend>

                <div class="row d-flex justify-content-center">
                    <form class="col-12 col-md-10 col-lg-8" [formGroup]="configuracionForm" (ngSubmit)="enviar()" novalidate="novalidate">
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-percent iconoInput"></i> Contingencias Comunes
                                </p>
                                <input class="textoInput" formControlName="contingencias_comunes_porciento" type="text">
                            </div>
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-percent iconoInput"></i> Desempleo Trabajador
                                </p>
                                <input class="textoInput" formControlName="desempleo_trabajador_porciento" type="text">
                            </div>
                        </div>
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-percent iconoInput"></i> Formación Profesional Trabajador
                                </p>
                                <input class="textoInput" formControlName="formacion_prof_porciento" type="text">
                            </div>
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-percent iconoInput"></i> IRPF
                                </p>
                                <input class="textoInput" formControlName="irpf_porciento" type="text">
                            </div>
                        </div>
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    Pérdida de Licencia
                                </p>
                                <input class="textoInput" formControlName="perdida_licencia" type="text">
                            </div>
                        </div>
                        <div class="row btnRow">
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit" class="{{!configuracionForm.valid ? 'btnGuardarDisabled' : 'btnGuardar'}}" [disabled]="!configuracionForm.valid">
                                    <i class="bi bi-sd-card"></i>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>