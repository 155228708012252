import { Pipe, PipeTransform } from '@angular/core';
import { Conflicto } from '../models/conflicto.model';

@Pipe({
  name: 'filterMes'
})
export class FilterMesPipe implements PipeTransform {

  transform(value: Conflicto[], arg: number[]): Conflicto[] {
    let conflictos: Conflicto[] = [];
    if (arg.length === 0) {
      conflictos = value;
    } else {
      value.forEach(c => {
        const fecha: Date = new Date(c.fecha);
        if (arg.includes(fecha.getMonth() + 1)) {
          conflictos.push(c);
        }
      });
    }
    return conflictos;
  }

}
