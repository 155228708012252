<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-send-fill iconoLegend"></i>
                    Horas Vuelo
                </legend>

                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-sm ">
                                <thead>
                                    <tr>
                                        <th *ngFor="let key of keysTabla" scope="col">{{key}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of eventos">
                                        <th scope="row">{{item.codigo}}</th>
                                        <td>{{item.h_vuelo / 60 | number: '1.0-2'}}</td>
                                        <td>{{item.fecha_inicio}} {{item.hora_inicio}}</td>
                                        <td>{{item.fecha_final}} {{item.hora_final}}</td>
                                        <td>{{item.trayecto_inicio}}</td>
                                        <td>{{item.trayecto_final}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">TOTAL HORAS:</th>
                                        <td>{{h_totales / 60 | number: '1.0-2'}}</td>
                                        <td></td>
                                        <td scope="row"><strong>TOTAL EVENTOS:</strong></td>
                                        <td>{{eventos.length}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="rowBack row d-flex justify-content-end">
                    <a class="btnBack" href="javascript:history.back()">
                        Volver
                    </a>
                </div>

            </fieldset>
        </div>
    </div>
</div>