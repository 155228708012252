<div class="container-fluid">

    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-sun-fill iconoLegend"></i>
                    Días de Vacaciones
                </legend>

                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-sm ">
                                <thead>
                                    <tr>
                                        <th *ngFor="let key of keysTabla" scope="col">{{key}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of eventos">
                                        <th scope="row">{{item.codigo}}</th>
                                        <td>{{item.fecha_inicio}}</td>
                                        <td>{{item.fecha_final}}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row"><strong>TOTAL EVENTOS:</strong></td>
                                        <td>{{eventos.length}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-emoji-angry iconoLegend"></i>
                    Días ERTE
                </legend>

                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-striped table-bordered table-sm ">
                                <thead>
                                    <tr>
                                        <th *ngFor="let key of keysTabla" scope="col">{{key}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of eventosErte">
                                        <th scope="row">{{item.codigo}}</th>
                                        <td>{{item.fecha_inicio}}</td>
                                        <td>{{item.fecha_final}}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row"><strong>TOTAL EVENTOS:</strong></td>
                                        <td>{{eventosErte.length}}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>

    <div class="rowBack row d-flex justify-content-end">
        <a class="btnBack" href="javascript:history.back()">
            Volver
        </a>
    </div>

</div>