import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { StorageService } from '../providers/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private _router: Router,
    private _storage: StorageService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve) => {
      this._storage.checkJwt().subscribe({
        next: (result: any) => {
          if (result.status) {
            resolve(true);
          } else {
            this._router.navigate(['/login']);
            resolve(false)
          }
        },
        error: () => {
          this._router.navigate(['/login']);
          resolve(false);
        },
        complete: () => { }
      });
    });
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise(async (resolve) => {
      this._storage.checkJwt().subscribe({
        next: (result: any) => {
          if (result.status) {
            resolve(true);
          } else {
            this._router.navigate(['/login']);
            resolve(false)
          }
        },
        error: () => {
          this._router.navigate(['/login']);
          resolve(false);
        },
        complete: () => { }
      });
    });
  }

}
