import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api.service';

@Component({
  selector: 'app-aviones',
  templateUrl: './aviones.component.html',
  styleUrls: ['../dashboard.component.scss', './listas.component.scss']
})
export class AvionesComponent implements OnInit {

  lista;
  pageActual = 1;
  mostrarTodo = true;
  buscar = '';

  constructor(private _api: ApiService) { }

  ngOnInit(): void {
    this._api.getAviones().subscribe({
      next: (aviones) => {
        if (aviones.status) {
          this.extraerAviones(aviones.aviones);
        }
      },
      error: (error) => {
      }
    });
  }

  extraerAviones(aviones) {
    this.lista = aviones;
  }

  mostrar() {
    if (this.mostrarTodo) {
      this.mostrarTodo = false;
    } else {
      this.mostrarTodo = true;
    }
  }

  iconos(icono: number) {
    switch (icono) {
      case 1: {
        return 'fas fa-plane';
      }
      case 2: {
        return 'fas fa-home';
      }
      case 3: {
        return 'fas fa-globe';
      }
      case 4: {
        return 'fas fa-plane';
      }
      case 5: {
        return 'fas fa-calendar';
      }
      default: {
        return 'fas fa-plane';
      }
    }
  }

  //Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

}
