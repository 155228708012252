import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;
  private url = environment.path;
  headers: any;
  private auth;

  constructor(
    private _http: HttpClient,
    private _router: Router
  ) {
    this.localStorageService = localStorage;
    this.actualizarHeader();
  }

  actualizarHeader() {
    this.auth = this.loadTokenData();
    this.headers = { 'token': this.auth };
  }

  setCurrentSessionToken(token: any): void {
    this.auth = token;
    this.localStorageService.setItem('token', JSON.stringify(token));
  }

  setEmail(email: any): void {
    this.localStorageService.setItem('email', email);
    this.localStorageService.setItem('remember', true);
  }

  getRemember(): any {
    return this.localStorageService.getItem('remember') || false;
  }

  getEmail(): any {
    let email = this.localStorageService.getItem('email');
    if (email === null) {
      return '';
    } else {
      return email;
    }
  }

  removeEmail(): void {
    this.localStorageService.removeItem('email');
    this.localStorageService.removeItem('remember');
  }

  loadTokenData(): any {
    const sessionToken = this.localStorageService.getItem('token');
    return (sessionToken) ? JSON.parse(sessionToken) : null;
  }

  getCurrentTokenSession(): any {
    return this.auth;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('token');
    this.auth = null;
  }

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  }

  getCurrentToken(): String {
    const session = this.getCurrentTokenSession();
    return session;
  }

  checkUser(token): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'checkUser', { token }, { 'headers': this.headers });
  }

  checkJwt(): Observable<any> {
    this.actualizarHeader();
    let token = this.auth;
    return this._http.post(this.url + 'checkUser', { token }, { 'headers': this.headers });
  }

  logout(): void {
    this.removeCurrentSession();
    this._router.navigate(['/login']);
  }

}
