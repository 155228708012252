<nav class="sb-sidenav accordion" id="sidenavAccordion">

    <div class="sb-sidenav-menu">
        <div id="itemsContainer" class="nav">
            <div *ngFor="let title of titleItems">
                <div *ngFor="let item of menuItems">
                    <a *ngIf="item.group == title" class="nav-link enlace" (click)="sidebar(!class)"
                        [routerLink]="item.path">
                        <div [class]="item.class">
                            <i [class]="item.icon"></i>
                        </div>
                        <span class="textoEnlace">{{item.title}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

</nav>