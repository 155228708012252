import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterPerfilesPipe'
})
export class FilterPerfilesPipe implements PipeTransform {

  transform(value: any[], arg: string[]): any[] {
    let datos: any[] = [];
    if (value != undefined && value.length != 0) {
      if (arg[0] != undefined && arg[0] != "") {
        const argLc: string = arg[0].toLowerCase();
        value.forEach(perfil => {
          const titulo: string = perfil.trayecto.toLowerCase();
          if (titulo.includes(argLc)) {
            datos.push(perfil);
          }
        });
      } else {
        datos = value;
      }
    }
    return datos;
  }

}
