import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/providers/api.service';
import { AuthService } from 'src/app/providers/auth.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { StorageService } from 'src/app/providers/storage.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['../dashboard.component.scss', './perfil.component.scss']
})
export class AdministradorComponent implements OnInit {

  usuario = { nombre: '', apellido1: '', apellido2: '' };
  piloto;
  imagen;
  checkUser = true;
  checkAdmin = false;
  lista;
  pageActual = 1;
  mostrarTodo = true;
  opciones = ['nombre', 'apellido1', 'apellido2', 'email'];
  filterPost = '';
  seleccionado = 'nombre';
  userForm: FormGroup;
  //FICHERO CSV
  nombreFichero = 'Elige un fichero';
  fichero: File;
  errFichero: string;
  msjFichero: string;
  datosCSV: any[] = [];
  keysCSV: any[] = [];
  tablaHeader: any[] = [];
  mesSindicato = 1;
  anioSindicato = 2023;

  constructor(private _formB: FormBuilder, private _api: ApiService, private _router: Router, public _dash: DashboardService, private _user: AuthService, private _storage: StorageService) { }

  ngOnInit(): void {
    this.userForm = this._formB.group({
      centro: []
    });
    this.userForm.controls.centro.valueChanges.subscribe(data => {
      this.seleccionado = this.opciones[data];
    });
    this.onCheckUser();
  }

  onCheckUser() {
    if (this._storage.isAuthenticated()) {
      let token = this._storage.getCurrentToken();
      this._storage.checkUser(token).subscribe({
        next: (user) => {
          this._user.getPiloto().subscribe({
            next: (piloto) => {
              this.extraerUsuario(piloto.piloto, user.user);
              if (user.user.tipo == 'admin') {
                this._api.getUsers().subscribe(usuarios => {
                  this.extraerUsuarios(usuarios.users);
                });
              }
            },
            error: (error) => {
            }
          });
        },
        error: (error) => {
        }
      });
    } else {
      this.checkUser = false;
    }
  }

  extraerUsuario(piloto, usuario) {
    this.usuario = piloto;
    this.esAdmin(usuario.tipo);
    this.checkUser = true;
  }

  extraerUsuarios(usuarios) {
    this.lista = usuarios;
  }

  esAdmin(admin) {
    if (admin == 'admin') {
      this.checkAdmin = true;
    } else {
      this.checkAdmin = false;
      this._router.navigateByUrl('/sepla/info-anual');
    }
  }

  mostrar() {
    if (this.mostrarTodo) {
      this.mostrarTodo = false;
    } else {
      this.mostrarTodo = true;
    }
  }

  editStatus(usuarioId) {
    this._api.editStatus(usuarioId).subscribe({
      next: (result) => {
        if (result.status) {
          var i = this.lista.findIndex(user => user.usuario.id === usuarioId);
          if (i !== -1) {
            this.lista[i].usuario.estado = result.user.estado;
            if (result.user.estado) {
              this.lanzarAlertaExito("Estado activado.");
            } else {
              this.lanzarAlertaExito("Estado desactivado.");
            }
          }
        } else {
          this.lanzarAlertaFracaso("Error al cambiar de estado.");
        }
      },
      error: (error) => {
      }
    });
  }

  resetAttempts(usuarioId) {
    this._api.resetAttempts(usuarioId).subscribe({
      next: (result) => {
        if (result.status) {
          var i = this.lista.findIndex(user => user.usuario.id === usuarioId);
          if (i !== -1) {
            this.lista[i].usuario.intentosPassword = result.user.intentosPassword;
            if (result.user.intentosPassword == 0) {
              this.lanzarAlertaExito("Intentos de contraseña reiniciados");
            } else {
              this.lanzarAlertaExito("Tiene varios intentos de contraseña");
            }
          }
        } else {
          this.lanzarAlertaFracaso("Error al reiniciar los intentos de contraseña");
        }
      },
      error: (error) => {
        this.lanzarAlertaFracaso("Error al reiniciar los intentos de contraseña");
      }
    });
  }

  editSindicato(usuarioId) {
    this._api.editSindicato(usuarioId).subscribe({
      next: (result) => {
        if (result.status) {
          var i = this.lista.findIndex(user => user.usuario.id === usuarioId);
          if (i !== -1) {
            this.lista[i].usuario.sindicato = result.user.sindicato;
            if (result.user.sindicato) {
              this.lanzarAlertaExito("Sindicato activado.");
            } else {
              this.lanzarAlertaExito("Sindicato desactivado.");
            }
          }
        } else {
          this.lanzarAlertaFracaso("Error al cambiar de estado.");
        }
      },
      error: (error) => {
      }
    });
  }

  // Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: true,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true
    });
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: true,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true
    });
  }


  subirFichero() {
    this.datosCSV = [];
    if (this.fichero) {
      let formData = new FormData();
      formData.append('file', this.fichero, this.fichero.name);
      this._api.subirFichero(this.mesSindicato, this.anioSindicato, formData).subscribe({
        next: (result) => {
          if (result.status) {
            this.lanzarAlertaExito("Fichero insertado correctamente");
          } else {
            this.lanzarAlertaFracaso(result.error);
          }
        },
        error: (error) => {
          this.lanzarAlertaFracaso("Algo ha salido mal, contacte con administración");
          console.log(error);
        }
      });
    } else {
      this.lanzarAlertaFracaso("Error al guardar el fichero");
    }
  }

  changeFichero(event) {
    this.fichero = null;
    this.msjFichero = null;
    this.errFichero = null;
    this.nombreFichero = event.name;
    let validarFichero = this.validarFichero(event);
    if (validarFichero.status) {
      this.fichero = event;
      this.msjFichero = validarFichero.msj;
    } else {
      this.fichero = null;
      this.errFichero = validarFichero.error;
    }
    this.subirFichero();
  }

  validarFichero(fichero) {
    var nombreCorto = fichero['name'].split('.');
    var extension = nombreCorto[nombreCorto.length - 1];
    var extenValidas = ['xlsx', 'XLSX'];
    if (extenValidas.indexOf(extension) < 0) {
      return { status: false, error: `Debe de seleccionar una extensión válida: ${extenValidas.join(', ')}` };
    }
    if (!fichero) {
      return { status: false, error: 'No es un fichero' };
    }
    return { status: true, msj: 'Fichero correcto' };
  }


}
