import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/providers/api.service';
import { StorageService } from 'src/app/providers/storage.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../../providers/auth.service';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['../dashboard.component.scss', './listas.component.scss'],
})
export class ListaPerfilesComponent implements OnInit {
  textAreaForm: FormGroup;
  lista;
  pageActual = 1;
  mostrarTodo = true;
  buscar = '';
  checkUser = true;
  checkAdmin = false;
  usuario = { nombre: '', apellido1: '', apellido2: '' };
  datosTextArea: any[] = [];
  filterPost = '';

  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private _formB: FormBuilder,
    private _storage: StorageService
  ) {}

  ngOnInit(): void {
    this.onCheckUser();
    this.crearFormularioTextArea();
  }

  crearFormularioTextArea() {
    this.textAreaForm = this._formB.group({
      texto: ['', [Validators.required]],
      flota: ['B787', [Validators.required]],
    });
  }

  leerDatos() {
    this.datosTextArea = [];
    this.datosTextArea = this.transformarTextAreaObjeto(
      this.textAreaForm.value.texto
    );
    if (this.datosTextArea.length > 0) {
      this.lanzarAlertaCuidado('Revise los datos en la tabla y pulse enviar.');
    } else {
      this.lanzarAlertaFracaso('Los datos no son correctos.');
    }
  }

  transformarTextAreaObjeto(texto): any[] {
    let resultado: any[] = [];
    if (texto != '') {
      const separarFilas = texto.split('\n');
      for (const fila of separarFilas) {
        let objeto = {
          trayecto: '',
          tipo: '',
          tiempo: '',
          observacion: '',
          flota: '',
          fecha: '',
        };
        const textoSinEspacios = fila.trim();
        const separarEspacios = textoSinEspacios.split(' ');
        const eliminadoVacio = separarEspacios.filter((x) => x != '');
        switch (eliminadoVacio.length) {
          case 4:
            objeto.trayecto = eliminadoVacio[1].trim();
            objeto.tipo = eliminadoVacio[2].trim();
            objeto.tiempo = eliminadoVacio[3].trim();
            objeto.observacion = '';
            objeto.flota = this.textAreaForm.controls.flota.value;
            break;
          case 5:
            if (eliminadoVacio[1].trim() == 'a') {
              objeto.trayecto = eliminadoVacio[2].trim();
              objeto.tipo = eliminadoVacio[3].trim();
              objeto.tiempo = eliminadoVacio[4].trim();
              objeto.observacion = '';
              objeto.flota = this.textAreaForm.controls.flota.value;
            } else {
              objeto.trayecto = eliminadoVacio[1].trim();
              objeto.tipo = eliminadoVacio[2].trim();
              objeto.tiempo = eliminadoVacio[3].trim();
              objeto.observacion = '';
              objeto.flota = this.textAreaForm.controls.flota.value;
            }
            break;
          case 6:
            if (eliminadoVacio[1].trim() == 'a') {
              objeto.trayecto = eliminadoVacio[2].trim();
              objeto.tipo = eliminadoVacio[3].trim();
              objeto.tiempo = eliminadoVacio[4].trim();
              objeto.observacion =
                eliminadoVacio[5].trim() == 'PE'
                  ? eliminadoVacio[5].trim()
                  : '';
              objeto.flota = this.textAreaForm.controls.flota.value;
            } else {
              objeto.trayecto = eliminadoVacio[1].trim();
              objeto.tipo = eliminadoVacio[2].trim();
              objeto.tiempo = eliminadoVacio[3].trim();
              objeto.observacion =
                eliminadoVacio[4].trim() == 'PE'
                  ? eliminadoVacio[4].trim()
                  : '';
              objeto.flota = this.textAreaForm.controls.flota.value;
            }
            break;
          case 7:
            objeto.trayecto = eliminadoVacio[2].trim();
            objeto.tipo = eliminadoVacio[3].trim();
            objeto.tiempo = eliminadoVacio[4].trim();
            objeto.observacion =
              eliminadoVacio[5].trim() == 'PE' ? eliminadoVacio[5].trim() : '';
            objeto.flota = this.textAreaForm.controls.flota.value;
            break;
          default:
            break;
        }
        if (objeto.trayecto != '' && objeto.tipo != '' && objeto.tiempo != '') {
          if (objeto.tipo == 'C' || objeto.tipo == 'R') {
            resultado.push(objeto);
          }
        }
      }
    }
    return resultado;
  }

  enviarTextArea() {
    let datosTextArea: any[] = [];
    datosTextArea = this.datosTextArea;
    this._api.setPerfilVuelo(datosTextArea).subscribe({
      next: (result) => {
        if (result.status) {
          this.lanzarAlertaExito(
            'Perfiles añadidos a la base de datos: ' +
              result.contador +
              ' de ' +
              this.datosTextArea.length,
            () => {
              this.datosTextArea = [];
              this.textAreaForm.controls.texto.setValue('');
            }
          );
        } else {
          this.lanzarAlertaFracaso('Su solicitud no ha podido ser procesada.');
        }
      },
      error: (error) => {},
    });
  }

  onCheckUser() {
    if (this._storage.isAuthenticated()) {
      this._auth.getPiloto().subscribe({
        next: (result) => {
          this.extraerUsuario(result);
          this._api.getPerfilesVueloAdmin().subscribe({
            next: (result) => {
              if (result.status) {
                this.lista = result.perfiles;
              }
            },
            error: (error) => {},
          });
        },
        error: (error) => {},
      });
    } else {
      this.checkUser = false;
    }
  }

  extraerUsuario(result) {
    if (result.status) {
      this.usuario = result.piloto;
      this.esAdmin(result.piloto.tipo);
      this.checkUser = true;
    } else {
      this.checkUser = false;
      this._storage.logout();
    }
  }

  esAdmin(admin) {
    if (admin == 'admin') {
      this.checkAdmin = true;
    } else {
      this.checkAdmin = false;
    }
  }

  mostrar() {
    if (this.mostrarTodo) {
      this.mostrarTodo = false;
    } else {
      this.mostrarTodo = true;
    }
  }

  //Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: '25rem',
      titleText: contenido,
      showConfirmButton: false,
      color: 'rgb(255, 255, 255)',
      icon: 'success',
      background: 'rgb(60, 60, 160)',
      iconColor: 'rgb(25, 135, 85)',
      toast: true,
      timer: 1200,
    }).then(f);
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: '25rem',
      titleText: contenido,
      showConfirmButton: false,
      color: 'rgb(255, 255, 255)',
      icon: 'error',
      background: 'rgb(60, 60, 160)',
      iconColor: 'rgb(185, 50, 20)',
      toast: true,
      timer: 1200,
    });
  }

  public lanzarAlertaCuidado(contenido: string) {
    Swal.fire({
      width: '25rem',
      titleText: contenido,
      showConfirmButton: false,
      color: 'rgb(255, 255, 255)',
      icon: 'warning',
      background: 'rgb(60, 60, 160)',
      iconColor: 'rgb(225, 175, 25)',
      toast: true,
      timer: 1200,
    });
  }
}
