import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/providers/auth.service';
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.scss']
})
export class ResetpassComponent implements OnInit {

  auth2: any;
  passwordForm: FormGroup;
  passIgual = true;
  backRoute = "/login"
  token: any;

  constructor(private route: ActivatedRoute, private _ngzone: NgZone, private _form: FormBuilder, private _router: Router, private _auth: AuthService) { }

  ngOnInit(): void {
    this.crearFormulario();
    this.route.params.subscribe(params => {
      this.token = params['token'];
    })
    // Observa cambios en los campos.
    this.passwordForm.controls.pass2.valueChanges.subscribe(data => {
      this.passIgual = this.noIgual();
    });
    this.passwordForm.controls.password.valueChanges.subscribe(data => {
      this.passIgual = this.noIgual();
    });
  }

  crearFormulario() {
    this.passwordForm = this._form.group({
      email: ['', [Validators.email, Validators.required, Validators.maxLength(200)]],
      password: ['', [Validators.required, Validators.maxLength(200), Validators.minLength(6)]],
      pass2: ['', [Validators.required, Validators.maxLength(200), Validators.minLength(6)]],
    });
  }

  noIgual() {
    // Comprueba que los passwords sean iguales.
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const password2 = (document.getElementById('pass2') as HTMLInputElement).value;
    if (password === password2 && password !== ' ' && password2 !== ' ') {
      return true;
    }
    return false;
  }

  validarFormulario() {
    if (this.passwordForm.valid && this.passIgual) {
      return true;
    }
    return false;
  }

  passwordReset() {
    this._auth.newPassword({ ...this.passwordForm.value, token: this.token }).subscribe({
      next: (result) => {
        if (result.status) {
          this.lanzarAlertaExito("Contraseña modificada", () => {
            this._ngzone.run(() => {
              this._router.navigateByUrl('/login');
            });
          });
        } else {
          this.lanzarAlertaFracaso("Error al modificar contraseña");
        }
      },
      error: (error) => {
        this.lanzarAlertaFracaso("Error al modificar contraseña");
      }
    });
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true,
      timer: 1200,
    }).then(f);
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true,
      timer: 1200,
    });
  }

}

