<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-pin-map-fill iconoLegend"></i> Aeropuertos
                </legend>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card shadow mb-4">
                            <form [formGroup]="seleccionadoForm" *ngIf="checkAdmin && mostrarEditar">
                                <div class="row m-2">
                                    <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                        <b> IATA:</b>
                                        <input style="width: 50%" class="m-2" formControlName="iata" type="text">
                                    </div>
                                    <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                        <b> OACI:</b>
                                        <input style="width: 50%" class="m-2" formControlName="oaci" type="text">

                                    </div>
                                    <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                        <b> GMT:</b>
                                        <input style="width: 50%" class="m-2" formControlName="utc" type="text">
                                    </div>
                                    <div class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                        <i [ngClass]="iconos(4)"></i>
                                        <input style="width: 50%" class="m-2" formControlName="aeropuerto" type="text">

                                    </div>
                                    <div class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                        <b> ID:</b> {{seleccionado.id}}
                                    </div>
                                    <div class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                        <b> Localización:</b>
                                        <input style="width: 50%" class="m-2" formControlName="localizacion" type="text">

                                    </div>
                                    <div class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                        <b> País:</b>
                                        <input style="width: 50%" class="m-2" formControlName="pais" type="text">

                                    </div>
                                    <div class="col-3">
                                        <button type="button" class="btn botonSeplaAct" [disabled]="!seleccionadoForm.valid" (click)="enviarAeropuerto()">Guardar</button>
                                    </div>
                                    <div class="col-3" *ngIf="mensajeInformativo != ''">
                                        <div class="alert alert-secondary m-2" role="alert">{{mensajeInformativo}}</div>
                                    </div>
                                </div>
                            </form>
                            <div class="card-body row">
                                <div class="col-md-6 col-sm-12">
                                    <span *ngIf="!mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar todo</button></span>
                                    <span *ngIf="mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar menos</button></span>
                                </div>
                                <div class="col-12" *ngIf="!mostrarTodo">
                                    <div class="row m-2" *ngFor="let elemento of lista | paginate: {itemsPerPage: 4, currentPage: pageActual}">
                                        <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> IATA:</b> {{elemento.iata}}
                                        </div>
                                        <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> OACI:</b> {{elemento.oaci}}
                                        </div>
                                        <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> GMT:</b> {{elemento.utc}}
                                        </div>
                                        <div class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <i [ngClass]="iconos(4)"></i> <strong> {{elemento.aeropuerto}}</strong>
                                        </div>
                                        <div class="col-md-5 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <b> ID:</b> {{elemento.id}}
                                        </div>
                                        <div *ngIf="checkAdmin" class="col-md-1 col-sm-6 fondoUno list-group-item list-group-item-secondary" (click)="crearFormulario(elemento,i)">
                                            <b> EDITAR</b>
                                        </div>
                                        <div *ngIf="!checkAdmin" class="col-md-1 col-sm-6 fondoUno list-group-item list-group-item-secondary" (click)="crearFormulario(elemento,i)">

                                        </div>
                                        <div class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> Localización:</b> {{elemento.localizacion}}
                                        </div>
                                        <div class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> País:</b> {{ elemento.pais }}
                                        </div>
                                    </div>
                                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" *ngIf="lista !== undefined && !mostrarTodo" class="text-left" (pageChange)="pageActual = $event"></pagination-controls>
                                </div>
                                <div class="col-12" *ngIf="mostrarTodo">
                                    <div class="row m-2" *ngFor="let elemento of lista">
                                        <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> IATA:</b> {{elemento.iata}}
                                        </div>
                                        <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> OACI:</b> {{elemento.oaci}}
                                        </div>
                                        <div class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> GMT:</b> {{elemento.utc}}
                                        </div>
                                        <div class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <i [ngClass]="iconos(4)"></i> <strong> {{elemento.aeropuerto}}</strong>
                                        </div>
                                        <div class="col-md-5 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <b> ID:</b> {{elemento.id}}
                                        </div>
                                        <div *ngIf="checkAdmin" class="col-md-1 col-sm-6 fondoUno list-group-item list-group-item-secondary" (click)="crearFormulario(elemento,i)">
                                            <b> EDITAR</b>
                                        </div>
                                        <div *ngIf="!checkAdmin" class="col-md-1 col-sm-6 fondoUno list-group-item list-group-item-secondary" (click)="crearFormulario(elemento,i)">

                                        </div>
                                        <div class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> Localización:</b> {{elemento.localizacion}}
                                        </div>
                                        <div class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> País:</b> {{ elemento.pais }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>