<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-signpost-2 iconoLegend"></i> Comparar Eventos
                    <select [(ngModel)]="anioSeleccionado" class="selectorAnio" (change)="cambiarAnio()" [disabled]="cargando">
                        <option *ngFor="let anio of aniosSeleccionables; index as i" class="selectorAnioOption"
                            [ngValue]="anio">{{anio}}</option>
                    </select>
                    <i class="bi bi-caret-down-fill iconoSelector"></i>
                </legend>

                <div *ngIf="cargando" class="loadingScreen d-flex justify-content-center align-items-center">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Cargando...</span>
                    </div>
                </div>

                <div *ngIf="!cargando" class="row">
                    <div class="col-12 d-flex justify-content-start align-items-center">
                        <p *ngIf="filtroMes.length === 0" class="tituloFiltro">
                            Filtro
                        </p>
                        <p *ngIf="filtroMes.length != 0" class="tituloFiltroReset" (click)="resetFiltroMes();">
                            x
                        </p>
                    </div>
                </div>

                <div *ngIf="!cargando" class="row filterRow">
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(1) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(1);">
                            Ene
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(2) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(2);">
                            Feb
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(3) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(3);">
                            Mar
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(4) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(4);">
                            Abr
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(5) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(5);">
                            May
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(6) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(6);">
                            Jun
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(7) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(7);">
                            Jul
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(8) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(8);">
                            Ago
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(9) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(9);">
                            Sep
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(10) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(10);">
                            Oct
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(11) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(11);">
                            Nov
                        </button>
                    </div>
                    <div class="colMes col-4 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                        <button class="btnMes {{comprobarFiltroMes(12) ? 'btnSeleccionado': ''}}" (click)="modificarFiltroMes(12);">
                            Dic
                        </button>
                    </div>
                </div>

                <div *ngIf="!cargando" class="row tableRow d-flex justify-content-center">
                    <div class="col-8 d-flex justify-content-center cardInfoConflictos" *ngIf="!existenEventos()">
                        <p class="textInfoConflictos">
                            <i class="iconInfoConflictos bi bi-exclamation-lg"></i> No hay eventos solapados.
                        </p>
                    </div>
                    <div class="col-12" *ngIf="existenEventos()">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>
                                        <i class="bi bi-mailbox2"></i> Evento 1
                                    </th>
                                    <th></th>
                                    <th>
                                        <i class="bi bi-globe"></i> Evento 2
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="trClick" *ngFor="let c of conflictos | filterAnio: filtroAnio | filterMes: filtroMes; i as index" (click)="seleccionarConflicto(c)" data-toggle="modal" data-target="#modalEdicion">
                                    <td>
                                        <p class="textoEvento text-nowrap">
                                            <i class="bi bi-signpost-2 iconoEvento"></i>
                                            <span>{{c.evento_programado1.evento.codigoEvento}}</span>
                                            <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                                            <span>{{c.evento_programado1.evento.trayectoEvento}}</span>
                                        </p>
                                        <p class="textoEvento text-nowrap">
                                            <i class="bi bi-calendar iconoEvento"></i>
                                            <span>{{c.evento_programado1.evento.fechaEvento}}</span>
                                            <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                                            <span>{{c.evento_programado1.evento.horaEvento}}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <i class="bi bi-code iconoComparador"></i>
                                    </td>
                                    <td>
                                        <p class="textoEvento text-nowrap">
                                            <i class="bi bi-signpost-2 iconoEvento"></i>
                                            <span>{{c.evento_programado2.evento.codigoEvento}}</span>
                                            <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                                            <span>{{c.evento_programado2.evento.trayectoEvento}}</span>
                                        </p>
                                        <p class="textoEvento text-nowrap">
                                            <i class="bi bi-calendar iconoEvento"></i>
                                            <span>{{c.evento_programado2.evento.fechaEvento}}</span>
                                            <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                                            <span>{{c.evento_programado2.evento.horaEvento}}</span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>

<!-- Modal Edición -->
<div class="modal" id="modalEdicion" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenteredLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenteredLabel">
                    Resolver Conflicto
                    <i class="bi bi-lightning-fill iconoTituloModal"></i>
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p class="mP mTituloEvento">
                    <i class="mIcono bi bi-mailbox2"></i> Evento 1
                </p>
                <p class="mP">
                    <span>{{conflictoSeleccionado.evento_programado2.evento.codigoEvento}}</span>
                    <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                    <span>{{conflictoSeleccionado.evento_programado2.evento.fechaEvento}}</span>
                    <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                    <span>{{conflictoSeleccionado.evento_programado2.evento.horaEvento}}</span>
                </p>
                <p class="mP mTituloEvento">
                    <i class="mIcono bi bi-globe"></i> Evento 2
                </p>
                <p class="mP">
                    <span>{{conflictoSeleccionado.evento_programado2.evento.codigoEvento}}</span>
                    <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                    <span>{{conflictoSeleccionado.evento_programado2.evento.fechaEvento}}</span>
                    <span> <i class="bi bi-three-dots-vertical iconoSeparador"></i> </span>
                    <span>{{conflictoSeleccionado.evento_programado2.evento.horaEvento}}</span>
                </p>
            </div>
            <div *ngIf="conflictoSeleccionado.evento_programado2.esSA || conflictoSeleccionado.evento_programado2.esSA" class="modal-footer d-flex justify-content-center">
                <p class="mP mTituloEvento">
                    Modifica las horas del evento en el calendario.
                    <button type="button" class="btnEdicion btnIr" (click)="goCalendar();" data-dismiss="modal">
                        Ir al <span class="textoResaltado">mes</span>
                    </button>
                </p>
            </div>
            <div *ngIf="!conflictoSeleccionado.evento_programado2.esSA && !conflictoSeleccionado.evento_programado2.esSA" class="modal-footer d-flex justify-content-center">
                <!-- <button type="button" class="btnEdicion btnReemplazar" (click)="editarConflicto('evento_programado2');" data-dismiss="modal">
                    Mantener <span class="textoResaltado">Realizado</span> y reemplazar <span
                        class="textoResaltado">Programado</span>
                </button>
                <button type="button" class="btnEdicion btnReemplazar" (click)="editarConflicto('evento_programado');" data-dismiss="modal">
                    Mantener <span class="textoResaltado">Programado</span> y reemplazar <span
                        class="textoResaltado">Realizado</span>
                </button> -->
                <!-- <button type="button" class="btnEdicion btnEliminar" (click)="eliminarConflicto('evento_programado2');" data-dismiss="modal">
                    Eliminar <span class="textoResaltado">Programado 1</span> y mantener <span
                        class="textoResaltado">Programado 2</span>
                </button>
                <button type="button" class="btnEdicion btnEliminar" (click)="eliminarConflicto('evento_programado1');" data-dismiss="modal">
                    Eliminar <span class="textoResaltado">Programado 2</span> y mantener <span
                        class="textoResaltado">Programado 1</span>
                </button> -->
                <button type="button" class="btnEdicion btnIr" (click)="goCalendar();" data-dismiss="modal">
                    Ir al <span class="textoResaltado">mes</span> (modificar evento)
                </button>
            </div>
        </div>
    </div>
</div>