import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../providers/api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../../providers/dashboard.service';

@Component({
  selector: 'app-horas-vuelo',
  templateUrl: './horas-vuelo.component.html',
  styleUrls: ['../dashboard.component.scss', './info-anual.component.scss']
})
export class HorasVueloComponent implements OnInit, OnDestroy {

  keysTabla: any[] = ['Código', 'Horas Vuelo', 'Fecha/Hora inicio', 'Fecha/Hora final', 'Aeropuerto Salida', 'Aeropuerto Llegada'];
  eventos = [];
  funEventos: Subscription;
  h_totales = 0;
  constructor(private _rutaActiva: ActivatedRoute, private _api: ApiService, private _dashboard: DashboardService) { }

  ngOnDestroy() {
    this.funEventos.unsubscribe();
  }

  ngOnInit(): void {
    let anio = this._rutaActiva.snapshot.params.anio
    this.funEventos = this._api.getEventosAnio(anio).subscribe({
      next: (result) => {
        if (result.status) {
          for (let x = 0; x < result.eventos.vuelos.length; x++) {
            let vuelo = result.eventos.vuelos[x];
            vuelo.h_vuelo = this._dashboard.eventosMinutos(vuelo.fecha_inicio, vuelo.hora_inicio, vuelo.fecha_final, vuelo.hora_final);
            this.h_totales = this.h_totales + Number(vuelo.h_vuelo);
            this.eventos.push(vuelo);
          }
          this.eventos = this._dashboard.ordenarFechasEventos(this.eventos);
        }
      },
      error: (error) => {
      }
    });
  }

}
