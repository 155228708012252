import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/providers/auth.service';
import { SwalService } from 'src/app/providers/swal.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  auth2: any;
  registerForm: FormGroup;
  niveles = [];
  flotas = new Array('B737', 'B787');
  passIgual = true;
  backRoute = "/login"

  constructor(
    private _ngzone: NgZone,
    private _form: FormBuilder,
    private _router: Router,
    private _auth: AuthService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
    this._auth.getNiveles().subscribe(result => {
      this.extraerNiveles(result.niveles);
    });

    // Observa cambios en los campos.
    this.registerForm.controls.pass2.valueChanges.subscribe(data => {
      this.passIgual = this.noIgual();
    });
    this.registerForm.controls.password.valueChanges.subscribe(data => {
      this.passIgual = this.noIgual();
    });
  }

  extraerNiveles(niveles) {
    this.niveles = niveles;
  }

  crearFormulario() {
    this.registerForm = this._form.group({
      email: ['', [Validators.email, Validators.required, Validators.maxLength(200)]],
      password: ['', [Validators.required, Validators.maxLength(200), Validators.minLength(6)]],
      pass2: ['', [Validators.required, Validators.maxLength(200), Validators.minLength(6)]],
      nombre: ['', [Validators.required, Validators.maxLength(50)]],
      apellido1: ['', [Validators.required, Validators.maxLength(50)]],
      apellido2: ['', [Validators.required, Validators.maxLength(50)]],
      nivel: ['', [Validators.required]],
      flota: ['', [Validators.required]],
      disclaimer: [false, [Validators.required]]
    });
  }

  noIgual() {
    // Comprueba que los passwords sean iguales.
    const password = (document.getElementById('password') as HTMLInputElement).value;
    const password2 = (document.getElementById('pass2') as HTMLInputElement).value;
    if (password === password2 && password !== ' ' && password2 !== ' ') {
      return true;
    }
    return false;
  }

  validarFormulario() {
    if (this.registerForm.valid && this.passIgual && this.registerForm.controls.disclaimer.value == true) {
      return true;
    }
    return false;
  }

  register() {
    this._auth.register(this.registerForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this.lanzarAlertaExito('Registro completado correctamente. Por favor, póngase en contacto con su administrador para que le active su cuenta.', () => {
            this._ngzone.run(() => {
              this._router.navigateByUrl('/login');
            });
          });
        } else {
          this.lanzarAlertaFracaso(result.error);
        }
      },
      error: (error) => {
        this.lanzarAlertaFracaso("Algo ha fallado.");
      }
    });
  }

  verDisclaimer(): void {
    this.swalService.launchInfoSwalHTML(
      'CONDICIONES DE USO',
      `
        <p style="font-size: 14px;">La entidad responsable del tratamiento le informa que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de protección de datos (RGPD), y en la L.O. 3/2018, de protección de datos y garantía de los derechos digitales (LOPDGDD), por lo que se le facilita la siguiente información del tratamiento:</p>
        <ul style="font-size: 14px;">
          <li><strong>Finalidad del tratamiento:</strong> Proporcionar los servicios para los que el usuario accede a este sitio web.</li>
          <li><strong>Legitimación:</strong> Consentimiento del usuario otorgado con el envío del formulario.</li>
          <li><strong>Comunicación de los datos:</strong> No se comunicarán los datos a terceros, salvo obligación legal.</li>
          <li><strong>Criterios de conservación de los datos:</strong> Mientras dure la relación del usuario con la entidad, finalizada la relación se procederá a la supresión de los datos garantizándole un total respeto a la confidencialidad tanto en el tratamiento como en su posterior destrucción.</li>
          <li><strong>Derechos que asisten al usuario:</strong> Derecho de acceso, rectificación, oposición, supresión, portabilidad y limitación u oposición a su tratamiento.</li>
          <li><strong>Datos de contacto para ejercer sus derechos:</strong> <a href="mailto:nominas.ssaea@sepla.es" style="font-size: 14px;">nominas.ssaea@sepla.es</a></li>
        </ul>
      `
    );
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: true,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
    }).then(f);
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: true,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
    });
  }

}

