import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-actividad',
  templateUrl: './actividad.component.html',
  styleUrls: ['../dashboard.component.scss', './perfil.component.scss']
})
export class ActividadComponent implements OnInit {

  lista;
  pageActual = 1;
  mostrarTodo = true;

  constructor(public _dash: DashboardService, private _api: ApiService) { }

  ngOnInit(): void {
    this._api.getActividades().subscribe({
      next: (result) => {
        if (result.status) {
          let actividades = result.actividades.map((actividad: any) => {
            actividad.creado = new Date(actividad.creado);
            return actividad;
          });
          this.extraerActividades(actividades);
        }
      },
      error: (error) => {
      }
    });
  }

  extraerActividades(actividades) {
    this.lista = actividades;
    this.ordenar();
  }

  mostrar() {
    if (this.mostrarTodo) {
      this.mostrarTodo = false;
    } else {
      this.mostrarTodo = true;
    }
  }

  ordenar() {
    // Ordena los eventos de fecha mayor a menor.
    this.lista = this._dash.ordenarFechas(this.lista);
  }

  borrarTodo() {
    this.lanzarAlertaCuidado("BORRAR TODO", (result) => {
      if (result.isConfirmed) {
        this.lanzarAlertaMuchoCuidado("VAS A BORRAR TODOS LOS DATOS", (result) => {
          if (result.isConfirmed) {
            this._api.eliminarTodo().subscribe({
              next: (result) => {
                if (result.status) {
                  this.lanzarAlertaExito("Se han eliminado todos los datos", () => {
                    // location.reload();
                    this.ngOnInit();
                  })
                } else {
                  this.lanzarAlertaFracaso("No se han podido eliminar todos los datos.");
                }
              },
              error: (error) => {
              }
            });
          } else {
            return;
          }
        });
      }
    })
  }

  // Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true,
      timer: 1200
    }).then(f);
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true,
      timer: 1200
    });
  }

  public lanzarAlertaCuidado(contenido: string, f: (result: SweetAlertResult) => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Estás seguro/a?, se borrarán todos los datos",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(225, 175, 25)",
      timer: 7000,
      toast: true,
      timerProgressBar: true
    }).then(f);
  }

  public lanzarAlertaMuchoCuidado(contenido: string, f: (result: SweetAlertResult) => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Confirmar borrado?, se perderán todos los datos y NO se podrán recuperar",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      timer: 7000,
      toast: true,
      timerProgressBar: true
    }).then(f);
  }

}
