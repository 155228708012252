import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../providers/api.service';
import { SwalService } from 'src/app/providers/swal.service';
import { ConflictosService } from 'src/app/providers/conflictos.service';
import { Conflicto, ConflictoEditar, MiniEvento } from 'src/app/models/conflicto.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-comparar-eventos',
  templateUrl: './comparar-eventos.component.html',
  styleUrls: ['../dashboard.component.scss', 'comparar-eventos.component.scss']
})
export class CompararEventosComponent implements OnInit {

  public conflictos: Conflicto[];
  public conflictoSeleccionado: Conflicto;

  public cargando: boolean;
  public anioSeleccionado: number;
  public aniosSeleccionables: number[];
  public filtroAnio: number;
  public filtroMes: number[];

  constructor(
    private _api: ApiService,
    private _swal: SwalService,
    private _conflictos: ConflictosService,
    private _router: Router
  ) {
    this.conflictos = [];
    this.conflictoSeleccionado = this.generarConflicto();
    this.cargando = true;
    this.anioSeleccionado = new Date().getFullYear();
    this.aniosSeleccionables = [];
    this.filtroAnio = 0;
    this.filtroMes = [];
    this.generarAnios();
  }

  ngOnInit() {
    this._conflictos.getAllConflictos().subscribe({
      next: (result) => {
        if (result.status) {
          this.conflictos = result.conflictos;
          this.asignarEventosIndividuales();
          this.cambiarAnio();
        }
      },
      error: (error) => {
      },
      complete: () => { }
    });
  }

  public generarConflicto(): Conflicto {
    return new Conflicto(
      0,
      {
        esSA: false,
        evento: { trayectoEvento: "", codigoEvento: "", fechaEvento: "", horaEvento: "" }
      },
      {
        esSA: false,
        evento: { trayectoEvento: "", codigoEvento: "", fechaEvento: "", horaEvento: "" }
      },
      0,
      ""
    );
  }

  public seleccionarConflicto(c: Conflicto): void {
    this.conflictoSeleccionado = c;
  }


  public eliminarConflicto(seleccionado: string): void {
    let contenidoSele: any;
    let contenidoCont: any;
    if (seleccionado === 'evento_programado2') {
      contenidoSele = this.conflictoSeleccionado.evento_programado2.evento;
      contenidoCont = this.conflictoSeleccionado.evento_programado1.evento;
    } else if (seleccionado === 'evento_programado1') {
      contenidoSele = this.conflictoSeleccionado.evento_programado1.evento;
      contenidoCont = this.conflictoSeleccionado.evento_programado2.evento;
    }
    this._swal.launchQuestionSwal("Eliminar", `¿Borrar ${contenidoCont.trayectoEvento} ${contenidoCont.codigoEvento}  y mantener  ${contenidoSele.trayectoEvento} ${contenidoSele.codigoEvento}?`, "Borrar", "Cancelar", () => {
      const id: number = this.conflictoSeleccionado.id;
      const ce: ConflictoEditar = new ConflictoEditar(id, seleccionado);
      this._conflictos.eliminarConflicto(ce).subscribe({
        next: (result) => {
          if (result.status) {
            this._swal.launchCorrectSwal("Conflicto solucionado", () => {
              const i = this.conflictos.findIndex(c => c.id === id);
              this.conflictos.splice(i, 1);
              this.conflictos = [...this.conflictos];
            });
          } else {
            this._swal.launchErrorSwal("Algo ha fallado", () => { })
          }
        },
        error: () => {
          this._swal.launchErrorSwal("Algo ha fallado", () => { })
        },
        complete: () => { }
      });
    });
  }

  // ASIGNAR EVENTO INDIVIDUAL

  public async asignarEventosIndividuales() {
    for await (const conflicto of this.conflictos) {
      const evento_programado1 = await this.extraerEvento(conflicto.evento_programado1.id);
      conflicto.evento_programado1 = new MiniEvento(
        conflicto.evento_programado1.id,
        evento_programado1.contenidoEvento,
        evento_programado1.esSA
      )
      const evento_programado2 = await this.extraerEvento(conflicto.evento_programado2.id);
      conflicto.evento_programado2 = new MiniEvento(
        conflicto.evento_programado2.id,
        evento_programado2.contenidoEvento,
        evento_programado2.esSA
      )
      conflicto.fecha = new Date(conflicto.fecha);
    }
    this.cargando = false;
  }

  // CONSULTAS EVENTOS INDIVIDUALES

  public async extraerEvento(id: any) {
    let promesa = async () => {
      return await new Promise((resolve, reject) => {
        this._api.getEvento(id).subscribe((result: any) => {
          if (result.status) {
            resolve(result.evento);
          } else {
            reject();
          }
        })
      });
    };
    let trayectoEvento = "";
    let codigoEvento = "";
    let fechaEvento = "";
    let horaEvento = "";
    let esSA: boolean = false;
    await promesa().then((e: any) => {
      trayectoEvento = `${e.trayecto_inicio} - ${e.trayecto_final}`;
      codigoEvento = `${e.codigo}`;
      fechaEvento = `${e.fecha_inicio} - ${e.fecha_final}`;
      horaEvento = `${e.hora_inicio} - ${e.hora_final}`;
      if (e.codigo === "SA" || e.codigo === "OC" || e.codigo === "SR") {
        esSA = true;
      }
    }).catch(() => { });
    return {
      contenidoEvento: {
        trayectoEvento,
        codigoEvento,
        fechaEvento,
        horaEvento
      },
      esSA
    };
  }

  // Dinámico

  public generarAnios(): void {
    this.aniosSeleccionables = [];
    for (let i = 0; i < 3; i++) {
      this.aniosSeleccionables.push(this.anioSeleccionado - i);
    }
  }

  public cambiarAnio() {
    this.filtroAnio = this.anioSeleccionado;
  }

  public modificarFiltroMes(m: number): void {
    if (this.filtroMes.includes(m)) {
      const i: number = this.filtroMes.findIndex(mes => mes === m);
      this.filtroMes.splice(i, 1);
    } else {
      this.filtroMes.push(m);
    }
    this.filtroMes = [...this.filtroMes];
  }

  public comprobarFiltroMes(m: number): boolean {
    return this.filtroMes.includes(m);
  }

  public resetFiltroMes(): void {
    this.filtroMes = [];
  }

  public scrollTo($e: any): void {
    setTimeout(() => {
      if ($e) {
        $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }, 10);
  }

  public volver($e: any): void {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  public goCalendar(): void {
    this._router.navigate(["sepla/calendario", String(this.conflictoSeleccionado.fecha)]);
  }

  existenEventos() {
    let conflictos = [];
    let conflictosFinal = [];
    this.conflictos.forEach(c => {
      const fecha: Date = new Date(c.fecha);
      if (fecha.getFullYear() === this.filtroAnio) {
        conflictos.push(c);
      }
    });
    if (this.filtroMes.length != 0) {
      conflictos.forEach(c => {
        const fecha: Date = new Date(c.fecha);
        if (this.filtroMes.includes(fecha.getMonth() + 1)) {
          conflictosFinal.push(c);
        }
      });
    } else {
      conflictosFinal = conflictos;
    }
    if (conflictosFinal.length == 0) {
      return false;
    } else {
      return true;
    }
  }
}
