import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../providers/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  forgotForm: FormGroup;


  constructor(private _auth: AuthService,
    private _form: FormBuilder, private _ngzone: NgZone, private _router: Router) {
  }

  ngOnInit(): void {
    this.forgotForm = this._form.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  forgot() {
    this._auth.forgotPassword(this.forgotForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this.lanzarAlertaExito("Correo electrónico enviado para modificadar la contraseña", () => {
            this._ngzone.run(() => {
              this._router.navigateByUrl('/login');
            });
          });
        } else {
          this.lanzarAlertaFracaso("El usuario no existe");
        }
      },
      error: (error) => {
        this.lanzarAlertaFracaso("El usuario no existe");
      }
    });
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true,
      timer: 4000
    }).then(f)
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true,
      timer: 2500
    });
  }

}
