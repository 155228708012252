<div class="container-fluid">
    <div class="row">
        <div>
            <fieldset class="fieldCard">
                <legend>
                    <div>
                        <i class="bi bi-file-earmark-text-fill iconoLegend"></i> Nómina
                        <span class="dateNomina">{{datosNomina.mes_hoy}}/{{datosNomina.anio_hoy}}</span>
                    </div>
                </legend>
                <div class="card border-center-primary shadow p-2" *ngIf="infoDiasPerdidos.length > 0">
                    <p class="m-2">Información Días libres:</p>
                    <div class="p-1 card-body" *ngFor="let info of infoDiasPerdidos">
                        {{info.codigo}} - {{info.fecha_inicio}} - {{info.hora_inicio}} / {{info.fecha_final}} -
                        {{info.hora_final}} <button type="button" class="btn btn-danger btn-sm"
                            (click)="eliminarDiaLibre(info.id)"><i class="bi bi-trash-fill"></i></button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="rowDownload row d-flex justify-content-end">
                        <button class="btnDownload" (click)="verDesglose();" value="imprimir div">
                            <i class="bi bi-eye-fill"></i>
                            Ver Desglose Mensual
                        </button>
                        <button class="btnDownload" (click)="descargarPDF();" value="imprimir div">
                            <i class="bi bi-arrow-down-short"></i>
                            Descargar
                        </button>
                    </div>
                    <div class="card border-center-primary shadow">
                        <div class="card-body">
                            <div class="main-container col-lg-12 align-items-center justify-content-center text-center"
                                id="nominaPDF">
                                <!-- BLOQUE A -->
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 negrita14">
                                        {{datosNomina.nombre}} {{datosNomina.apellido1}} {{datosNomina.apellido2}}
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 letra13 text-left">
                                        Mes/Año: {{datosNomina.mes_hoy}}/{{datosNomina.anio_hoy}}
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                        Categoria: {{datosNomina.categoria}}
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                        Nivel: {{datosNomina.nivel}}
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                        Base: {{datosNomina.base}}
                                    </div>
                                    <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                        Flota: {{datosNomina.flota}}
                                    </div>
                                </div>
                                <br>
                                <div class="m-2">
                                    <!-- BLOQUE B-->
                                    <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="4">DEVENGOS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="112,5" height="100%">CONCEPTOS</td>
                                            <td class="negrita14" width="112,5" height="100%">UNIDADES</td>
                                            <td class="negrita14" width="112,5" height="100%">DEVENGOS</td>
                                            <td class="negrita14" width="112,5" height="100%">DEDUCCIONES</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Salario Base</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.salario_base_unidades}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.salario_base_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Plus de Transporte</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.plus_transporte_unidades}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.plus_transporte_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Prima Razón Viaje</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.prima_viaje_unidades}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.prima_viaje_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Plus Asistencia Técnica</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.plus_asistencia_tecnica_unidades}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.plus_asistencia_tecnica_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr *ngIf="datosNomina.prima_responsabilidad_boolean == 'Sí'">
                                            <td class="letra13" width="112,5" height="100%">Prima Responsabilidad Comandante</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.prima_responsabilidad_unidades}}
                                            </td>
                                            <td class="letra13" width="112,5" height="100%" *ngIf="datosNomina.prima_responsabilidad_devengos != NaN">
                                                {{datosNomina.prima_responsabilidad_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr> 
                                        <tr *ngIf="datosNomina.comEconomicoAnexoIV != '0'">
                                            <td class="letra13" width="112,5" height="100%">Com. Económico anexo IV</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.comEconomicoAnexoIV | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <!-- <tr *ngIf="datosNomina.prima_responsabilidad_boolean == 'Sí'">
                                            <td class="letra13" width="112,5" height="100%">P Responsabilidad</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.prima_responsabilidad_unidades}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.prima_responsabilidad_devengos}}
                                            </td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr> -->
                                        <tr *ngIf="datosNomina.dias_debidos_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">Días libres</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dias_debidos_unidades}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dias_debidos_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>

                                        <tr *ngIf="datosNomina.mes_hoy == '12' || datosNomina.mes_hoy == '6'">
                                            <td *ngIf="datosNomina.mes_hoy == '12'" class="letra13" width="112,5"
                                                height="100%">Paga Extra Navidad</td>
                                            <td *ngIf="datosNomina.mes_hoy == '6'" class="letra13" width="112,5"
                                                height="100%">Paga Extra Verano</td>

                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.paga_extra | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr *ngIf="datosNomina.bajas_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">Prestación por Baja</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.bajas_unidades}}
                                            </td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.bajas_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr *ngIf="datosNomina.anticipo_dietas != '0'">
                                            <td class="letra13" width="112,5" height="100%">Total Anticipo Dietas</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.anticipo_dietas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.vacaciones_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">Media Días Vacaciones</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.vacaciones_unidades}}
                                            </td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.vacaciones_devengos | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="100%" height="100%" COLSPAN="4"></td>
                                        </tr>
                                    </table>
                                    <br>
                                    <!-- BLOQUE C-->
                                    <!-- <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="4">RETRIBUCIÓN
                                                FLEXIBLE Y EN ESPECIE
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Seguro Médico
                                            </td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.seguro_medico | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Valor Billetes Free</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.valor_billetes_free | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Comida Crew</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.comida_crew |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                    </table>
                                    <br> -->
                                    <!-- BLOQUE D-->
                                    <!-- <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="4">PLANES DE
                                                PENSIONES
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">Aporte Plan Pensiones
                                            </td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.plan_pensiones | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                        </tr>
                                    </table>
                                    <br> -->
                                    <!-- BLOQUE E -->
                                    <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="3">VARIABLES MES
                                                ANTERIOR
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="150" height="100%">PLUS</td>
                                            <td class="negrita14" width="150" height="100%">UNIDADES</td>
                                            <td class="negrita14" width="150" height="100%">IMPORTE</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.posicion_situacion_unidades !='0'">
                                            <td class="letra13" width="150" height="100%">H Posición Situación</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.posicion_situacion_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.posicion_situacion_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="150" height="100%">Plus Nocturnidad</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_nocturnidad_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_nocturnidad_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="150" height="100%">Plus Horas Vuelo 1</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_vuelo1_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_vuelo1_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="150" height="100%">Plus Horas Vuelo 2</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_vuelo2_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_vuelo2_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="150" height="100%">Plus Imaginaria</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_imaginaria_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.plus_imaginaria_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="150" height="100%">Actividad Extraordinaria</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.actividad_extraordinaria_unidades | number : '1.2-2'}}
                                            </td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.actividad_extraordinaria_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="150" height="100%">Fuera de mínimos</td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.actividad_fuera_minimos_unidades | number : '1.2-2'}}
                                            </td>
                                            <td class="letra13" width="150" height="100%">
                                                {{datosNomina.actividad_fuera_minimos_importe | number : '1.2-2'}}</td>
                                        </tr>
                                    </table>
                                    <br>
                                    <!-- BLOQUE A -->
                                    <!-- <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-6 negrita14">
                                            {{datosNomina.nombre}} {{datosNomina.apellido1}} {{datosNomina.apellido2}}
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 letra13 text-left">
                                            Mes/Año: {{datosNomina.mes_hoy}}/{{datosNomina.anio_hoy}}
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                            Categoria: {{datosNomina.categoria}}
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                            Nivel: {{datosNomina.nivel}}
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                            Base: {{datosNomina.base}}
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 letra13 text-left">
                                            Flota: {{datosNomina.flota}}
                                        </div>
                                    </div>
                                    <br> -->
                                    <!-- BLOQUE F -->
                                    <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="4">VARIABLES MES
                                                ANTERIOR
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="112,5" height="100%">CODIGO</td>
                                            <td class="negrita14" width="112,5" height="100%">UNID.</td>
                                            <td class="negrita14" width="112,5" height="100%">Dietas Sujetas Retención
                                            </td>
                                            <td class="negrita14" width="112,5" height="100%">Dietas Exentas Tributación
                                            </td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNC</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dnc_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNP</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dnp_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnt_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNT</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dnt_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnt_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnt_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dic_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIC</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dic_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dip_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIP</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dip_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dip_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dip_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dit_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIT</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dit_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dit_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dit_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dimc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIMC</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dimc_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dimc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dimc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dimp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIMP</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dimp_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dimp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dimp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLC</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlc_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLP</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlp_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlt_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLT</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlt_unidades |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlt_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlt_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlc2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLC2</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlc2_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlt2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLT2</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlt2_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlt2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlt2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlmc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLMC</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlmc_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlmc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlmc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlmp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLMP</td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.dlmp_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlmp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlmp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnc_dp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNC/DP</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dp_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnc_dp2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNC/DP2 (+30 días)</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dp2_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dp2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dp2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnc_dc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNC/DC</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dc_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dnc_dc2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DNC/DC2 (+30 días)</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dc2_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dc2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dnc_dc2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dic_dp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIC/DP</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dp_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dic_dp2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIC/DP2 (+30 días)</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dp2_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dp2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dp2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dic_dc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIC/DC</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dc_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dic_dc2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DIC/DC2 (+30 días)</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dc2_unidades | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dc2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dic_dc2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlc_dp_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLC/DP</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dp_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dp_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dp_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlc_dp2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLC/DP2 (+30 días)</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dp2_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dp2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dp2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlc_dc_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLC/DC</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dc_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dc_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dc_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr *ngIf="datosNomina.dlc_dc2_unidades != '0'">
                                            <td class="letra13" width="112,5" height="100%">DLMP</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dc2_unidades
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dc2_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.dlc_dc2_imp_exentas | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="100%" height="100%" COLSPAN="4"></td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="112,5" height="100%"></td>
                                            <td class="negrita14" width="112,5" height="100%">TOTALES</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.totales_imp_retencion | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.totales_imp_exentas| number : '1.2-2'}}</td>
                                        </tr>
                                    </table>
                                    <br>
                                    <!-- BLOQUE G -->
                                    <!-- <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="4">TOTAL
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="112,5" height="100%"></td>
                                            <td class="negrita14" width="112,5" height="100%"></td>
                                            <td class="negrita14" width="112,5" height="100%">DEVENGOS</td>
                                            <td class="negrita14" width="112,5" height="100%">DEDUCCIONES</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%"></td>
                                            <td class="negrita14" width="112,5" height="100%"></td>
                                            <td class="letra13" width="112,5" height="100%">{{datosNomina.total_devengos
                                                | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.total_deducciones | number : '1.2-2'}}
                                            </td>
                                        </tr>
                                    </table>
                                    <br> -->
                                    <!-- BLOQUE H -->
                                    <!-- <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="100%" height="100%" COLSPAN="4">DETERMINACIÓN
                                                DE LAS BASES DE COTIZACION DE LA SEG. SOCIAL</td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="112,5" height="100%">REMUNER. TOTAL</td>
                                            <td class="negrita14" width="112,5" height="100%">BASE IT</td>
                                            <td class="negrita14" width="112,5" height="100%">PR PAGAS EXTRA</td>
                                            <td class="negrita14" width="112,5" height="100%">TOTAL</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.remuner_total_seg_social | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.base_it_seg_social | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.pr_pagas_extra_seg_social | number : '1.2-2'}}</td>
                                            <td class="letra13" width="112,5" height="100%">
                                                {{datosNomina.total_seg_social | number : '1.2-2'}}</td>
                                        </tr>
                                    </table>
                                    <br> -->
                                    <!-- SEXTO BLOQUE -->
                                    <!-- <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="letra13" width="180" height="100%"></td>
                                            <td class="negrita14" width="90" height="100%">BASES</td>
                                            <td class="negrita14" width="90" height="100%">%</td>
                                            <td class="negrita14" width="90" height="100%">IMPORTE</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">Cuota Contingencias Comunes
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.contingencias_comunes_bases | number : '1.2-2'}}</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.contingencias_comunes_porciento}}%
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.contingencias_comunes_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">Cuota Desempleo Trabajador
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.desempleo_trabajador_bases | number : '1.2-2'}}</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.desempleo_trabajador_porciento}}%</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.desempleo_trabajador_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">Cuota Formación Prof Trabaj
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.formacion_prof_trabaj_bases | number : '1.2-2'}}</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.formacion_prof_porciento}}%</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.formacion_prof_trabaj_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">I.R.P.F</td>
                                            <td class="letra13" width="90" height="100%">{{datosNomina.irpf_bases |
                                                number : '1.2-2'}}</td>
                                            <td class="letra13" width="90" height="100%">{{datosNomina.irpf_porciento}}%
                                            </td>
                                            <td class="letra13" width="90" height="100%">{{datosNomina.irpf_importe |
                                                number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="100%" height="100%" COLSPAN="4"></td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%"></td>
                                            <td class="letra13" width="90" height="100%"></td>
                                            <td class="negrita14" width="90" height="100%">TOTAL SEG. SOCIAL</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.final_total_seg_social_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%"></td>
                                            <td class="letra13" width="90" height="100%"></td>
                                            <td class="negrita14" width="90" height="100%">TOTAL RETENCIONES</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.final_total_retenciones_importe | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%"></td>
                                            <td class="letra13" width="90" height="100%"></td>
                                            <td class="negrita14" width="90" height="100%">LIQUIDO A PERCIBIR</td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.final_liquido_percibir_importe | number : '1.2-2'}}</td>
                                        </tr>
                                    </table> -->
                                    <table border="2" cellspacing="1" style="border-collapse: collapse"
                                        bordercolor="#111111" width="100%" height="100%">
                                        <tr>
                                            <td class="negrita14" width="90" height="100%" COLSPAN="2">LIMITES SPL Y PPE</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">Máximo Pérdida de Licencia	
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{maximos_licencia_jubilacion.perdida_licencia | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">Máximo Plan de Jubilación	
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{maximos_licencia_jubilacion.jubilacion | number : '1.2-2'}}</td>
                                        </tr>
                                        <tr>
                                            <td class="negrita14" width="90" height="100%" COLSPAN="2">INFORMACIÓN</td>
                                        </tr>
                                        <tr>
                                            <td class="letra13" width="180" height="100%">CALCULO DE HORAS DE ACTIVIDAD
                                                + IMAGINARIA
                                            </td>
                                            <td class="letra13" width="90" height="100%">
                                                {{datosNomina.actividad_mas_imaginaria | number : '1.2-2'}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <!-- Aqui sí se imprime -->
                            </div>
                            <!-- Aqui no se imprime -->
                        </div>
                    </div>
                </div>
                <div class="rowBack row d-flex justify-content-end">
                    <a class="btnBack" href="javascript:history.back()">
                        Volver
                    </a>
                </div>
            </fieldset>
        </div>
    </div>
</div>