<div [class]="event">

    <app-nav id="navBarra" [class]="class" (newItemEvent)="sidebar($event)"></app-nav>

    <div id="layoutSidenav" class="sideContainer">
        <div id="layoutSidenav_nav" class="sideBarra">
            <app-side [class]="class" (newItemEvent)="sidebar($event)"></app-side>
        </div>
    </div>

    <div class="container-fluid" (click)="hideSide('');">
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>

</div>