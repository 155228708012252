import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side-dashboard.component.scss', './side.component.scss']
})
export class SideComponent implements OnInit {

  @Output() newItemEvent = new EventEmitter();
  @Input() class: Boolean = true;

  public usuario: any = {};
  public menuItems: any[] = [];
  public titleItems: any[] = [];

  constructor() { }

  ngOnInit(): void {
    let titulos = [];
    this.menuItems = ROUTES;
    for (let i = 0; i < this.menuItems.length; i++) {
      const item = this.menuItems[i];
      titulos.push(item.group);
    }
    this.titleItems = [...new Set(titulos)];
  }

  sidebar(status: boolean) {
    if (status) {
      this.newItemEvent.emit('')
    } else {
      this.newItemEvent.emit('sb-sidenav-toggled')
    }
    this.class = !this.class;
  }

}

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  group: string;
}

export const ROUTES: RouteInfo[] = [
  { path: 'info-anual', title: 'Resumen Anual', icon: 'iconoNav bi bi-clipboard-data-fill', class: 'sb-nav-link-icon', group: 'Navegación' },
  { path: 'lista-destacamento', title: 'Lista Destacamento', icon: 'iconoNav bi bi-signpost-2', class: 'sb-nav-link-icon', group: 'Navegación' },
  { path: 'nominas', title: 'Nóminas', icon: 'iconoNav bi bi-file-earmark-text-fill', class: 'sb-nav-link-icon', group: 'Navegación' },
  { path: 'filecsv', title: 'Añadir Eventos', icon: 'iconoNav bi bi-file-earmark-plus', class: 'sb-nav-link-icon', group: 'Navegación' },
  { path: 'calendario/0', title: 'Calendario', icon: 'iconoNav bi bi-calendar-fill', class: 'sb-nav-link-icon', group: 'Navegación' },
  { path: 'datos', title: 'Lista Eventos', icon: 'iconoNav bi bi-list-ul', class: 'sb-nav-link-icon', group: 'Navegación' },
  { path: 'aeropuertos', title: 'Aeropuertos', icon: 'iconoNav bi bi-pin-map-fill', class: 'sb-nav-link-icon', group: 'Navegación' },
  // { path: 'aviones', title: 'Aviones', icon: 'iconoNav bi bi-send-fill', class: 'sb-nav-link-icon', group: 'Navegación' },
  // { path: 'lista-perfiles', title: 'Perfiles Vuelo', icon: 'iconoNav bi bi-cloud-fog2-fill', class: 'sb-nav-link-icon', group: 'Navegación' }

];