import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../providers/auth.service';
import { SwalService } from 'src/app/providers/swal.service';

@Component({
  selector: 'app-lista-nominas',
  templateUrl: './lista-nominas.component.html',
  styleUrls: ['../dashboard.component.scss', './lista-nominas.component.scss']
})
export class ListaNominasComponent implements OnInit {

  nominas: any[];
  nominasForm: FormGroup;

  anioSeleccionado: string = new Date().getFullYear().toString();
  aniosSeleccionables: string[] = [];

  mesesAnios: any = [];
  clavesAnios: any[] = [];
  mesesNombres: any[] = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  mesesAniosBonitos: any = {};
  niveles: any;
  bases = new Array({ nombre: 'Madrid', value: 'Madrid' }, { nombre: 'Las Palmas', value: 'Palmas' }, { nombre: 'Palma de Mallorca', value: 'Mallorca' }, { nombre: 'Barcelona', value: 'Barcelona' }, { nombre: 'Tenerife Norte', value: 'Tenerife' }, { nombre: 'Tenerife Sur', value: 'Granadilla de Abona, Tenerife' }, { nombre: 'Málaga', value: 'Málaga' }, { nombre: 'Valencia', value: 'Valencia' });
  flotas = new Array('B737', 'B787');
  categorias = new Array('P1', 'P2');

  constructor(
    private _api: ApiService,
    private _formB: FormBuilder,
    private _router: Router,
    private _auth: AuthService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.consultarCalendario();
    this.generarAnios();
    this._auth.getNiveles().subscribe({
      next: (result) => {
        if (result.status) {
          this.extraerNiveles(result.niveles);
        }
      },
      error: (error) => {
      }
    });
    this._auth.getPiloto().subscribe({
      next: (result) => {
        if (result.status) {
          this._api.getNominas().subscribe({
            next: (res) => {
              if (res.status) {
                this.ordenarNominas(res.nominas);
                this.crearFormulario(result.piloto, this.buscarUltimaNomina(res.nominas));
              } else {
                this.crearFormulario(result.piloto);
              }
            },
            error: (error) => {
              this.crearFormulario(result.piloto);

            }
          });
        }
      },
      error: (error) => {

      }
    });
  }

  extraerNiveles(niveles) {
    this.niveles = niveles;
  }

  crearFormulario(piloto: any, nomina?: any) {
    let nivelPiloto = piloto.nivel;
    if (nomina) {
      if (nivelPiloto) {
        let nivelNomina = nomina.nDatos?.nivel.split("/")

        if (nivelNomina.length == 2) {
          let nivel = nivelNomina[1].trim();
          if (nivel.includes("BIS")) {
            nivel = "N9P2BIS";
          } else {
            if (!nivel.includes("P")) {
              nivel = nivel + nomina.nDatos?.categoria;
            }
          }
          nivelPiloto = this.niveles.find(objeto => objeto.nivel == nivel);
        }
        if (nivelNomina.length == 1) {
          let nivel = nivelNomina[0].trim()
          if (nivel.includes("BIS")) {
            nivel = "N9P2BIS";
          } else {
            if (!nivel.includes("P")) {
              nivel = nivel + nomina.nDatos?.categoria;
            }
          }
          nivelPiloto = this.niveles.find(objeto => objeto.nivel == nivel);
        }
      }
    }

    this.nominasForm = this._formB.group({
      mes_hoy: [nomina ? nomina.nDatos?.mes_hoy : '1', [Validators.required, Validators.maxLength(2), Validators.minLength(1)]],
      anio_hoy: [nomina ? nomina.nDatos?.anio_hoy : new Date().getFullYear().toString(), [Validators.required, Validators.maxLength(4), Validators.minLength(4)]],
      seguro_medico_boolean: [nomina ? nomina.nRetribucion?.seguro_medico_boolean : 'No', [Validators.required]],
      seguro_medico: [nomina ? nomina.nRetribucion?.seguro_medico == 0 ? '' : nomina.nRetribucion?.seguro_medico : ''],
      miembros_seguro_medico: [nomina ? nomina.nRetribucion?.miembros_seguro_medico == 0 ? '' : nomina.nRetribucion?.miembros_seguro_medico : ''],
      globalia_flex_boolean: [nomina ? nomina.nRetribucion?.globalia_flex_boolean : 'No', [Validators.required]],
      globalia_flex: [nomina ? nomina.nRetribucion?.globalia_flex == 0 ? '' : nomina.nRetribucion?.globalia_flex : ''],
      plan_pensiones_boolean: [nomina ? nomina.nPension?.plan_pensiones_boolean : 'No', [Validators.required]],
      plan_pensiones: [nomina ? nomina.nPension?.plan_pensiones == 0 ? '' : nomina.nPension?.plan_pensiones : ''],
      valor_billetes_free: [nomina ? nomina.nRetribucion?.valor_billetes_free == 0 ? '' : nomina.nRetribucion?.valor_billetes_free : ''],
      comida_crew: [nomina ? nomina.nRetribucion?.comida_crew == 0 ? '' : nomina.nRetribucion?.comida_crew : ''],
      vacaciones_devengos: [],
      anticipo_dietas: [],
      reduccion_jornada: [nomina ? nomina.nDatos?.reduccion_jornada : piloto.reduccion_jornada, [Validators.required]],
      flota: [nomina ? nomina.nDatos?.flota : piloto.flota, [Validators.required]],
      categoria: [nomina ? nomina.nDatos?.categoria : piloto.categoria, [Validators.required]],
      nivel: [nomina ? nivelPiloto.id : piloto.nivel.id, [Validators.required]],
      irpf_porciento: [nomina ? nomina.nTotales?.irpf_porciento == 0 ? '' : nomina.nTotales?.irpf_porciento : ''],
      segundo_nivel_boolean: ['No', [Validators.required]],
      dias_segundo_nivel: ['0'],
      segundo_nivel: [piloto ? piloto.nivel.id : '0'],
      bases: [nomina ? nomina.nTotales?.contingencias_comunes_bases == 0 ? '' : nomina.nTotales?.contingencias_comunes_bases : ''],
      contingenciasComunes: [nomina ? nomina.nTotales?.contingencias_comunes_porciento == 0 ? '' : nomina.nTotales?.contingencias_comunes_porciento : ''],
      desempleo: [nomina ? nomina.nTotales?.desempleo_trabajador_porciento == 0 ? '' : nomina.nTotales?.desempleo_trabajador_porciento : ''],
      formacionProfesional: [nomina ? nomina.nTotales?.formacion_prof_porciento == 0 ? '' : nomina.nTotales?.formacion_prof_porciento : ''],
      // perdidaLicencia: [nomina ? nomina.nDevengo?.perdida_licencia_devengos == 0 ? '' : nomina.nDevengo?.perdida_licencia_devengos : ''],
      perdidaLicencia: [''],
    });
  }

  cargarNominas(nominas: any) {
    this.nominas = nominas;
  }

  ordenarNominas(nominas: any) {
    let nominasOrdenadas = nominas.sort((a: any, b: any) => {
      let aFecha = Number(`${a.anio_hoy}${a.mes_hoy}`);
      let bFecha = Number(`${b.anio_hoy}${b.mes_hoy}`);
      return aFecha - bFecha;
    });
    this.cargarNominas(nominasOrdenadas);
  }

  iniciar(tipo) {
    this.nominasForm.get(tipo).setValue('');
  }

  async crearNomina() {
    let contiene_meses = []
    let anio_hoy = this.nominasForm.value.anio_hoy;
    let mes_hoy = this.nominasForm.value.mes_hoy;
    // Buscar si tiene las últimas 5 nóminas creadas
    if (mes_hoy == 6 || mes_hoy == 12) {
      let inicio = mes_hoy == 6 ? 1 : 7;
      for (let i = inicio; i < mes_hoy; i++) {
        const mes = [i];
        contiene_meses.push(this.mesesAnios[anio_hoy].includes(mes.toString()))
      }
      if (contiene_meses.every(elemento => elemento === true)) {
        this.realizarNomina(anio_hoy, mes_hoy)
      } else {
        this._swal.launchQuestionSwal("Información", `No se calculará completamente la paga extra, ya que necesitas tener creadas las cinco nóminas anteriores.`, "Continuar", "Salir", () => {
          this.realizarNomina(anio_hoy, mes_hoy)
        })
      }
    } else {
      this.realizarNomina(anio_hoy, mes_hoy)
    }
  }

  async realizarNomina(anio_hoy, mes_hoy) {
    this._api.getDiasLibresPerdidos(anio_hoy, mes_hoy).subscribe({
      next: (result) => {
        let infoDiasPerdidos = [];
        let mensaje = 'días libres perdidos';
        if (result.status) {
          infoDiasPerdidos = result.dias_perdidos_mes;
          mensaje = `${infoDiasPerdidos.length > 1 ? 'días libres perdidos, si no es correcto eliminarlos y vuelve a crear la nómina.' : 'día libre perdido, si no es correcto eliminarlo y vuelve a crear la nómina'}`;
        }
        this._swal.launchQuestionSwal("Información", `Comprueba las banderas del calendario de este mes y el comparador de evento para ajustar la nómina. Tienes ${infoDiasPerdidos.length} ${mensaje}`, "Continuar", "Salir", () => {
          this.mostrarGenerar = false;
          this.loading = false;
          this.loading = true;
          if (this.nominasForm.value.seguro_medico == null || this.nominasForm.value.seguro_medico == '') this.nominasForm.value.seguro_medico = '0';
          if (this.nominasForm.value.miembros_seguro_medico == null || this.nominasForm.value.miembros_seguro_medico == '') this.nominasForm.value.miembros_seguro_medico = '0';
          if (this.nominasForm.value.globalia_flex == null || this.nominasForm.value.globalia_flex == '') this.nominasForm.value.globalia_flex = '0';
          if (this.nominasForm.value.dias_segundo_nivel == null || this.nominasForm.value.dias_segundo_nivel == '') this.nominasForm.value.dias_segundo_nivel = '0';
          if (this.nominasForm.value.plan_pensiones == null || this.nominasForm.value.plan_pensiones == '') this.nominasForm.value.plan_pensiones = '0';
          if (this.nominasForm.value.valor_billetes_free == null || this.nominasForm.value.valor_billetes_free == '') this.nominasForm.value.valor_billetes_free = '0';
          if (this.nominasForm.value.comida_crew == null || this.nominasForm.value.comida_crew == '') this.nominasForm.value.comida_crew = '0';
          if (this.nominasForm.value.vacaciones_devengos == null || this.nominasForm.value.vacaciones_devengos == '') this.nominasForm.value.vacaciones_devengos = '0';
          if (this.nominasForm.value.anticipo_dietas == null || this.nominasForm.value.anticipo_dietas == '') this.nominasForm.value.anticipo_dietas = '0';
          if (this.nominasForm.value.irpf_porciento == null || this.nominasForm.value.irpf_porciento == '') this.nominasForm.value.irpf_porciento = '0';
          this.nominasForm.value.dias_segundo_nivel = this.nominasForm.value.dias_segundo_nivel.replace(',', '.');
          this.nominasForm.value.plan_pensiones = this.nominasForm.value.plan_pensiones.replace(',', '.');
          this.nominasForm.value.valor_billetes_free = this.nominasForm.value.valor_billetes_free.replace(',', '.');
          this.nominasForm.value.comida_crew = this.nominasForm.value.comida_crew.replace(',', '.');
          this.nominasForm.value.vacaciones_devengos = this.nominasForm.value.vacaciones_devengos.replace(',', '.');
          this.nominasForm.value.seguro_medico = this.nominasForm.value.seguro_medico.replace(',', '.');
          this.nominasForm.value.anticipo_dietas = this.nominasForm.value.anticipo_dietas.replace(',', '.');
          this.nominasForm.value.irpf_porciento = this.nominasForm.value.irpf_porciento.replace(',', '.');

          this._api.setNomina(this.nominasForm.value).subscribe({
            next: (result) => {
              if (result.status) {
                this._swal.launchCorrectSwal("Nómina generada", () => {
                  this.mostrarGenerar = false;
                  this.loading = false;
                  this._router.navigate(['/sepla/nomina', this.nominasForm.value.anio_hoy, this.nominasForm.value.mes_hoy]);
                });
              } else {
                if (result.error) {
                  this._swal.launchErrorSwal(result.error, () => {
                    this.loading = false;
                  });
                } else {
                  this._swal.launchErrorSwal("Contacte con administración", () => {
                    this.loading = false;
                  });
                }
              }
            },
            error: (error) => {
              this.loading = false;
            }
          });
        });
      },
      error: (error) => {
      }
    });
  }

  entrarNomina(anio: any, mes: any) {
    this.nominasForm.value.plan_pensiones = this.nominasForm.value.plan_pensiones.replace(',', '.');
    this.nominasForm.value.valor_billetes_free = this.nominasForm.value.valor_billetes_free.replace(',', '.');
    this.nominasForm.value.comida_crew = this.nominasForm.value.comida_crew.replace(',', '.');
    this.nominasForm.value.vacaciones_devengos = this.nominasForm.value.vacaciones_devengos.replace(',', '.');
    this.nominasForm.value.seguro_medico = this.nominasForm.value.seguro_medico.replace(',', '.');
    this.nominasForm.value.anticipo_dietas = this.nominasForm.value.anticipo_dietas.replace(',', '.');
    this._api.setNomina(this.nominasForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("La nómina se ha generado correctamente", () => {
            this._router.navigate(['/sepla/nomina', anio, mes])
          });
        } else {
          this._swal.launchErrorSwal("Algo salió mal.1", () => { });
        }
      },
      error: (error) => {
      }
    })
  }

  actualizarNomina(anio: any, mes: any) {
    let i = this.mesesNombres.findIndex(mesesNombre => mesesNombre === mes);
    mes = i + 1;
    // for (let x = 0; x < this.nominas.length; x++) {
    //   const nomina = this.nominas[x];
    //   if (nomina.mes_hoy == mes && nomina.anio_hoy == anio) {
    //     this.nominasForm.controls.mes_hoy.setValue(nomina.mes_hoy);
    //     this.nominasForm.controls.anio_hoy.setValue(nomina.anio_hoy);
    //     this.nominasForm.controls.planPensionesPropio.setValue(nomina.planPensionesPropio);
    //     this.nominasForm.controls.plan_pensiones.setValue(nomina.plan_pensiones);
    //     this.nominasForm.controls.valor_billetes_free.setValue(nomina.valor_billetes_free);
    //     this.nominasForm.controls.comida_crew.setValue(nomina.comida_crew);
    //     this.nominasForm.controls.seguroMedicoPropio.setValue(nomina.seguroMedicoPropio);
    //     this.nominasForm.controls.seguro_medico.setValue(nomina.seguro_medico);
    //     this.nominasForm.controls.globaliaFlex.setValue(nomina.globaliaFlex);
    //     this.nominasForm.controls.anticipo_dietas.setValue(nomina.anticipo_dietas);
    //     this._api.eliminarNomina(nomina.id_nomina).subscribe(result => {
    //       if (result.status) {
    //         let i = this.nominas.findIndex(nomin => nomin.id_nomina === nomina.id_nomina);
    //         if (i !== -1) {
    //           this.nominas.splice(i, 1);
    //           this.entrarNomina(anio, mes);
    //         }
    //       } else {
    //         Swal.fire({
    //           icon: 'error',
    //           title: 'Error',
    //           text: 'Error al actualizar la nómina'
    //         });
    //       }
    //     });
    //   }
    // }
    this._router.navigate(['/sepla/nomina', anio, mes]);
  }

  eliminarNomina(anio: any, mes: any) {
    try {
      let i = this.mesesNombres.findIndex(mesesNombre => mesesNombre === mes);
      mes = i + 1;
      for (let x = 0; x < this.nominas.length; x++) {
        const nomina = this.nominas[x];
        if (nomina.nDatos.mes_hoy == mes && nomina.nDatos.anio_hoy == anio) {
          this._swal.launchQuestionSwal("¿Eliminar Nómina?", `${this.mesesNombres[mes - 1]} ${anio}`, "Eliminar", "Cancelar", () => {
            this._api.eliminarNomina(nomina.id).subscribe({
              next: (result) => {
                if (result.status) {
                  let i = this.nominas.findIndex(nomin => nomin.id === nomina.id);
                  if (i !== -1) {
                    this.nominas.splice(i, 1);
                    this._swal.launchCorrectSwal("Nómina eliminada", () => {
                      this.consultarCalendario();
                      // location.reload();
                      this.mesesAniosBonitos = {};
                      this.ngOnInit();
                    });
                  }
                } else {
                  this._swal.launchErrorSwal("Algo salió mal.2", () => { });
                }
              },
              error: (error) => {
              }
            });
          });

        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  // CONSULTAR CALENDARIO

  consultarCalendario() {
    this.mesesAnios = [];
    this.clavesAnios = [];
    this._api.getCalendario().subscribe({
      next: (res) => {
        if (res.status) {
          this.mesesAnios = res.mesesAnios;
          this.clavesAnios = Object.keys(this.mesesAnios).reverse();
          this.decoraCalendario();
        }
      },
      error: (error) => {
      }
    });
  }

  decoraCalendario() {
    for (let i = 0; i < this.clavesAnios.length; i++) {
      let mesesBonitos: string[] = [];
      let anio = this.clavesAnios[i];

      let mesesFeos = this.ordenaCalendario(this.mesesAnios[(anio)]);
      for (let m = 1; m < 13; m++) {
        if (mesesFeos.includes(m)) {
          mesesBonitos.push(this.mesesNombres[m - 1]);
        } else {
          mesesBonitos.push("nc");
        }
      }
      this.mesesAniosBonitos[(anio)] = mesesBonitos;
      mesesBonitos = [];
    }
  }

  ordenaCalendario(mesesFeos: any): any[] {
    let mesesOrdenados = [];
    for (let i = 0; i < mesesFeos.length; i++) {
      const mes = mesesFeos[i];
      mesesOrdenados.push(Number(mes));
    }
    return mesesOrdenados.sort((a, b) => { return a - b });
  }

  buscarUltimaNomina(nominas: any) {
    let id_ultimaNomina = 0;
    let ultimaNomina = {};
    for (let x = 0; x < nominas.length; x++) {
      const nomina = nominas[x];
      if (Number(nomina.id) > Number(id_ultimaNomina)) {
        id_ultimaNomina = nomina.id;
        ultimaNomina = nomina;
      }
    }
    return ultimaNomina;
  }

  // Dinámico

  public mostrarGenerar: boolean = false;
  public loading: boolean = false;

  public mostrarGenerarNomina(): void {
    this.mostrarGenerar = !this.mostrarGenerar;
  }

  generarAnios() {
    this.aniosSeleccionables = [];
    let fechaHoy = new Date();
    for (let i = 0; i < 5; i++) {
      this.aniosSeleccionables.push(String(fechaHoy.getFullYear() - i));
    }
  }

  addNomina(mesNombre: string) {
    this.mostrarGenerar = true;
    this.nominasForm.get("anio_hoy").setValue(this.anioSeleccionado);
    this.nominasForm.get("mes_hoy").setValue(this.mesesNombres.indexOf(mesNombre) + 1);
  }

  scrollTo($e: any) {
    setTimeout(() => {
      if ($e) {

        $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }, 10);
  }

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      this.mostrarGenerar = false;
    }
  }

  calcularPrecioVacaciones() {
    this._api.calcularPrecioVacaciones(this.nominasForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this.nominasForm.get("vacaciones_devengos").setValue(result.value);
          if (result.meses != '12') {
            this._swal.launchErrorSwal(`Necesitas 12 nóminas anteriores, tienes ${result.meses}`, () => {

            });
          } else {
            this._swal.launchCorrectSwal(`Datos actualizados, tienes ${result.meses} nóminas de meses anteriores`, () => {
            });
          }
        } else {
          if (result.error) {
            this._swal.launchErrorSwal(result.error, () => {

            });
          } else {
            this._swal.launchErrorSwal("Contacte con administración", () => {

            });
          }
        }
      },
      error: (error) => {
        this._swal.launchErrorSwal("Contacte con administración", () => {

        });
      }
    });
  }
}
