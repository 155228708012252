import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../providers/api.service';
import { NActividad, NActividadResponse } from 'src/app/interfaces/nActividad-response.interface';

@Component({
  selector: 'app-info-nomina',
  templateUrl: './info-nomina.component.html',
  styleUrls: ['../dashboard.component.scss', './info-nomina.component.scss']
})
export class InfoNominaComponent {

  nominaId: number;
  anio: string;
  mes: string;
  loading: boolean = true;
  actividadesNomina: NActividad[] | undefined;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly apiService: ApiService
  ) {
    this.anio = this.activatedRoute.snapshot.paramMap.get('anio_hoy');
    this.mes = this.activatedRoute.snapshot.paramMap.get('mes_hoy');
    this.nominaId = Number(this.activatedRoute.snapshot.paramMap.get('nomina_id'));
    this.getNActividad();
  }

  getNActividad(): void {
    this.apiService.getNActividad(this.nominaId).subscribe({
      next: (result: NActividadResponse) => {
        if (result.status) {
          this.loading = false;
          this.actividadesNomina = result.nActividades;
        }
      },
      error: () => { }
    });
  }

}
