import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/providers/api.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-info-anual',
  templateUrl: './info-anual.component.html',
  styleUrls: ['../dashboard.component.scss', './info-anual.component.scss']
})
export class InfoAnualComponent implements OnInit {

  cargando: boolean = true;


  datosObj: any;

  anioSeleccionado: string = new Date().getFullYear().toString();
  aniosSeleccionables: string[] = [];

  mesesProcesados: any[] = [];
  mesesCargados: any[] = [];
  mesesNombres: any[] = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];


  constructor(
    private _api: ApiService,
    private _dashboard: DashboardService,
  ) {
  }

  ngOnInit(): void {
    this.restablecerDatos();
    this.cambiarAnio();
    this.inicializarMeses();
    this.generarAnios();
  }

  inicializarMeses() {
    this.mesesProcesados = [];
    for (let l = 0; l < 12; l++) {
      this.mesesProcesados.push('badge-danger');
    }
  }

  restablecerDatos() {
    this.datosObj = {
      procesoVuelo: 0,
      procesoDiasLibres: 0,
      sumaVuelos: 0,
      diasLibres: 0,
      sumaVacaciones: 0,
      sumaSalario: 0,
      sumaActividad: 0,
      mesesCargados: []
    }
    this.mesesProcesados = [];
    this.mesesCargados = [];
  }


  totalAnualesVuelo() {
    this._api.getEventosAnio(Number(this.anioSeleccionado)).subscribe({
      next: (result) => {
        if (result.status) {
          for (let x = 0; x < result.eventos.vuelos.length; x++) {
            let vuelo = result.eventos.vuelos[x];
            vuelo.h_vuelo = this._dashboard.eventosMinutos(vuelo.fecha_inicio, vuelo.hora_inicio, vuelo.fecha_final, vuelo.hora_final)
            this.datosObj.sumaVuelos = this.datosObj.sumaVuelos + Number(vuelo.h_vuelo);
          }
          this.datosObj.sumaVuelos = this.datosObj.sumaVuelos / 60;
        }
      },
      error: (error) => {
        console.log('error', error);
      }
    })
  }

  
  totalAnualesActividad() {
    this._api.getNActividadPorAnio(Number(this.anioSeleccionado)).subscribe({
      next: (result) => {
        if (result.status) {
          let sumaTotalHorasActividad = 0;
          for (let x = 0; x < result.nActividades.length; x++) {
            const nActividad = result.nActividades[x];
            const horasActividad = nActividad.horasActividad;

            const [horas, minutos] = horasActividad.split(':')
            const minutosTotales = parseInt(horas) * 60 + parseInt(minutos); 

            sumaTotalHorasActividad += minutosTotales
          }
          this.datosObj.sumaActividad = sumaTotalHorasActividad / 60;
        }
      },
      error: (error) => {
        console.log('error', error);
      }
    })
  }


  cambiarAnio() {
    this.cargando = true;
    this.restablecerDatos();
    this._api.getDatosAnuales(Number(this.anioSeleccionado)).subscribe({
      next: (res) => {
        if (res.status) {
          this.datosObj = res.datos;
          this.mesesCargados = res.datos.mesesCargados;
          this.totalAnualesVuelo()
          this.totalAnualesActividad()

        }
        this.actualizarMeses();
      },
      error: (error) => {
        this.actualizarMeses();
      }
    });
  }


  actualizarMeses() {
    this.inicializarMeses();
    for (let x = 0; x < this.mesesCargados.length; x++) {
      const mes = this.mesesCargados[x];
      this.mesesProcesados[Number(mes) - 1] = 'badge-success';
    }
    this.cargando = false;
  }

  generarAnios() {
    this.aniosSeleccionables = [];
    for (let i = 0; i < 3; i++) {
      this.aniosSeleccionables.push(String(Number(this.anioSeleccionado) - i));
    }
  }

  eliminarMes(mes: any) {
    mes = mes + 1;
    let anio = this.anioSeleccionado;

    this.lanzarAlertaCuidado(`BORRAR ${mes}/${anio}`, (result) => {
      if (result.isConfirmed) {
        this.lanzarAlertaMuchoCuidado(`VAS A BORRAR TODOS LOS DATOS DEL ${mes}/${anio}.`, (result) => {
          if (result.isConfirmed) {
            this._api.eliminarTodoUnMesEventos(mes, anio).subscribe({
              next: (result) => {
                if (result.status) {
                  this.lanzarAlertaExito(`Se han eliminado todos los datos del ${mes}/${anio}.`, () => {
                    // location.reload();
                    this.ngOnInit();
                  })
                } else {
                  this.lanzarAlertaFracaso(`No se han podido eliminar los datos del ${mes}/${anio}.`);
                }
              },
              error: (error) => {
              }
            });
          } else {
            return;
          }
        });
      }
    })
  }

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true,
      timer: 1200
    }).then(f);
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true,
      timer: 1200
    });
  }

  public lanzarAlertaCuidado(contenido: string, f: (result: SweetAlertResult) => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Estás seguro/a?, se borrarán todos los datos",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(225, 175, 25)",
      timer: 7000,
      toast: true,
      timerProgressBar: true
    }).then(f);
  }

  public lanzarAlertaMuchoCuidado(contenido: string, f: (result: SweetAlertResult) => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Confirmar borrado?, se perderán todos los datos y NO se podrán recuperar",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      timer: 7000,
      toast: true,
      timerProgressBar: true
    }).then(f);
  }

}