<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-send-fill iconoLegend"></i>
                    Aviones
                </legend>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card shadow mb-4">
                            <div class="card-body row">
                                <div class="col-md-6 col-sm-12">
                                    <span *ngIf="!mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar todo</button></span>
                                    <span *ngIf="mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar menos</button></span>
                                </div>
                                <div class="col-12" *ngIf="!mostrarTodo">
                                    <div class="row m-2"
                                        *ngFor="let elemento of lista | paginate: {itemsPerPage: 4, currentPage: pageActual}">
                                        <div
                                            class="col-md-12 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            <b> Clave:</b> {{elemento.clave}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <i [ngClass]="iconos(4)"></i> <strong> {{elemento.matricula}}</strong>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <b> Mtow:</b> {{elemento.mtow}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> Pasajeros:</b> {{elemento.n_pasajeros}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> Tipo:</b> {{elemento.tipo}}
                                        </div>
                                    </div>
                                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente"
                                        *ngIf="lista !== undefined && !mostrarTodo" class="text-left"
                                        (pageChange)="pageActual = $event"></pagination-controls>
                                </div>
                                <div class="col-12" *ngIf="mostrarTodo">
                                    <div class="row m-2" *ngFor="let elemento of lista">
                                        <div
                                            class="col-md-12 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            <b> Clave:</b> {{elemento.clave}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <i [ngClass]="iconos(4)"></i> <strong>{{elemento.matricula}}</strong>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <b> Mtow:</b> {{elemento.mtow}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> Pasajeros:</b> {{elemento.n_pasajeros}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> Tipo:</b> {{elemento.tipo}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>