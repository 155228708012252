import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { NActividadResponse } from '../interfaces/nActividad-response.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private url = environment.path;
  private url787 = environment.path787;

  private auth = this._storage.loadTokenData();
  headers: any;

  constructor(private _http: HttpClient, private _storage: StorageService) {
    this.actualizarHeader();
  }

  actualizarHeader() {
    this.auth = this._storage.loadTokenData();
    this.headers = { token: this.auth };
  }

  //USUARIO

  getUser(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'user/', { headers: this.headers });
  }

  getUsers(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'users/', { headers: this.headers });
  }

  editStatus(usuarioId: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'editStatus/',
      { usuarioId },
      { headers: this.headers }
    );
  }

  resetAttempts(usuarioId: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'reseteoIntentos/',
      { usuarioId },
      { headers: this.headers }
    );
  }

  editSindicato(usuarioId: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'editSindicato/',
      { usuarioId },
      { headers: this.headers }
    );
  }

  eliminarTodo(): Observable<any> {
    this.actualizarHeader();
    return this._http.delete(this.url + 'eliminarTodo/', {
      headers: this.headers,
    });
  }

  // JUBILACIÓN

  getNiveles(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'niveles/', { headers: this.headers });
  }

  // EVENTO

  subirFicheroProgramado(mes, anio, file: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'leerProgramacionPdf/' + mes + '/' + anio + '/',
      file,
      { headers: this.headers }
    );
  }

  enviarTextArea(datos: any, tipo: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'enviartextarea/' + tipo, datos, {
      headers: this.headers,
    });
  }

  subirFichero(mes, anio, file: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'insertarFicheroSindicato/' + mes + '/' + anio,
      file,
      { headers: this.headers }
    );
  }

  getEventos(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'eventos/', { headers: this.headers });
  }

  getEventosServicio(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'eventosservicio/', {
      headers: this.headers,
    });
  }

  getTodosEventosServicio(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'eventostodosservicio/', {
      headers: this.headers,
    });
  }

  getEventosAnio(anio: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'eventos/' + anio, {
      headers: this.headers,
    });
  }

  getEvento(id: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + `getEvento/${id}`, {
      headers: this.headers,
    });
  }

  getEventoCsv(id: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + `getEventoCsv/${id}`, {
      headers: this.headers,
    });
  }

  getEventoServicio(id: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + `getEventoServicio/${id}`, {
      headers: this.headers,
    });
  }

  agregarDatosEvento(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'adddataevent/', datos, {
      headers: this.headers,
    });
  }

  editarDatosEvento(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'editdataevent/', datos, {
      headers: this.headers,
    });
  }

  editarDatosEventoServicio(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'editdataeventservicio/', datos, {
      headers: this.headers,
    });
  }

  eliminarDatosEvento(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'deletedataevent/', datos, {
      headers: this.headers,
    });
  }

  eliminarDatosEventoServicio(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'deletedataeventservicio/', datos, {
      headers: this.headers,
    });
  }

  eliminarTodoUnMesEventos(mes: any, anio: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'eliminartodounmes/',
      { mes, anio },
      { headers: this.headers }
    );
  }

  // COMPARAR EVENTO

  compararEventos(anio: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'comparareventoscomparar/' + anio, {
      headers: this.headers,
    });
  }

  getEventosComprobar(anio: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'comparareventosextraer/' + anio, {
      headers: this.headers,
    });
  }

  eliminarEventoComprobar(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'comparareventoseliminar/', datos, {
      headers: this.headers,
    });
  }

  editarCompEnEventoComprobar(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'comparareventoseditarcomp/', datos, {
      headers: this.headers,
    });
  }

  editarEventoCsvEnEvento(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'comparareventoseditarevento/', datos, {
      headers: this.headers,
    });
  }

  insertarEventoCsvEnEvento(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'comparareventosinsertarevento/', datos, {
      headers: this.headers,
    });
  }

  // ACTIVIDAD

  getActividades(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'actividades/', { headers: this.headers });
  }

  // AEROPUERTO

  getAeropuertos(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'aeropuertos/', { headers: this.headers });
  }

  setAeropuerto(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'guardarAeropuerto/', datos, {
      headers: this.headers,
    });
  }

  // AVION

  getAviones(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'aviones/', { headers: this.headers });
  }

  // NOMINA

  setNomina(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'setNomina/', datos, {
      headers: this.headers,
    });

    // if (datos.flota == "B787") {
    //   return this._http.post(this.url787 + 'setNomina/', datos, { 'headers': this.headers });
    // } else if (datos.flota == "B737") {
    //   return this._http.post(this.url + 'setNomina/', datos, { 'headers': this.headers });
    // }
  }

  eliminarNomina(id_nomina: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'eliminarNomina/',
      { id_nomina },
      { headers: this.headers }
    );
  }

  getNominas(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'nominas/', { headers: this.headers });
  }

  getNomina(anioHoy: any, mesHoy: any): Observable<any> {
    let datos = {
      anioHoy,
      mesHoy,
    };
    this.actualizarHeader();
    return this._http.post(this.url + 'nomina/', datos, {
      headers: this.headers,
    });
  }

  calcularPrecioVacaciones(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'calcularPrecioVacaciones/', datos, {
      headers: this.headers,
    });
  }

  // PERFIL VUELO

  getPerfilesVuelo(flota: any): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'perfiles/' + flota, {
      headers: this.headers,
    });
  }

  getPerfilesVueloAdmin(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'perfilesAdmin/', {
      headers: this.headers,
    });
  }

  setPerfilVuelo(perfil: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'addPerfil/', perfil, {
      headers: this.headers,
    });
  }

  // INFO-ANUAL

  getDatosAnuales(anio: number): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'info-anual/' + anio, {
      headers: this.headers,
    });
  }

  // CALENDARIO NÓMINAS

  getCalendario(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'nominas/calendario', {
      headers: this.headers,
    });
  }

  // DIASLIBRESPERDIDOS

  getDiasLibresPerdidos(anio: number, mes: number): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'diaslibresperdidos/' + anio + '/' + mes, {
      headers: this.headers,
    });
  }

  eliminarDiaLibrePerdido(id: number): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'eliminardialibreperdido/' + id, {
      headers: this.headers,
    });
  }

  // UTILIDAD CASILLERO

  setUserCasillero(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'user_casillero/',
      {
        usuarioCasillero: datos.usuarioCasillero,
        passwordCasillero: datos.passwordCasillero,
      },
      { headers: this.headers }
    );
  }

  getUserCasillero(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'user_casillero/', {
      headers: this.headers,
    });
  }

  setEventoHttp(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'eventohttp/',
      { mes: datos.mes, anio: datos.anio },
      { headers: this.headers }
    );
  }

  // INFO-NOMINA

  getNActividad(nominaId: number): Observable<NActividadResponse> {
    this.actualizarHeader();
    return this._http.get<NActividadResponse>(
      this.url + `nactividad/${nominaId}`,
      { headers: this.headers }
    );
  }

  getNActividadPorAnio(anio: number): Observable<NActividadResponse> {
    this.actualizarHeader();
    return this._http.get<NActividadResponse>(
      this.url + `nactividad/anio/${anio}`,
      { headers: this.headers }
    );
  }

  // DESTACAMENTO

  getEventosDestacamento(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'getEventosDestacamento', {
      headers: this.headers,
    });
  }

  getEventosPosiblesDestacamentos(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'getEventosPosiblesDestacamentos', {
      headers: this.headers,
    });
  }

  setEventosDestacamento(destacamento: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'setEventosDestacamento', destacamento, {
      headers: this.headers,
    });
  }

  editEventosDestacamento(destacamento: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'editEventosDestacamento', destacamento, {
      headers: this.headers,
    });
  }

  deleteEventosDestacamento(uuid: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(
      this.url + 'deleteEventosDestacamento',
      { uuid },
      { headers: this.headers }
    );
  }

  // Limites perdida licencia y jubilación MAX

  getPerdidaLicenciaJubilacionMax(datos: any): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'perdidaLicenciaJubilacionMax/', datos, {
      headers: this.headers,
    });
  }
}
