import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);

// MODULES
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngb-modal';


// PROVIDERS
import { DashboardService } from './providers/dashboard.service';
import { AuthService } from './providers/auth.service';
import { ApiService } from './providers/api.service';
import { StorageService } from './providers/storage.service';
import { SwalService } from './providers/swal.service';

// COMPONENTS
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RegisterComponent } from './components/auth/register/register.component';
import { AddEventosComponent } from './components/dashboard/add-eventos/add-eventos.component';
import { CalendarioComponent } from './components/dashboard/calendario/calendario.component';
import { CalendarioListComponent } from './components/dashboard/calendario/calendario-list.component';
import { PerfilComponent } from './components/dashboard/perfil/perfil.component';
import { ConfiguracionComponent } from './components/dashboard/perfil/configuracion.component';
import { ForgotComponent } from './components/auth/forgot/forgot.component';
import { ResetpassComponent } from './components/auth/resetpass/resetpass.component';
import { ActividadComponent } from './components/dashboard/perfil/actividad.component';
import { AvionesComponent } from './components/dashboard/listas/aviones.component';
import { AeropuertosComponent } from './components/dashboard/listas/aeropuertos.component';
import { NominaComponent } from './components/dashboard/nomina/nomina.component';
import { ListaNominasComponent } from './components/dashboard/lista-nominas/lista-nominas.component';
import { AdministradorComponent } from './components/dashboard/perfil/administrador.component';
import { InfoAnualComponent } from './components/dashboard/info-anual/info-anual.component';
import { ListaPerfilesComponent } from './components/dashboard/listas/perfiles.component';
import { CompararEventosComponent } from './components/dashboard/comparar-eventos/comparar-eventos.component';
import { DiasLibresComponent } from './components/dashboard/info-anual/dias-libres.component';
import { HorasVueloComponent } from './components/dashboard/info-anual/horas-vuelo.component';
import { DiasVaComponent } from './components/dashboard/info-anual/dias-va.component';
import { NavComponent } from './components/dashboard/layout/nav/nav.component';
import { SideComponent } from './components/dashboard/layout/side/side.component';
import { FooterComponent } from './components/dashboard/layout/footer/footer.component';
import { CalendarioVueloComponent } from './components/dashboard/calendario/calendario-vuelo.component';
import { InfoNominaComponent } from './components/dashboard/info-nomina/info-nomina.component';
import { ListaDestacamentoComponent } from './components/dashboard/lista-destacamento/lista-destacamento.component';

// PIPES
import { FilterUserPipe } from './pipes/filter-user.pipe';
import { FilterAnioPipe } from './pipes/filter-anio.pipe';
import { FilterMesPipe } from './pipes/filter-mes.pipe';
import { FilterPerfilesPipe } from './pipes/filter-perfiles.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    RegisterComponent,
    AddEventosComponent,
    CalendarioComponent,
    CalendarioVueloComponent,
    CalendarioListComponent,
    PerfilComponent,
    FilterPerfilesPipe,
    ConfiguracionComponent,
    ListaDestacamentoComponent,
    ForgotComponent,
    ResetpassComponent,
    ActividadComponent,
    AvionesComponent,
    AeropuertosComponent,
    NominaComponent,
    ListaNominasComponent,
    AdministradorComponent,
    FilterUserPipe,
    InfoAnualComponent,
    ListaPerfilesComponent,
    CompararEventosComponent,
    DiasLibresComponent,
    HorasVueloComponent,
    DiasVaComponent,
    NavComponent,
    SideComponent,
    FooterComponent,
    FilterAnioPipe,
    FilterMesPipe,
    InfoNominaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    FontAwesomeModule,
    ModalModule
  ],
  providers: [
    ApiService,
    DashboardService,
    StorageService,
    AuthService,
    SwalService,
    { provide: LOCALE_ID, useValue: 'es-ES' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
