<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <div>
                        <i class="bi bi-play-fill iconoLegend"></i> Actividad
                    </div>
                </legend>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card shadow mb-4">
                            <div class="card-body row align-items-center justify-content-center text-center">
                                <div class="col-md-6 col-sm-12">
                                    <span *ngIf="!mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar todo</button></span>
                                    <span *ngIf="mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar menos</button></span>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <button type="button" class="btn botonSeplaAct" (click)="borrarTodo()">BORRAR TODOS
                                        LOS
                                        DATOS DEL SISTEMA</button>
                                </div>
                                <div class="col-12" *ngIf="!mostrarTodo">
                                    <div class="row m-2" *ngFor="let elemento of lista | paginate: {itemsPerPage: 6, currentPage: pageActual}">
                                        <div class="col-md-4 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            {{elemento.creado | date:'dd/MM/yy'}} - {{elemento.creado | date:'HH:mm'}}
                                        </div>
                                        <div class="col-md-8 col-sm-12 fondoUno list-group-item list-group-item-secondary">
                                            {{elemento.datos}}
                                        </div>
                                    </div>
                                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" *ngIf="lista !== undefined && !mostrarTodo" class="text-left" (pageChange)="pageActual = $event"></pagination-controls>
                                </div>
                                <div class="col-12" *ngIf="mostrarTodo">
                                    <div class="row m-2" *ngFor="let elemento of lista">
                                        <div class="col-md-4 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            {{elemento.creado | date:'dd/MM/yy'}} - {{elemento.creado | date:'HH:mm'}}
                                        </div>
                                        <div class="col-md-8 col-sm-12 fondoUno list-group-item list-group-item-secondary">
                                            {{elemento.datos}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>