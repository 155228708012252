import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../providers/api.service';
import { Subscription } from 'rxjs';
import { DashboardService } from 'src/app/providers/dashboard.service';

@Component({
  selector: 'app-dias-libres',
  templateUrl: './dias-libres.component.html',
  styleUrls: ['../dashboard.component.scss', './info-anual.component.scss']
})
export class DiasLibresComponent implements OnInit, OnDestroy {

  keysTabla: any[] = ['Código', 'Fecha inicio', 'Fecha final'];
  eventos = [];
  funEventos: Subscription;
  constructor(private _rutaActiva: ActivatedRoute, private _api: ApiService, private _dashboard: DashboardService) { }

  ngOnDestroy() {
    this.funEventos.unsubscribe();
  }

  ngOnInit(): void {
    let anio = this._rutaActiva.snapshot.params.anio
    this.funEventos = this._api.getEventosAnio(anio).subscribe({
      next: (result) => {
        if (result.status) {
          this.eventos = result.eventos.L;
          this.eventos = this._dashboard.ordenarFechasEventos(this.eventos);
        }
      },
      error: (error) => {
      }
    });
  }

}
