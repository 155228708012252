import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { ApiService } from 'src/app/providers/api.service';
import { SwalService } from 'src/app/providers/swal.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-nomina',
  templateUrl: './nomina.component.html',
  styleUrls: ['../dashboard.component.scss', './nomina.component.scss']
})
export class NominaComponent implements OnInit {

  infoDiasPerdidos = [];
  nominaId: number | undefined;
  maximos_licencia_jubilacion = { perdida_licencia: '0', jubilacion: '0' };

  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    private _swal: SwalService,
    private _router: Router) { }

  ngOnInit(): void {
    let anio_hoy = this._route.snapshot.paramMap.get('anio_hoy');
    let mes_hoy = this._route.snapshot.paramMap.get('mes_hoy');
    this._api.getNomina(anio_hoy, mes_hoy).subscribe({
      next: (result) => {
        if (result.status) {
          this.nominaId = result.nomina.id;
          this.cargarDatosNomina(result.nomina);
          this.cargarDiasLibres(anio_hoy, mes_hoy);
          this.cargarDatosPerdidaLicenciaJubilacionMax();
        }
      },
      error: (error) => { }
    });
  }

  cargarDiasLibres(anio: any, mes: any) {
    this._api.getDiasLibresPerdidos(anio, mes).subscribe({
      next: (result) => {
        if (result.status) {
          this.infoDiasPerdidos = result.dias_perdidos_mes;
        }
      },
      error: (error) => { }
    });
  }

  eliminarDiaLibre(id: number) {
    this.lanzarAlertaCuidado("El día libre será borrado de la base de datos, recuerda eliminar y volver a cargar la nómina", id);
  }

  cargarDatosNomina(datosNomina: any) {
    this.datosNomina = {
      ...datosNomina.nBaseCotizacion,
      ...datosNomina.nDatos,
      ...datosNomina.nDevengo,
      ...datosNomina.nPension,
      ...datosNomina.nRetribucion,
      ...datosNomina.nTotal,
      ...datosNomina.nTotales,
      ...datosNomina.nVariables1,
      ...datosNomina.nVariables2,
    }

    // SUMAR PRECIO DIETAS
    this.datosNomina.dnc_imp_retencion = (Number(this.datosNomina.dnc_imp_retencion) * Number(this.datosNomina.dnc_unidades)).toString();
    this.datosNomina.dnp_imp_retencion = (Number(this.datosNomina.dnp_imp_retencion) * Number(this.datosNomina.dnp_unidades)).toString();
    this.datosNomina.dnt_imp_retencion = (Number(this.datosNomina.dnt_imp_retencion) * Number(this.datosNomina.dnt_unidades)).toString();
    this.datosNomina.dic_imp_retencion = (Number(this.datosNomina.dic_imp_retencion) * Number(this.datosNomina.dic_unidades)).toString();
    this.datosNomina.dip_imp_retencion = (Number(this.datosNomina.dip_imp_retencion) * Number(this.datosNomina.dip_unidades)).toString();
    this.datosNomina.dit_imp_retencion = (Number(this.datosNomina.dit_imp_retencion) * Number(this.datosNomina.dit_unidades)).toString();
    this.datosNomina.dimc_imp_retencion = (Number(this.datosNomina.dimc_imp_retencion) * Number(this.datosNomina.dimc_unidades)).toString();
    this.datosNomina.dimp_imp_retencion = (Number(this.datosNomina.dimp_imp_retencion) * Number(this.datosNomina.dimp_unidades)).toString();
    this.datosNomina.dlc_imp_retencion = (Number(this.datosNomina.dlc_imp_retencion) * Number(this.datosNomina.dlc_unidades)).toString();
    this.datosNomina.dlp_imp_retencion = (Number(this.datosNomina.dlp_imp_retencion) * Number(this.datosNomina.dlp_unidades)).toString();
    this.datosNomina.dlt_imp_retencion = (Number(this.datosNomina.dlt_imp_retencion) * Number(this.datosNomina.dlt_unidades)).toString();
    this.datosNomina.dlc2_imp_retencion = (Number(this.datosNomina.dlc2_imp_retencion) * Number(this.datosNomina.dlc2_unidades)).toString();
    this.datosNomina.dlt2_imp_retencion = (Number(this.datosNomina.dlt2_imp_retencion) * Number(this.datosNomina.dlt2_unidades)).toString();
    this.datosNomina.dlmc_imp_retencion = (Number(this.datosNomina.dlmc_imp_retencion) * Number(this.datosNomina.dlmc_unidades)).toString();
    this.datosNomina.dlmp_imp_retencion = (Number(this.datosNomina.dlmp_imp_retencion) * Number(this.datosNomina.dlmp_unidades)).toString();

    // DESTACAMENTO
    this.datosNomina.dnc_dp_imp_retencion = (Number(this.datosNomina.dnc_dp_imp_retencion) * Number(this.datosNomina.dnc_dp_unidades)).toString();
    this.datosNomina.dnc_dp2_imp_retencion = (Number(this.datosNomina.dnc_dp2_imp_retencion) * Number(this.datosNomina.dnc_dp2_unidades)).toString();
    this.datosNomina.dnc_dc_imp_retencion = (Number(this.datosNomina.dnc_dc_imp_retencion) * Number(this.datosNomina.dnc_dc_unidades)).toString();
    this.datosNomina.dnc_dc2_imp_retencion = (Number(this.datosNomina.dnc_dc2_imp_retencion) * Number(this.datosNomina.dnc_dc2_unidades)).toString();
    this.datosNomina.dic_dp_imp_retencion = (Number(this.datosNomina.dic_dp_imp_retencion) * Number(this.datosNomina.dic_dp_unidades)).toString();
    this.datosNomina.dic_dp2_imp_retencion = (Number(this.datosNomina.dic_dp2_imp_retencion) * Number(this.datosNomina.dic_dp2_unidades)).toString();
    this.datosNomina.dic_dc_imp_retencion = (Number(this.datosNomina.dic_dc_imp_retencion) * Number(this.datosNomina.dic_dc_unidades)).toString();
    this.datosNomina.dic_dc2_imp_retencion = (Number(this.datosNomina.dic_dc2_imp_retencion) * Number(this.datosNomina.dic_dc2_unidades)).toString();
    this.datosNomina.dlc_dp_imp_retencion = (Number(this.datosNomina.dlc_dp_imp_retencion) * Number(this.datosNomina.dlc_dp_unidades)).toString();
    this.datosNomina.dlc_dp2_imp_retencion = (Number(this.datosNomina.dlc_dp2_imp_retencion) * Number(this.datosNomina.dlc_dp2_unidades)).toString();
    this.datosNomina.dlc_dc_imp_retencion = (Number(this.datosNomina.dlc_dc_imp_retencion) * Number(this.datosNomina.dlc_dc_unidades)).toString();
    this.datosNomina.dlc_dc2_imp_retencion = (Number(this.datosNomina.dlc_dc2_imp_retencion) * Number(this.datosNomina.dlc_dc2_unidades)).toString();

    this.datosNomina.dnc_imp_exentas = (Number(this.datosNomina.dnc_imp_exentas) * Number(this.datosNomina.dnc_unidades)).toString();
    this.datosNomina.dnp_imp_exentas = (Number(this.datosNomina.dnp_imp_exentas) * Number(this.datosNomina.dnp_unidades)).toString();
    this.datosNomina.dnt_imp_exentas = (Number(this.datosNomina.dnt_imp_exentas) * Number(this.datosNomina.dnt_unidades)).toString();
    this.datosNomina.dic_imp_exentas = (Number(this.datosNomina.dic_imp_exentas) * Number(this.datosNomina.dic_unidades)).toString();
    this.datosNomina.dip_imp_exentas = (Number(this.datosNomina.dip_imp_exentas) * Number(this.datosNomina.dip_unidades)).toString();
    this.datosNomina.dit_imp_exentas = (Number(this.datosNomina.dit_imp_exentas) * Number(this.datosNomina.dit_unidades)).toString();
    this.datosNomina.dimc_imp_exentas = (Number(this.datosNomina.dimc_imp_exentas) * Number(this.datosNomina.dimc_unidades)).toString();
    this.datosNomina.dimp_imp_exentas = (Number(this.datosNomina.dimp_imp_exentas) * Number(this.datosNomina.dimp_unidades)).toString();
    this.datosNomina.dlc_imp_exentas = (Number(this.datosNomina.dlc_imp_exentas) * Number(this.datosNomina.dlc_unidades)).toString();
    this.datosNomina.dlp_imp_exentas = (Number(this.datosNomina.dlp_imp_exentas) * Number(this.datosNomina.dlp_unidades)).toString();
    this.datosNomina.dlt_imp_exentas = (Number(this.datosNomina.dlt_imp_exentas) * Number(this.datosNomina.dlt_unidades)).toString();
    this.datosNomina.dlc2_imp_exentas = (Number(this.datosNomina.dlc2_imp_exentas) * Number(this.datosNomina.dlc2_unidades)).toString();
    this.datosNomina.dlt2_imp_exentas = (Number(this.datosNomina.dlt2_imp_exentas) * Number(this.datosNomina.dlt2_unidades)).toString();
    this.datosNomina.dlmc_imp_exentas = (Number(this.datosNomina.dlmc_imp_exentas) * Number(this.datosNomina.dlmc_unidades)).toString();
    this.datosNomina.dlmp_imp_exentas = (Number(this.datosNomina.dlmp_imp_exentas) * Number(this.datosNomina.dlmp_unidades)).toString();

    // DESTACAMENTO
    this.datosNomina.dnc_dp_imp_exentas = (Number(this.datosNomina.dnc_dp_imp_exentas) * Number(this.datosNomina.dnc_dp_unidades)).toString();
    this.datosNomina.dnc_dp2_imp_exentas = (Number(this.datosNomina.dnc_dp2_imp_exentas) * Number(this.datosNomina.dnc_dp2_unidades)).toString();
    this.datosNomina.dnc_dc_imp_exentas = (Number(this.datosNomina.dnc_dc_imp_exentas) * Number(this.datosNomina.dnc_dc_unidades)).toString();
    this.datosNomina.dnc_dc2_imp_exentas = (Number(this.datosNomina.dnc_dc2_imp_exentas) * Number(this.datosNomina.dnc_dc2_unidades)).toString();
    this.datosNomina.dic_dp_imp_exentas = (Number(this.datosNomina.dic_dp_imp_exentas) * Number(this.datosNomina.dic_dp_unidades)).toString();
    this.datosNomina.dic_dp2_imp_exentas = (Number(this.datosNomina.dic_dp2_imp_exentas) * Number(this.datosNomina.dic_dp2_unidades)).toString();
    this.datosNomina.dic_dc_imp_exentas = (Number(this.datosNomina.dic_dc_imp_exentas) * Number(this.datosNomina.dic_dc_unidades)).toString();
    this.datosNomina.dic_dc2_imp_exentas = (Number(this.datosNomina.dic_dc2_imp_exentas) * Number(this.datosNomina.dic_dc2_unidades)).toString();
    this.datosNomina.dlc_dp_imp_exentas = (Number(this.datosNomina.dlc_dp_imp_exentas) * Number(this.datosNomina.dlc_dp_unidades)).toString();
    this.datosNomina.dlc_dp2_imp_exentas = (Number(this.datosNomina.dlc_dp2_imp_exentas) * Number(this.datosNomina.dlc_dp2_unidades)).toString();
    this.datosNomina.dlc_dc_imp_exentas = (Number(this.datosNomina.dlc_dc_imp_exentas) * Number(this.datosNomina.dlc_dc_unidades)).toString();
    this.datosNomina.dlc_dc2_imp_exentas = (Number(this.datosNomina.dlc_dc2_imp_exentas) * Number(this.datosNomina.dlc_dc2_unidades)).toString();
  }

  descargarPDF() {
    const options = {
      margin: 1,
      filename: `${new Date().toISOString()}_nomina.pdf`,
      html2canvas: {
        scale: 4,
        dpi: 192,
        letterRendering: true,
        allowTaint: true,
        useCORS: true,
        logging: false,
        scrollX: 0,
        scrollY: 0
      },
      jsPDF: {
        orientation: 'portrait',
        unit: 'cm',
        format: 'a4'
      }
    }

    const element: Element = document.getElementById('nominaPDF');
    html2pdf()
      .set(options)
      .from(element)
      .save().finally(() => this.pdfFinal());
  }

  pdfFinal() {
  }

  datosNomina = {
    id_nomina: '',
    usuarioId: '',

    //BLOQUE A
    nombre: '',
    apellido1: '',
    apellido2: '',
    base: '',
    nivel: '',
    categoria: '',
    flota: '',
    mes_hoy: '',
    anio_hoy: '',
    reduccion_jornada: '',

    //BLOQUE B
    salario_base_unidades: '',
    salario_base_devengos: '',
    prima_viaje_unidades: '',
    prima_viaje_devengos: '',
    plus_transporte_unidades: '',
    plus_transporte_devengos: '',
    prima_responsabilidad_boolean: 'No',
    prima_responsabilidad_unidades: '',
    prima_responsabilidad_devengos: '',
    plus_asistencia_tecnica_unidades: '',
    plus_asistencia_tecnica_devengos: '',
    plan_jubilacion_unidades: '',
    plan_jubilacion_devengos: '',
    perdida_licencia_devengos: '',
    paga_extra: '',
    anticipo_dietas: '',
    dias_debidos_unidades: '',
    dias_debidos_devengos: '',
    bajas_unidades: '',
    bajas_devengos: '',
    vacaciones_unidades: '',
    vacaciones_devengos: '',
    comEconomicoAnexoIV: '',

    //BLOQUE C
    seguro_medico_boolean: 'No',
    seguro_medico: '',
    miembros_seguro_medico: '',
    valor_billetes_free: '',
    comida_crew: '',
    globalia_flex_boolean: 'No',
    globalia_flex: '',

    //BLOQUE D
    plan_pensiones_boolean: 'No',
    plan_pensiones: '',

    //BLOQUE E
    posicion_situacion_unidades: '',
    posicion_situacion_importe: '',
    plus_nocturnidad_unidades: '',
    plus_nocturnidad_importe: '',
    plus_vuelo1_unidades: '',
    plus_vuelo1_importe: '',
    plus_vuelo2_unidades: '',
    plus_vuelo2_importe: '',
    plus_imaginaria_unidades: '',
    plus_imaginaria_importe: '',
    actividad_extraordinaria_unidades: '',
    actividad_extraordinaria_importe: '',
    actividad_fuera_minimos_unidades: '',
    actividad_fuera_minimos_importe: '',
    actividad_mas_imaginaria: '',

    //BLOQUE F
    dnc_unidades: '',
    dnc_imp_retencion: '',
    dnc_imp_exentas: '',
    dnp_unidades: '',
    dnp_imp_retencion: '',
    dnp_imp_exentas: '',
    dnt_unidades: '',
    dnt_imp_retencion: '',
    dnt_imp_exentas: '',
    dic_unidades: '',
    dic_imp_retencion: '',
    dic_imp_exentas: '',
    dit_unidades: '',
    dit_imp_retencion: '',
    dit_imp_exentas: '',
    dip_unidades: '',
    dip_imp_retencion: '',
    dip_imp_exentas: '',
    dimc_unidades: '',
    dimc_imp_retencion: '',
    dimc_imp_exentas: '',
    dimp_unidades: '',
    dimp_imp_retencion: '',
    dimp_imp_exentas: '',
    dlc_unidades: '',
    dlc_imp_retencion: '',
    dlc_imp_exentas: '',
    dlp_unidades: '',
    dlp_imp_retencion: '',
    dlp_imp_exentas: '',
    dlt_unidades: '',
    dlt_imp_retencion: '',
    dlt_imp_exentas: '',
    dlc2_unidades: '',
    dlc2_imp_retencion: '',
    dlc2_imp_exentas: '',
    dlt2_unidades: '',
    dlt2_imp_retencion: '',
    dlt2_imp_exentas: '',
    dlmc_unidades: '',
    dlmc_imp_retencion: '',
    dlmc_imp_exentas: '',
    dlmp_unidades: '',
    dlmp_imp_retencion: '',
    dlmp_imp_exentas: '',
    dsy_unidades: '',
    dsy_imp_retencion: '',
    dsy_imp_exentas: '',
    dnc_dp_unidades: '',
    dnc_dp_imp_retencion: '',
    dnc_dp_imp_exentas: '',
    dnc_dp2_unidades: '',
    dnc_dp2_imp_retencion: '',
    dnc_dp2_imp_exentas: '',
    dnc_dc_unidades: '',
    dnc_dc_imp_retencion: '',
    dnc_dc_imp_exentas: '',
    dnc_dc2_unidades: '',
    dnc_dc2_imp_retencion: '',
    dnc_dc2_imp_exentas: '',
    dic_dp_unidades: '',
    dic_dp_imp_retencion: '',
    dic_dp_imp_exentas: '',
    dic_dp2_unidades: '',
    dic_dp2_imp_retencion: '',
    dic_dp2_imp_exentas: '',
    dic_dc_unidades: '',
    dic_dc_imp_retencion: '',
    dic_dc_imp_exentas: '',
    dic_dc2_unidades: '',
    dic_dc2_imp_retencion: '',
    dic_dc2_imp_exentas: '',
    dlc_dp_unidades: '',
    dlc_dp_imp_retencion: '',
    dlc_dp_imp_exentas: '',
    dlc_dp2_unidades: '',
    dlc_dp2_imp_retencion: '',
    dlc_dp2_imp_exentas: '',
    dlc_dc_unidades: '',
    dlc_dc_imp_retencion: '',
    dlc_dc_imp_exentas: '',
    dlc_dc2_unidades: '',
    dlc_dc2_imp_retencion: '',
    dlc_dc2_imp_exentas: '',


    totales_unidades: '',
    totales_imp_retencion: '',
    totales_imp_exentas: '',

    //BLOQUE G
    total_devengos: '',
    total_deducciones: '',

    //BLOQUE H
    remuner_total_seg_social: '',
    base_it_seg_social: '',
    pr_pagas_extra_seg_social: '',
    total_seg_social: '',

    //BLOQUE I
    contingencias_comunes_bases: '',
    contingencias_comunes_porciento: '',
    contingencias_comunes_importe: '',
    desempleo_trabajador_bases: '',
    desempleo_trabajador_porciento: '',
    desempleo_trabajador_importe: '',
    formacion_prof_trabaj_bases: '',
    formacion_prof_porciento: '',
    formacion_prof_trabaj_importe: '',
    irpf_bases: '',
    irpf_porciento: '',
    irpf_importe: '',
    final_total_seg_social_importe: '',
    final_total_retenciones_importe: '',
    final_liquido_percibir_importe: '',
  }

  public lanzarAlertaCuidado(contenido: string, id) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Estás seguro/a?",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(225, 175, 25)",
      toast: true,
      timerProgressBar: true
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this._api.eliminarDiaLibrePerdido(id).subscribe({
          next: (result) => {
            if (result.status) {
              this._swal.launchCorrectSwal("Elimina la nómina y vuélvala a crear", () => {
                this._router.navigate(['/sepla/nominas']);
              });
            }
          },
          error: (error) => {
          }
        });
      } else if (result.isDenied) {
      }
    })
  }

  verDesglose(): void {
    if (this.nominaId)
      this._router.navigate(['sepla/info-nomina', this._route.snapshot.paramMap.get('anio_hoy'), this._route.snapshot.paramMap.get('mes_hoy'), this.nominaId]);
  }

  cargarDatosPerdidaLicenciaJubilacionMax() {
    let datos = { anio: this.datosNomina.anio_hoy, mes: this.datosNomina.mes_hoy, flota: this.datosNomina.flota, categoria: this.datosNomina.categoria, nivel: this.datosNomina.nivel }
    this._api.getPerdidaLicenciaJubilacionMax(datos).subscribe({
      next: (result) => {
        if (result.status) {
          this.maximos_licencia_jubilacion = result.resultado
        }
      },
      error: (error) => { }
    });
  }
}
