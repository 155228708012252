import { Component, OnInit, ViewChild } from '@angular/core';

// Full Calendar
import interactionGridPlugin from "@fullcalendar/interaction";
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { ApiService } from 'src/app/providers/api.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { createPopper } from '@popperjs/core';
import { ModalManager } from 'ngb-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalService } from 'src/app/providers/swal.service';
import { ActivatedRoute } from '@angular/router';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['../dashboard.component.scss', './calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  calElement: any;
  tooltip: any;
  events = [];
  eventosOriginal = [];
  eventosOriginalRealizados = [];
  eventsFull = [];
  options: any;
  calendar: Calendar;
  textoPopper: any = '';
  fechaSelecCrear: any;
  @ViewChild('modalEvento') eventoModal: any;
  @ViewChild('modalCrearEvento') crearEventoModal: any;
  private modalE: any;
  private modalC: any;
  eventoForm: FormGroup;
  private seleDate: string = "";

  constructor(
    private _formB: FormBuilder,
    private _modal: ModalManager,
    private _api: ApiService,
    public _dashboard: DashboardService,
    private _swal: SwalService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
    this._api.getEventos().subscribe({
      next: (programado) => {
        if (programado.status) {
          this._api.getTodosEventosServicio().subscribe({
            next: (realizado) => {
              this.cargarEventos(programado.eventos);
              this.eventosOriginal = programado.eventos;
              if (realizado.status) {
                this.eventosOriginalRealizados = realizado.eventos;
              }
              this.seleDate = this._route.snapshot.params.date;
              this.crearCalendario();
            },
            error: (error) => {
            }
          });
        }
      },
      error: (error) => {
      }
    });
  }

  cargarEventos(eventos: any) {
    try {
      this.events = [];
      for (const evento of eventos) {
        let objeto = this.transformarEvento(evento);
        this.events.push(objeto);
      };
    } catch (error) {
      console.log(error);
    }
    this.eventsFull = this.events;
  }

  transformarEvento(evento: any) {
    let start = this._dashboard.transformar_fecha(evento.fecha_inicio, evento.hora_inicio);
    let end = this._dashboard.transformar_fecha(evento.fecha_final, evento.hora_final);
    let title = '';
    let mensaje = `${evento.hora_inicio}-${evento.hora_final}`;
    if (evento.codigo == 'CO') {
      title = ` ${evento.alerta == 'true' ? '🚩' : ''}${evento.trayecto_inicio}-${evento.trayecto_final} ${mensaje}`;
    } else if (evento.codigo == 'VS') {
      title = ` ${evento.alerta == 'true' ? '🚩' : ''}${evento.codigo} ${evento.trayecto_inicio}-${evento.trayecto_final} ${mensaje}`;
    } else {
      if (evento.extra == 'LD') {
        title = `${evento.extra}-${evento.codigo}`;
      } else if (evento.codigo == 'IM') {
        title = `${evento.codigo} ${mensaje}`;
      } else {
        title = `${evento.codigo}`;
      }
    }
    let objeto = {
      title,
      start,
      end,
      color: evento.color,
      description: title,
      textColor: '#fff',
      allDay: false,
      tipo: evento.codigo,
      id_evento: evento.id,
      vuelo: evento.vuelo
    };
    return objeto;
  }

  crearFormulario(evento?: any) {
    if (evento) {
      let realizado = this.eventosOriginalRealizados.find(x => x.id == evento.relacion);
      let fInicioR = realizado != undefined ? realizado.fecha_inicio.split('/') : this.fechaSelecCrear;
      let fFinalR = realizado != undefined ? realizado.fecha_final.split('/') : this.fechaSelecCrear;
      let fInicio = evento != undefined ? evento.fecha_inicio.split('/') : this.fechaSelecCrear;
      let fFinal = evento != undefined ? evento.fecha_final.split('/') : this.fechaSelecCrear;
      this.eventoForm = this._formB.group({
        id: [evento != undefined ? evento.id : ''],
        cod_fun: [evento != undefined ? evento.cod_fun : '', [Validators.maxLength(3)]],
        codigo: [evento != undefined ? evento.codigo : '', [Validators.required, Validators.maxLength(3)]],
        extra: [evento != undefined ? evento.extra : '', [Validators.maxLength(4)]],
        fecha_inicio: [evento != undefined ? `${fInicio[2]}-${fInicio[1]}-${fInicio[0]}` : this.fechaSelecCrear, [Validators.required]],
        fecha_final: [evento != undefined ? `${fFinal[2]}-${fFinal[1]}-${fFinal[0]}` : this.fechaSelecCrear, [Validators.required]],
        hora_final: [evento != undefined ? evento.hora_final : '', [Validators.required, Validators.maxLength(5)]],
        hora_inicio: [evento != undefined ? evento.hora_inicio : '', [Validators.required, Validators.maxLength(5)]],
        vuelo_fecha_inicio: [realizado != undefined ? `${fInicioR[2]}-${fInicioR[1]}-${fInicioR[0]}` : this.fechaSelecCrear, [Validators.required]],
        vuelo_fecha_final: [realizado != undefined ? `${fFinalR[2]}-${fFinalR[1]}-${fFinalR[0]}` : this.fechaSelecCrear, [Validators.required]],
        vuelo_hora_final: [realizado != undefined ? realizado.hora_final : '', [Validators.required, Validators.maxLength(5)]],
        vuelo_hora_inicio: [realizado != undefined ? realizado.hora_inicio : '', [Validators.required, Validators.maxLength(5)]],
        trayecto_final: [evento != undefined ? evento.trayecto_final : '', [Validators.required, Validators.maxLength(3)]],
        trayecto_inicio: [evento != undefined ? evento.trayecto_inicio : '', [Validators.required, Validators.maxLength(3)]],
        vuelo: [evento != undefined ? evento.vuelo : '', [Validators.maxLength(10)]],
        color: [evento != undefined ? evento.color : '',],
        relacion: [evento != undefined ? evento.relacion : '',],
        charter: [evento != undefined ? evento.charter : false,],
        alerta: [evento != undefined ? evento.alerta : false,],
        nacional_internacional: [evento != undefined ? evento.nacional_internacional : '',],
      });
    } else {
      this.eventoForm = this._formB.group({
        id: [''],
        cod_fun: ['', [Validators.maxLength(3)]],
        codigo: ['', [Validators.required, Validators.maxLength(3)]],
        extra: ['', [Validators.maxLength(4)]],
        fecha_inicio: [this.fechaSelecCrear, [Validators.required]],
        fecha_final: [this.fechaSelecCrear, [Validators.required]],
        hora_final: ['', [Validators.required, Validators.maxLength(5)]],
        hora_inicio: ['', [Validators.required, Validators.maxLength(5)]],
        vuelo_fecha_inicio: [this.fechaSelecCrear],
        vuelo_fecha_final: [this.fechaSelecCrear],
        vuelo_hora_final: ['', [Validators.maxLength(5)]],
        vuelo_hora_inicio: ['', [Validators.maxLength(5)]],
        trayecto_final: ['', [Validators.required, Validators.maxLength(3)]],
        trayecto_inicio: ['', [Validators.required, Validators.maxLength(3)]],
        vuelo: ['', [Validators.maxLength(10)]],
        color: [''],
        relacion: ['0'],
        charter: [false],
        alerta: [false],
        nacional_internacional: [''],
      });
    }

    this.eventoForm.controls.fecha_inicio.valueChanges.subscribe(() => {
      if (this.eventoForm.controls.fecha_inicio.value > this.eventoForm.controls.fecha_final.value) {
        this.eventoForm.controls.fecha_inicio.setValue(this.eventoForm.controls.fecha_final.value);
      }
    });
  }

  crearCalendario() {
    this.calElement = document.getElementById('calendar');
    this.tooltip = document.getElementById('tooltip');
    if (this.seleDate != "0") {
      const fecha = new Date(this.seleDate);
      this.montarCalendario(this.events, fecha);
    } else {
      this.montarCalendario(this.events);
    }
  }

  montarCalendario(eventos: any, fecha?: any) {
    let defaultDate;
    if (!fecha) {
      defaultDate = new Date();
    } else {
      defaultDate = fecha;
    }
    this.calendar = new Calendar(this.calElement, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, interactionGridPlugin],
      header: {
        left: 'today,prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      height: 800,
      selectable: true,
      // eventLimit: true,
      buttonText: {
        today: 'Hoy',
        month: 'Mes',
        week: 'Sem',
        day: 'Día'
      },
      showNonCurrentDates: false,
      defaultDate,
      navLinks: true,
      businessHours: true,
      editable: false,
      locale: 'es',
      firstDay: 1,
      displayEventTime: false,
      events: eventos,
      eventMouseEnter: (info) => {
        this.textoPopper = info.event.title;
        createPopper(info.el, this.tooltip, {
          placement: 'top'
        });
      },
      eventMouseLeave: (info) => {
        this.textoPopper = '';
      },
      dateClick: (date) => {
        const opciones = {
          size: 'ms',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true,
        };
        this.fechaSelecCrear = date.dateStr;
        this.modalC = this._modal.open(this.crearEventoModal, opciones);
        this.crearFormulario();
      },
      eventClick: (event) => {
        let id = event.event._def.extendedProps.id_evento;
        let programado = this.eventosOriginal.find(x => x.id == id);
        const opciones = {
          size: 'ms',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true
        };
        this.modalE = this._modal.open(this.eventoModal, opciones);
        this.crearFormulario(programado);
      },
      eventDrop: (calEvent) => { }
    });
    this.calendar.render();
  }

  seleccionarEventos(tipo: any) {
    this.events = this.eventsFull;
    let eventos = []
    if (tipo != 'TODOS') {
      eventos = [];
      for (let x = 0; x < this.events.length; x++) {
        const evento = this.events[x];
        if (tipo == 'LI-LN-LD') {
          if (evento.tipo == 'LI' || evento.tipo == 'LN' || evento.tipo == 'LD') {
            eventos.push(evento);
          }
        } else if (tipo == 'VUELOS') {
          if (evento.vuelo != '') {
            eventos.push(evento);
          }
        } else if (evento.tipo == tipo) {
          eventos.push(evento);
        }
      }
    } else {
      eventos = this.eventsFull;
    }
    let fecha = this.calendar.getDate();
    this.calendar.destroy();
    this.montarCalendario(eventos, fecha);
  }

  enviar() {
    this._api.editarDatosEvento(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento editado", () => {
            this._api.getEventos().subscribe({
              next: (programado) => {
                if (programado.status) {
                  this._api.getEventosServicio().subscribe({
                    next: (realizado) => {
                      if (realizado.status) {
                        this.cargarEventos(programado.eventos);
                        this.eventosOriginal = programado.eventos;
                        this.eventosOriginalRealizados = realizado.eventos;
                        this.seleDate = this._route.snapshot.params.date;
                        let fecha = this.calendar.getDate();
                        this.calendar.destroy();
                        this.montarCalendario(this.eventsFull, fecha);
                        this.modalE.close();
                      }
                    },
                    error: (error) => {
                    }
                  });
                }
              },
              error: (error) => {
              }
            });
          });
        } else {
          this._swal.launchErrorSwal(result.error, () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  crearEvento() {
    this._api.agregarDatosEvento(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento guardado", () => {
            let objeto = this.transformarEvento(result.eventoFinal);
            this.events.push(objeto);
            this.eventosOriginal.push(result.eventoFinal);
            this.eventosOriginalRealizados.push(result.eventoRealizadoFinal);
            this.eventsFull = this.events;
            let fecha = this.calendar.getDate();
            this.calendar.destroy();
            this.montarCalendario(this.eventsFull, fecha);
            this.modalC.close();
          });
        } else {
          this._swal.launchErrorSwal(result.error, () => { });
        }
      },
      error: (result) => {
      }
    });
  }

  insertarSA() {
    this.eventoForm.controls.cod_fun.setValue('');
    this.eventoForm.controls.codigo.setValue('SA');
    this.eventoForm.controls.extra.setValue('');
    this.eventoForm.controls.hora_final.setValue('23:59');
    this.eventoForm.controls.hora_inicio.setValue('00:00');
    this.eventoForm.controls.vuelo_hora_final.setValue('23:59');
    this.eventoForm.controls.vuelo_hora_inicio.setValue('00:00');
    this.eventoForm.controls.trayecto_final.setValue('MAD');
    this.eventoForm.controls.trayecto_inicio.setValue('MAD');
    this.eventoForm.controls.vuelo.setValue('');
    this.eventoForm.controls.color.setValue('#3232a0');
    this.eventoForm.controls.relacion.setValue(0);
    this.eventoForm.controls.charter.setValue('false');
    this.eventoForm.controls.alerta.setValue('true');
  }

  eliminarEvento() {
    this._api.eliminarDatosEvento(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento eliminado", () => {
            let indexOri = this.eventosOriginal.findIndex(x => x.id == result.buscarEvento.id);
            let indexEv = this.events.findIndex(x => x.id_evento == result.buscarEvento.id);
            if (indexOri != -1 && indexEv != -1) {
              this.events.splice(indexEv, 1);
              this.eventosOriginal.splice(indexOri, 1);
              let fecha = this.calendar.getDate();
              this.calendar.destroy();
              this.montarCalendario(this.eventsFull, fecha);
              this.modalE.close();
            }
          });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  eliminarMes() {
    let fecha = this.calendar.getDate();
    let mes = fecha.getMonth() + 1;
    let anio = fecha.getFullYear();

    this.lanzarAlertaCuidado(`BORRAR ${mes}/${anio}`, (result) => {
      if (result.isConfirmed) {
        this.lanzarAlertaMuchoCuidado(`VAS A BORRAR TODOS LOS DATOS DEL ${mes}/${anio}.`, (result) => {
          if (result.isConfirmed) {
            this._api.eliminarTodoUnMesEventos(mes, anio).subscribe({
              next: (result) => {
                if (result.status) {
                  this.lanzarAlertaExito(`Se han eliminado todos los datos del ${mes}/${anio}.`, () => {
                    location.reload();
                  })
                } else {
                  this.lanzarAlertaFracaso(`No se han podido eliminar los datos del ${mes}/${anio}.`);
                }
              },
              error: (error) => {
              }
            });
          } else {
            return;
          }
        });
      }
    })
  }

  // ALERTAS GENERALES

  public lanzarAlertaExito(contenido: string, f: () => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "success",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(25, 135, 85)",
      toast: true,
      timer: 1200
    }).then(f);
  }

  public lanzarAlertaFracaso(contenido: string) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      showConfirmButton: false,
      color: "rgb(255, 255, 255)",
      icon: "error",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      toast: true,
      timer: 1200
    });
  }

  public lanzarAlertaCuidado(contenido: string, f: (result: SweetAlertResult) => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Estás seguro/a?, se borrarán todos los datos",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(225, 175, 25)",
      timer: 7000,
      toast: true,
      timerProgressBar: true
    }).then(f);
  }

  public lanzarAlertaMuchoCuidado(contenido: string, f: (result: SweetAlertResult) => void) {
    Swal.fire({
      width: "25rem",
      titleText: contenido,
      text: "¿Confirmar borrado?, se perderán todos los datos y NO se podrán recuperar",
      color: "rgb(255, 255, 255)",
      confirmButtonText: "<i class='bi bi-check'></i>",
      confirmButtonColor: "rgb(25, 135, 85)",
      showConfirmButton: true,
      denyButtonText: "<i class='bi bi-x'></i>",
      denyButtonColor: "rgb(185, 50, 20)",
      showDenyButton: true,
      icon: "warning",
      background: "rgb(60, 60, 160)",
      iconColor: "rgb(185, 50, 20)",
      timer: 7000,
      toast: true,
      timerProgressBar: true
    }).then(f);
  }

  // Dinámico

  volver($e: any) {
    $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

}