import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConflictoEditar } from '../models/conflicto.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConflictosService {

  private url = environment.path;
  private auth = this._storage.loadTokenData();
  headers: any;

  constructor(
    private _http: HttpClient,
    private _storage: StorageService
  ) {
    this.actualizarHeader();
  }

  actualizarHeader() {
    this.auth = this._storage.loadTokenData();
    this.headers = { 'token': this.auth };
  }

  getAllConflictos(): Observable<any> {
    this.actualizarHeader();
    return this._http.get(this.url + 'buscar_conflicto', { 'headers': this.headers });
  }

  editarConflicto(ce: ConflictoEditar): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'editar_conflicto', ce, { 'headers': this.headers });
  }

  eliminarConflicto(ce: ConflictoEditar): Observable<any> {
    this.actualizarHeader();
    return this._http.post(this.url + 'eliminar_conflicto', ce, { 'headers': this.headers });
  }

}
