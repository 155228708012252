<div class="container-fluid">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">
            <fieldset>

                <legend>
                    Modificar Contraseña
                </legend>

                <form ngNativeValidate [formGroup]="passwordForm" (ngSubmit)="passwordReset();" novalidate="novalidate">

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="text" placeholder="Correo Electrónico"
                                formControlName="email">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="password" placeholder="Contraseña" formControlName="password"
                                name="password" id="password">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12">
                            <input class="formInput" type="password" placeholder="Repetir contraseña"
                                formControlName="pass2" name="pass2" id="pass2">
                        </div>
                    </div>

                    <div class="row formRow">
                        <div class="col-12 d-flex justify-content-between">
                            <a class="btnBack" [routerLink]="backRoute">Volver</a>
                            <button
                                [ngClass]="{'btnBack': validarFormulario(), 'btnEnviarDisabled':!validarFormulario()}"
                                type="submit" [disabled]="!validarFormulario()">Enviar</button>
                        </div>
                    </div>

                    <div class="msg-form text-center textoInfo rounded m-1" *ngIf="!validarFormulario()">
                        <p>Por favor, rellena todos los campos son obligatorios.</p>
                    </div>

                    <div class="msg-form text-center textoInfo rounded m-1" *ngIf="!passIgual">
                        <p>Las contraseñas no son iguales.</p>
                    </div>

                    <div class="msg-form text-center textoInfo rounded m-1" *ngIf="!passwordForm.controls.email.valid">
                        <p>No es un e-mail válido.</p>
                    </div>

                </form>

            </fieldset>
        </div>
    </div>

</div>