<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset class="fieldCard">
                <legend>
                    Información Nómina <span id="mes-anio">{{nominaId}} - {{mes}}/{{anio}}</span>
                </legend>
                <div *ngIf="loading" id="spinner-row" class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="spinner-border text-info"></div>
                    </div>
                </div>
                <div *ngIf="!loading" id="datos-row" class="row">
                    <div class="col-12">
                        <div *ngFor="let actividad of actividadesNomina" class="row activity-row">
                            <div class="col-2 fecha-inicio-col">
                                <p class="fecha-inicio-text">
                                    <i class="bi bi-arrow-right-short"></i> {{actividad.fechaInicio}}
                                </p>
                            </div>
                            <div class="col-10 activity-col">
                                <div class="row nActividad-row">
                                    <div class="col-12">
                                        <table class="table table-borderless nActividad-table">
                                            <tr>
                                                <th scope="col">ID</th>
                                                <th scope="col">Inicio</th>
                                                <th scope="col">Final</th>
                                                <th scope="col">Dieta</th>
                                                <th scope="col">Aclimatado</th>
                                                <th scope="col">Extraordinaria</th>
                                                <th scope="col">Horas Actividad</th>
                                                <th scope="col">Horas Nocturnidad</th>
                                            </tr>
                                            <tr>
                                                <td>{{actividad.id}}</td>
                                                <td>
                                                    {{actividad.fechaInicio}} {{actividad.horaInicio}}
                                                    <br>
                                                    <span class="actividad-local-style" data-toggle="tooltip" data-placement="top" title="Inicio local">
                                                        {{actividad.fechaInicioLocal}} {{actividad.horaInicioLocal}}
                                                    </span>
                                                </td>
                                                <td>
                                                    {{actividad.fechaFinal}} {{actividad.horaFinal}}
                                                    <br>
                                                    <span class="actividad-local-style" data-toggle="tooltip" data-placement="top" title="Final local">
                                                        {{actividad.fechaFinalLocal}} {{actividad.horaFinalLocal}}
                                                    </span>
                                                </td>
                                                <td>{{actividad.dieta}}</td>
                                                <td>{{actividad.aclimatado}}</td>
                                                <td>
                                                    {{actividad.actividadExtraordinaria ? 'Sí' : 'No'}}
                                                    <br>
                                                    <span *ngIf="actividad.horasActividad != '00:00'" data-toggle="tooltip" data-placement="top" title="Sector / Hora Inicio - Hora Final / Horas Máx">
                                                        {{actividad.extraordinaria.sector}} /
                                                        {{actividad.extraordinaria.hora_inicio}} -
                                                        {{actividad.extraordinaria.hora_final}} /
                                                        {{actividad.extraordinaria.horas_max}}
                                                    </span>
                                                </td>
                                                <td>{{actividad.horasActividad}}</td>
                                                <td>{{actividad.horasNocturnidad}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div *ngFor="let evento of actividad.nEventos" class="row nActividad-row">
                                    <div class="col-12">
                                        <table class="table nEventos-table">
                                            <tr>
                                                <th scope="col">ID / Código</th>
                                                <th scope="col">Trayecto Inicio</th>
                                                <th scope="col">Trayecto Fin</th>
                                                <th scope="col">Horas Perfil</th>
                                            </tr>
                                            <tr>
                                                <td>{{evento.id}} / {{evento.codigo}}</td>
                                                <td>{{evento.trayectoInicio}}</td>
                                                <td>{{evento.trayectoFinal}}</td>
                                                <td>{{evento.horasPerfil}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Fecha Inicio Vuelo</th>
                                                <th scope="col">Hora Inicio Vuelo</th>
                                                <th scope="col">Fecha Final Vuelo</th>
                                                <th scope="col">Hora Final Vuelo</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{evento.fechaInicioVuelo}}
                                                    <span class="evento-local-style" data-toggle="tooltip" data-placement="top" title="Fecha España Vuelo">{{evento.fechaInicioLocalVuelo}}</span>
                                                </td>
                                                <td>
                                                    {{evento.horaInicioVuelo}}
                                                    <span class="evento-local-style" data-toggle="tooltip" data-placement="top" title="Hora España Vuelo">{{evento.horaInicioLocalVuelo}}</span>
                                                </td>
                                                <td>
                                                    {{evento.fechaFinalVuelo}}
                                                    <span class="evento-local-style" data-toggle="tooltip" data-placement="top" title="Fecha España Vuelo">{{evento.fechaFinalLocalVuelo}}</span>
                                                </td>
                                                <td>
                                                    {{evento.horaFinalVuelo}}
                                                    <span class="evento-local-style" data-toggle="tooltip" data-placement="top" title="Hora España Vuelo">{{evento.horaFinalLocalVuelo}}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="back-row row">
        <div class="col-12 d-flex justify-content-end">
            <a class="btnBack" href="javascript:history.back()">
                Volver
            </a>
        </div>
    </div>
</div>