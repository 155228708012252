<nav class="container-fluid navBarra">

    <div class="row">

        <div class="col-8 d-flex justify-content-start align-items-center">
            <div class="imgContainer d-flex flex-column align-items-center">
                <img class="img-fluid imgLogoSepla" src="assets/img/logepla.png">
                <img class="img-fluid imgLogoAir" src="assets/img/logair.png">
            </div>
            <button class="btnSidebar d-flex align-items-center" (click)="sidebar(!class)">
                <i class="bi bi-list iconoSidebar"></i>
            </button>
        </div>

        <div class="col-4 d-flex justify-content-end align-items-center">
            <div class="dropdown">

                <button class="btnList d-flex justify-content-between" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <div class="icontainerFirst">
                        <i class="bi bi-gear-fill"></i>
                    </div>
                    <div class="icontainerSecond">
                        <i class="bi bi-gear"></i>
                    </div>
                </button>

                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" [routerLink]="['/sepla/perfil']">
                        <i class="bi bi-person-fill iconoMenu"></i>
                        <span class="textoEnlace">
                            Perfil
                        </span>
                    </a>
                    <!-- <a class="dropdown-item" [routerLink]="['/sepla/configuracion']">
                        <i class="bi bi-gear-wide-connected iconoMenu"></i>
                        <span class="textoEnlace">
                            Nómina
                        </span>
                    </a> -->
                    <a class="dropdown-item" [routerLink]="['/sepla/actividad']">
                        <i class="bi bi-play-fill iconoMenu"></i>
                        <span class="textoEnlace">
                            Actividad
                        </span>
                    </a>
                    <a *ngIf="checkAdmin" class="dropdown-item" [routerLink]="['/sepla/administrador']">
                        <i class="bi bi-award-fill iconoMenu"></i>
                        <span class="textoEnlace">
                            Administrador
                        </span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item d-flex justify-content-between" (click)="logout()">
                        <span class="textoEnlace enlaceItem">Salir</span><i class="bi bi-door-open-fill iconoSalir"></i>
                    </a>
                </div>

            </div>
        </div>

    </div>

</nav>