<div class="container-fluid">
    <div class="row">
        <button *ngIf="visual" class="btnDestacamento" (click)="visual=!visual"><span class="btnDestacamento__text">Ver destacamentos guardados</span><span class="btnDestacamento__icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke-width="2" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" height="24" fill="none" class="svg"><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span></button>
        <button *ngIf="!visual" class="btnDestacamento" (click)="visual=!visual"><span class="btnDestacamento__text">Ver posibles destacamentos</span><span class="btnDestacamento__icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke-width="2" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" height="24" fill="none" class="svg"><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span></button>
        <div class="col-12" *ngIf="visual">
            <fieldset class="fieldCard" [ngClass]="{'visible': visual, 'invisible': !visual}">
                <legend>
                    Lista posibles destacamentos
                </legend>

                <div id="datos-row" class="row">
                    <div class="col-12">
                        <div *ngFor="let destacamento of posiblesDestacamentos; let i = index;"
                            class="row activity-row">
                            <div class="col-2 fecha-inicio-col">
                                <p class="fecha-inicio-text">
                                    <i class="bi bi-arrow-right-short"></i>
                                    {{destacamento.posiblesDestacamentos[0].fecha_inicio}}
                                </p>
                            </div>
                            <div class="col-10 activity-col">
                                <div class="row nActividad-row">
                                    <div class="col-12">
                                        <table class="table table-borderless nActividad-table">
                                            <tr>
                                                <th scope="col">Tipo de Piloto</th>
                                                <th scope="col">Destacamento voluntario</th>
                                                <th scope="col">Guardar destacamento</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <button *ngIf="destacamento.tipoPiloto == null" type="button"
                                                        class="m-2 btn btn-primary"
                                                        (click)="modificarPiloto(i,'piloto')">Piloto</button>
                                                    <button *ngIf="destacamento.tipoPiloto == 'piloto'" type="button"
                                                        class="m-2 btn btn-success">Piloto</button>
                                                    <button *ngIf="destacamento.tipoPiloto == 'copiloto'" type="button"
                                                        class="m-2 btn btn-danger"
                                                        (click)="modificarPiloto(i, 'piloto')">Piloto</button>
                                                    <button *ngIf="destacamento.tipoPiloto == null" type="button"
                                                        class="m-2 btn btn-primary"
                                                        (click)="modificarPiloto(i,'copiloto')">Copiloto</button>
                                                    <button *ngIf="destacamento.tipoPiloto == 'copiloto'" type="button"
                                                        class="m-2 btn btn-success">Copiloto</button>
                                                    <button *ngIf="destacamento.tipoPiloto == 'piloto'" type="button"
                                                        class="m-2 btn btn-danger"
                                                        (click)="modificarPiloto(i, 'copiloto')">Copiloto</button>
                                                </td>
                                                <td>
                                                    <button *ngIf="destacamento.voluntario" type="button"
                                                        class="m-2 btn btn-success"
                                                        (click)="modificarVoluntario(i)">Si</button>
                                                    <button *ngIf="!destacamento.voluntario" type="button"
                                                        class="m-2 btn btn-danger"
                                                        (click)="modificarVoluntario(i)">Si</button>
                                                    <button *ngIf="destacamento.voluntario" type="button"
                                                        class="m-2 btn btn-danger"
                                                        (click)="modificarVoluntario(i)">No</button>
                                                    <button *ngIf="!destacamento.voluntario" type="button"
                                                        class="m-2 btn btn-success"
                                                        (click)="modificarVoluntario(i)">No</button>
                                                </td>
                                                <td>
                                                    <button *ngIf="destacamento.tipoPiloto != null" type="button"
                                                        class="m-2 btn btn-info"
                                                        (click)="guardarDestacamento(i)">Guardar</button>
                                                    <button *ngIf="destacamento.tipoPiloto == null" type="button"
                                                        class="m-2 btn btn-info" disabled="true">Guardar</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div *ngFor="let evento of destacamento.posiblesDestacamentos; let e = index;"
                                    class="row nActividad-row">
                                    <div class="col-12">
                                        <table class="table nEventos-table">
                                            <tr>
                                                <th scope="col">ID / Código</th>
                                                <th scope="col">Trayecto Inicio</th>
                                                <th scope="col">Trayecto Fin</th>
                                                <th scope="col">Días</th>
                                            </tr>
                                            <tr>
                                                <td>{{evento.id}} / {{evento.codigo}}</td>
                                                <td>{{evento.trayecto_inicio}}</td>
                                                <td>{{evento.trayecto_final}}</td>
                                                <td>{{evento.dias}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Fecha Inicio</th>
                                                <th scope="col">Hora Inicio</th>
                                                <th scope="col">Fecha Final</th>
                                                <th scope="col">Hora Final</th>
                                            </tr>
                                            <tr>
                                                <td>{{evento.fecha_inicio}}</td>
                                                <td>{{evento.hora_inicio}}</td>
                                                <td>{{evento.fecha_final}}</td>
                                                <td>{{evento.hora_final}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
        <div class="col-12" *ngIf="!visual">
            <fieldset class="fieldCard" [ngClass]="{'visible': !visual, 'invisible': visual}">
                <legend>
                    Lista destacamentos guardados
                </legend>

                <div id="datos-row" class="row">
                    <div class="col-12">
                        <div *ngFor="let uuid of uuids; let i = index;" class="row activity-row">
                            <div class="col-2 fecha-inicio-col">
                                <p class="fecha-inicio-text">
                                    <i class="bi bi-arrow-right-short"></i>
                                    {{destacamentos[uuid][0].eventoProgramado.fecha_inicio}}
                                </p>
                            </div>
                            <div class="col-10 activity-col">
                                <div class="row nActividad-row">
                                    <div class="col-12">
                                        <table class="table table-borderless nActividad-table">
                                            <tr>
                                                <th scope="col">Tipo de Piloto</th>
                                                <th scope="col">Destacamento voluntario</th>
                                                <th scope="col">Guardar destacamento</th>
                                                <th scope="col">Eliminar destacamento</th>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto == 'piloto'"
                                                        type="button" class="m-2 btn btn-success">Piloto</button>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto == 'copiloto'"
                                                        type="button" class="m-2 btn btn-danger"
                                                        (click)="modificarPilotoGuardado(uuid, 'piloto')">Piloto</button>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto == 'copiloto'"
                                                        type="button" class="m-2 btn btn-success">Copiloto</button>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto == 'piloto'"
                                                        type="button" class="m-2 btn btn-danger"
                                                        (click)="modificarPilotoGuardado(uuid, 'copiloto')">Copiloto</button>
                                                </td>
                                                <td>
                                                    <button *ngIf="destacamentos[uuid][0].voluntario" type="button"
                                                        class="m-2 btn btn-success"
                                                        (click)="modificarVoluntarioGuardado(uuid)">Si</button>
                                                    <button *ngIf="!destacamentos[uuid][0].voluntario" type="button"
                                                        class="m-2 btn btn-danger"
                                                        (click)="modificarVoluntarioGuardado(uuid)">Si</button>
                                                    <button *ngIf="destacamentos[uuid][0].voluntario" type="button"
                                                        class="m-2 btn btn-danger"
                                                        (click)="modificarVoluntarioGuardado(uuid)">No</button>
                                                    <button *ngIf="!destacamentos[uuid][0].voluntario" type="button"
                                                        class="m-2 btn btn-success"
                                                        (click)="modificarVoluntarioGuardado(uuid)">No</button>
                                                </td>
                                                <td>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto != null"
                                                        type="button" class="m-2 btn btn-info"
                                                        (click)="guardarDestacamentoGuardado(uuid)">Guardar</button>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto == null"
                                                        type="button" class="m-2 btn btn-info"
                                                        disabled="true">Guardar</button>
                                                </td>
                                                <td>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto != null"
                                                        type="button" class="m-2 btn btn-danger"
                                                        (click)="eliminarDestacamentoGuardado(uuid)">Eliminar</button>
                                                    <button *ngIf="destacamentos[uuid][0].tipoPiloto == null"
                                                        type="button" class="m-2 btn btn-danger"
                                                        disabled="true">Eliminar</button>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div *ngFor="let evento of destacamentos[uuid]; let e = index;"
                                    class="row nActividad-row">
                                    <div class="col-12">
                                        <table class="table nEventos-table">
                                            <tr>
                                                <th scope="col">ID / Código</th>
                                                <th scope="col">Trayecto Inicio</th>
                                                <th scope="col">Trayecto Fin</th>
                                                <th scope="col">Días</th>
                                            </tr>
                                            <tr>
                                                <td>{{evento.eventoProgramado.id}} / {{evento.eventoProgramado.codigo}}
                                                </td>
                                                <td>{{evento.eventoProgramado.trayecto_inicio}}</td>
                                                <td>{{evento.eventoProgramado.trayecto_final}}</td>
                                                <td>{{evento.dias}}</td>
                                            </tr>
                                            <tr>
                                                <th scope="col">Fecha Inicio</th>
                                                <th scope="col">Hora Inicio</th>
                                                <th scope="col">Fecha Final</th>
                                                <th scope="col">Hora Final</th>
                                            </tr>
                                            <tr>
                                                <td>{{evento.eventoProgramado.fecha_inicio}}</td>
                                                <td>{{evento.eventoProgramado.hora_inicio}}</td>
                                                <td>{{evento.eventoProgramado.fecha_final}}</td>
                                                <td>{{evento.eventoProgramado.hora_final}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="back-row row">
        <div class="col-12 d-flex justify-content-end">
            <a class="btnBack" href="javascript:history.back()">
                Volver
            </a>
        </div>
    </div>
</div>