import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/providers/api.service';
import { SwalService } from 'src/app/providers/swal.service';
import { DashboardService } from '../../../providers/dashboard.service';

@Component({
  selector: 'app-add-eventos',
  templateUrl: './add-eventos.component.html',
  styleUrls: ['../dashboard.component.scss', './add-eventos.component.scss']
})
export class AddEventosComponent implements OnInit {

  textAreaForm: FormGroup;
  textAreaForm2: FormGroup;
  casilleroForm: FormGroup;
  pdfForm: FormGroup;
  anios: string[] = [];
  pageActual = 1;

  datosTextArea: any[] = [];
  datosTextArea2: any[] = [];
  datosCasillero: any[] = [];
  datosPdf: any[] = [];


  //FICHERO CSV
  nombreFichero = 'Seleccionar fichero.';
  fichero: File;
  errFichero: string;
  msjFichero: string;

  constructor(
    private _form: FormBuilder,
    private _api: ApiService,
    private _dash: DashboardService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    let fecha = new Date();
    let anio = fecha.getFullYear();
    this.anios.push(anio.toString());
    for (let a = 0; a < 3; a++) {
      anio -= 1;
      this.anios.push(anio.toString());
    }
    this.crearFormularioTextArea();
    this.crearFormularioCasillero();
    this.crearFormularioPdf();
  }
  // EVENTOS CASILLERO
  crearFormularioCasillero() {
    this.casilleroForm = this._form.group({
      mes: ['', [Validators.required]],
      anio: ['', [Validators.required]],
    });
  }

  crearFormularioPdf() {
    this.pdfForm = this._form.group({
      mes: ['', [Validators.required]],
      anio: ['', [Validators.required]],
    });
  }

  leerDatos3() {
    console.log('leerDatos3');
    this.loadingCasillero = true;
    this._api.setEventoHttp(this.casilleroForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          if (result.msg == 'Tienes avisos pendientes entra en el casillero y acéptalos.') {
            this._swal.launchErrorSwal(result.msg, () => { });
          } else if (result.msg == 'No se pudo descargar todos los eventos. Vuelve a intentarlo.') {
            this._swal.launchErrorSwal(result.msg, () => { });
          } else if (result.msg == 'No hay suficiente cantidad de datos en ese mes.') {
            this._swal.launchErrorSwal('No se pudo descargar todos los eventos. Vuelve a intentarlo.', () => { });
          } else if (result.msg == 'Error en el sistema.') {
            this._swal.launchErrorSwal('Error en la web del casillero, compruebe que el usuario y la contraseña son correctos.', () => { });
          } else {
            this._swal.launchCorrectSwal("Eventos descargados", () => {
              this.datosCasillero = this.transformarCasilleroObjeto(result.msg);
              if (this.datosCasillero.length > 0) {
                this._swal.launchWarningSwal("Confirme los datos en la tabla y pulse enviar", () => {
                  this.scrollTo("eventosCasillero");
                });
              } else {
                this._swal.launchErrorSwal("Error en los datos", () => { });
              }
            });
          }
          this.loadingCasillero = false;
        } else {
          this._swal.launchErrorSwal(`Error al descargar eventos, contacte con administración`, () => {
            this.loadingCasillero = false;
          });
        }
      },
      error: (error) => {
        this._swal.launchErrorSwal("Algo ha salido mal. Contacte con administración", () => {
          this.loadingCasillero = false;
        });
      }
    });
  }

  transformarCasilleroObjeto(datos: any[]): any[] {
    let resultado: any[] = [];
    if (datos.length > 0) {
      for (const fila of datos) {
        let objeto = {
          usuarioId: 0, codigo: '', cod_fun: '', fecha_inicio: '', hora_inicio: '', fecha_final: '', hora_final: '', trayecto_inicio: '', trayecto_final: '', vuelo: '', extra: '', color: '#5900E1'
        };
        if (fila.length > 0) {
          let fecha_inicio = this._dash.separarFecha(fila[1]);
          let fecha_final = this._dash.separarFecha(fila[3]);
          let codigo = fila[0].trim();
          let cod_fun = fila[6].trim();
          if (fila[0].includes('-')) {
            codigo = fila[0].split('-')[0].trim();
            cod_fun = fila[0].split('-')[1].trim();
          }
          objeto.codigo = fila[0].includes('VA -') ? 'VA' : codigo;
          objeto.cod_fun = cod_fun;
          objeto.fecha_inicio = fecha_inicio.fecha.trim();
          objeto.hora_inicio = fecha_inicio.hora == '(LT)' ? '00:00' : fecha_inicio.hora.trim();
          objeto.fecha_final = fecha_final.fecha.trim();
          objeto.hora_final = fecha_final.hora == '(LT)' ? '00:00' : fecha_final.hora.trim();
          objeto.trayecto_inicio = fila[2].trim();
          objeto.trayecto_final = fila[4].trim();
          objeto.vuelo = fila[5].trim();

          if (objeto.fecha_inicio != '' && objeto.hora_inicio != '' && objeto.fecha_final != '' && objeto.hora_final != '' && objeto.fecha_inicio.length <= 10 && objeto.hora_inicio.length <= 10 && objeto.fecha_final.length <= 10 && objeto.hora_final.length <= 10) {
            resultado.push(objeto);
          }
        }
      };
    }
    return resultado;
  }

  // EVENTOS TEXTAREA
  crearFormularioTextArea() {
    this.textAreaForm = this._form.group({
      texto: ['', [Validators.required]]
    });
    this.textAreaForm2 = this._form.group({
      texto2: ['', [Validators.required]]
    });
  }

  leerDatos() {
    console.log('leerDatos');
    this.datosTextArea = [];
    this.datosTextArea = this.transformarTextAreaObjeto(this.textAreaForm.value.texto);
    if (this.datosTextArea.length > 0) {
      this._swal.launchWarningSwal("Confirme los datos en la tabla y pulse enviar", () => {
        this.scrollTo("eventosM");
      });
    } else {
      this._swal.launchErrorSwal("Datos incorrectos, revise el ejemplo", () => { });
    }
  }

  leerDatos2() {
    console.log('leerDatos2');
    this.datosTextArea2 = [];
    this.datosTextArea2 = this.transformarTextAreaObjeto2(this.textAreaForm2.value.texto2);
    if (this.datosTextArea2.length > 0) {
      this._swal.launchWarningSwal("Confirme los datos en la tabla y pulse enviar", () => {
        this.scrollTo("eventosW");
      });
    } else {
      this._swal.launchErrorSwal("Datos incorrectos, revise el ejemplo", () => { });
    }
  }

  enviarTextArea(tipo) {
    let datosTextArea: any[] = [];
    if (tipo == 1) {
      this.loadingMail = true;
      datosTextArea = this.datosTextArea;
    } else if (tipo == 2) {
      this.loadingWeb = true;
      datosTextArea = this.datosTextArea2;
    } else if (tipo == 3) {
      this.loadingCasillero = true;
      datosTextArea = this.datosCasillero;
      tipo = 2;
    } else {
      this.loadingPdf = true;
      datosTextArea = this.datosPdf;
      tipo = 1;
    }

    this._api.enviarTextArea(datosTextArea, tipo).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Eventos guardados", () => {
            this.datosTextArea = [];
            this.datosTextArea2 = [];
            this.datosCasillero = [];
            this.datosPdf = [];
            this.textAreaForm.controls.texto.setValue('');
            this.textAreaForm2.controls.texto2.setValue('');
            this.loadingMail = false;
            this.loadingWeb = false;
            this.loadingCasillero = false;
            this.loadingPdf = false;
          });
        } else {
          if (result.error.message = 'ER_DUP_ENTRY') {
            this._swal.launchErrorSwal(`Error tienes un duplicado. (${result.error.parameters})`, () => {
              this.loadingMail = false;
              this.loadingWeb = false;
              this.loadingCasillero = false;
              this.loadingPdf = false;
            });
          }
        }
      },
      error: (error) => {
        this._swal.launchErrorSwal("Algo ha salido mal", () => {
          this.loadingMail = false;
          this.loadingWeb = false;
          this.loadingCasillero = false;
          this.loadingPdf = false;
        });
      }
    });
  }

  transformarTextAreaObjeto(texto): any[] {
    let resultado: any[] = [];
    if (texto != '') {
      const separarFilas = texto.split('\n');
      for (const fila of separarFilas) {
        let objeto = {
          usuarioId: 0, codigo: '', cod_fun: '', fecha_inicio: '', hora_inicio: '', fecha_final: '', hora_final: '', trayecto_inicio: '', trayecto_final: '', vuelo: '', extra: '', color: '#5900E1'
        };
        const textoSinEspacios = fila.trim();
        const separarEspacios = textoSinEspacios.split(' ');
        const eliminadoVacio = separarEspacios.filter(x => x != '');
        switch (eliminadoVacio.length) {
          case 9:
            if (eliminadoVacio[0].trim() == 'VS') {
              objeto.codigo = eliminadoVacio[0].trim();
              objeto.cod_fun = '';
              objeto.fecha_inicio = eliminadoVacio[1].trim();
              objeto.hora_inicio = eliminadoVacio[2].trim();
              objeto.fecha_final = eliminadoVacio[3].trim();
              objeto.hora_final = eliminadoVacio[4].trim();
              objeto.trayecto_inicio = eliminadoVacio[5].trim();
              objeto.trayecto_final = eliminadoVacio[6].trim();
              objeto.vuelo = eliminadoVacio[7].trim();
            } else {
              objeto.codigo = eliminadoVacio[0].trim();
              objeto.cod_fun = eliminadoVacio[1].trim();
              objeto.fecha_inicio = eliminadoVacio[2].trim();
              objeto.hora_inicio = eliminadoVacio[3].trim();
              objeto.fecha_final = eliminadoVacio[4].trim();
              objeto.hora_final = eliminadoVacio[5].trim();
              objeto.trayecto_inicio = eliminadoVacio[6].trim();
              objeto.trayecto_final = eliminadoVacio[7].trim();
              objeto.vuelo = eliminadoVacio[8].trim();
            }
            break;
          case 8:
            if (eliminadoVacio[0].trim() == 'VS') {
              objeto.codigo = eliminadoVacio[0].trim();
              objeto.cod_fun = '';
              objeto.fecha_inicio = eliminadoVacio[1].trim();
              objeto.hora_inicio = eliminadoVacio[2].trim();
              objeto.fecha_final = eliminadoVacio[3].trim();
              objeto.hora_final = eliminadoVacio[4].trim();
              objeto.trayecto_inicio = eliminadoVacio[5].trim();
              objeto.trayecto_final = eliminadoVacio[6].trim();
              objeto.vuelo = eliminadoVacio[7].trim();
            } else if (eliminadoVacio[1].trim().length == 10) {
              objeto.codigo = eliminadoVacio[0].trim();
              objeto.fecha_inicio = eliminadoVacio[1].trim();
              objeto.hora_inicio = eliminadoVacio[2].trim();
              objeto.fecha_final = eliminadoVacio[3].trim();
              objeto.hora_final = eliminadoVacio[4].trim();
              objeto.trayecto_inicio = eliminadoVacio[5].trim();
              objeto.trayecto_final = eliminadoVacio[6].trim();
              objeto.extra = eliminadoVacio[7].trim();
            } else {
              objeto.codigo = eliminadoVacio[0].trim();
              objeto.cod_fun = eliminadoVacio[1].trim();
              objeto.fecha_inicio = eliminadoVacio[2].trim();
              objeto.hora_inicio = eliminadoVacio[3].trim();
              objeto.fecha_final = eliminadoVacio[4].trim();
              objeto.hora_final = eliminadoVacio[5].trim();
              objeto.trayecto_inicio = eliminadoVacio[6].trim();
              objeto.trayecto_final = eliminadoVacio[7].trim();
            }
            break;
          case 7:
            objeto.codigo = eliminadoVacio[0].trim();
            objeto.fecha_inicio = eliminadoVacio[1].trim();
            objeto.hora_inicio = eliminadoVacio[2].trim();
            objeto.fecha_final = eliminadoVacio[3].trim();
            objeto.hora_final = eliminadoVacio[4].trim();
            objeto.trayecto_inicio = eliminadoVacio[5].trim();
            objeto.trayecto_final = eliminadoVacio[6].trim();
            break;
          default:
            break;
        }
        if (objeto.fecha_final.length == 10 && objeto.fecha_inicio.length == 10 && objeto.hora_final.length == 5 && objeto.hora_inicio.length == 5 && objeto.fecha_inicio != '' && objeto.hora_inicio != '' && objeto.fecha_final != '' && objeto.hora_final != '' && objeto.fecha_inicio.length <= 10 && objeto.hora_inicio.length <= 10 && objeto.fecha_final.length <= 10 && objeto.hora_final.length <= 10) {
          resultado.push(objeto);
        }
      };
    }
    return resultado;
  }

  transformarTextAreaObjeto2(texto): any[] {
    let resultado: any[] = [];
    if (texto != '') {
      let texto2 = texto.replace(/\t/g, ' ');
      const separarFilas = texto2.split('\n');
      for (const fila of separarFilas) {
        let objeto = {
          usuarioId: 0, codigo: '', cod_fun: '', fecha_inicio: '', hora_inicio: '', fecha_final: '', hora_final: '', trayecto_inicio: '', trayecto_final: '', vuelo: '', extra: '', color: '#5900E1'
        };

        const textoSinEspacios = fila.trim();
        const separarEspacios = textoSinEspacios.split(' ');
        const eliminadoVacio = separarEspacios.filter(x => x != '');
        console.log({ eliminadoVacio });

        switch (eliminadoVacio.length) {
          case 11:
            if (eliminadoVacio[2].includes('RT21')) {
              objeto.codigo = 'RT';
              objeto.extra = 'VA';
            } else if (eliminadoVacio[2].includes('RT')) {
              objeto.extra = 'LD';
              objeto.codigo = 'RT'
            } else {
              if (eliminadoVacio[2].trim() == 'PAT'){
                objeto.extra = eliminadoVacio[2].trim();
              } else {
                objeto.extra = eliminadoVacio[5].trim();
              }
              objeto.codigo = eliminadoVacio[0].trim();
            }
            objeto.fecha_inicio = eliminadoVacio[3].trim();
            objeto.hora_inicio = eliminadoVacio[4].trim();
            objeto.fecha_final = eliminadoVacio[7].trim();
            objeto.hora_final = eliminadoVacio[8].trim();
            objeto.trayecto_inicio = eliminadoVacio[6].trim();
            objeto.trayecto_final = eliminadoVacio[10].trim();
            break;
          case 10:
            objeto.codigo = eliminadoVacio[0].trim();
            objeto.cod_fun = eliminadoVacio[8].trim();
            objeto.fecha_inicio = eliminadoVacio[1].trim();
            objeto.hora_inicio = eliminadoVacio[2].trim();
            objeto.fecha_final = eliminadoVacio[4].trim();
            objeto.hora_final = eliminadoVacio[5].trim();
            objeto.trayecto_inicio = eliminadoVacio[3].trim();
            objeto.trayecto_final = eliminadoVacio[6].trim();
            objeto.vuelo = eliminadoVacio[7].trim();
            break;
          case 9:
            objeto.codigo = eliminadoVacio[0].trim();
            if (eliminadoVacio[1].trim() == '-') {
              objeto.fecha_inicio = eliminadoVacio[3].trim();
              objeto.fecha_final = eliminadoVacio[6].trim();
              if (eliminadoVacio[2].trim() != '(LT)') {
                objeto.hora_inicio = eliminadoVacio[4].trim();
                objeto.hora_final = eliminadoVacio[7].trim();
              }
              if (eliminadoVacio[4].trim() == '(LT)' || eliminadoVacio[7].trim() == '(LT)') {
                objeto.hora_inicio = eliminadoVacio[4].trim() == '(LT)' ? '00:00' : eliminadoVacio[2].trim();
                objeto.hora_final = eliminadoVacio[7].trim() == '(LT)' ? '00:00' : eliminadoVacio[5].trim();
              }
              objeto.trayecto_inicio = eliminadoVacio[5].trim();
              objeto.trayecto_final = eliminadoVacio[8].trim();
              objeto.extra = eliminadoVacio[2].trim();
            } else if (eliminadoVacio[0].trim() == 'VS') {
              objeto.fecha_inicio = eliminadoVacio[1].trim();
              objeto.hora_inicio = eliminadoVacio[2].trim();
              objeto.fecha_final = eliminadoVacio[4].trim();
              objeto.hora_final = eliminadoVacio[5].trim();
              objeto.trayecto_inicio = eliminadoVacio[3].trim();
              objeto.trayecto_final = eliminadoVacio[6].trim();
              objeto.vuelo = eliminadoVacio[7].trim();
            }
            else {
              objeto.fecha_inicio = eliminadoVacio[1].trim();
              objeto.hora_inicio = eliminadoVacio[2].trim();
              objeto.fecha_final = eliminadoVacio[5].trim();
              objeto.hora_final = eliminadoVacio[6].trim();
              objeto.trayecto_inicio = eliminadoVacio[4].trim();
              objeto.trayecto_final = eliminadoVacio[8].trim();
              objeto.extra = eliminadoVacio[3].trim();
            }
            break;
          case 8:
            objeto.codigo = eliminadoVacio[0].trim();
            objeto.fecha_inicio = eliminadoVacio[1].trim();
            objeto.hora_inicio = eliminadoVacio[2].trim() == '(LT)' ? '00:00' : eliminadoVacio[2].trim();
            objeto.fecha_final = eliminadoVacio[4].trim();
            objeto.hora_final = eliminadoVacio[5].trim() == '(LT)' ? '00:00' : eliminadoVacio[5].trim();
            objeto.trayecto_inicio = eliminadoVacio[3].trim();
            objeto.trayecto_final = eliminadoVacio[6].trim();
            objeto.extra = eliminadoVacio[7].trim();
            break;
          case 7:
            objeto.codigo = eliminadoVacio[0].trim();
            objeto.fecha_inicio = eliminadoVacio[1].trim();
            objeto.hora_inicio = eliminadoVacio[2].trim() == '(LT)' ? '00:00' : eliminadoVacio[2].trim();
            objeto.fecha_final = eliminadoVacio[4].trim();
            objeto.hora_final = eliminadoVacio[5].trim() == '(LT)' ? '00:00' : eliminadoVacio[5].trim();
            objeto.trayecto_inicio = eliminadoVacio[3].trim();
            objeto.trayecto_final = eliminadoVacio[6].trim();
            break;
          default:
            break;
        }
        if (objeto.fecha_final.length == 10 && objeto.fecha_inicio.length == 10 && objeto.hora_final.length == 5 && objeto.hora_inicio.length == 5 && objeto.fecha_inicio != '' && objeto.hora_inicio != '' && objeto.fecha_final != '' && objeto.hora_final != '' && objeto.fecha_inicio.length <= 10 && objeto.hora_inicio.length <= 10 && objeto.fecha_final.length <= 10 && objeto.hora_final.length <= 10) {
          resultado.push(objeto);
        }
      };
    }
    return resultado;
  }

  // EVENTOS PDF

  subirFichero() {
    this.loadingPdf = true;
    this.pageActual = 1;
    this.datosPdf = [];
    // if (!this.pdfForm.valid) {
    //   this._swal.launchErrorSwal("Selecciona el mes y el año", () => { });
    //   return;
    // }
    let mes = this.pdfForm.value.mes;
    let anio = this.pdfForm.value.anio;
    if (this.fichero) {
      let formData = new FormData();
      formData.append('file', this.fichero, this.fichero.name);
      this._api.subirFicheroProgramado(mes, anio, formData).subscribe({
        next: (result) => {
          if (result.status) {
            this.loadingPdf = false;
            this._swal.launchWarningSwal("Confirme los datos en la tabla y pulse enviar", () => { });
            this.datosPdf = result.resultado;
          } else {
            this._swal.launchErrorSwal("Algo ha salido mal", () => { });
          }
        },
        error: (error) => {
          this.loadingPdf = false;
          this._swal.launchErrorSwal("Algo ha salido mal", () => { });
        }
      });
    } else {
      this.loadingPdf = false;
      this._swal.launchErrorSwal("Introduce un fichero", () => { });
    }
  }

  changeFichero(event) {
    this.fichero = null;
    this.msjFichero = null;
    this.errFichero = null;
    this.nombreFichero = event.name;
    let validarFichero = this.validarFichero(event);
    if (validarFichero.status) {
      this.fichero = event;
      this.msjFichero = validarFichero.msj;
    } else {
      this.fichero = null;
      this.errFichero = validarFichero.error;
    }
  }

  validarFichero(fichero) {
    var nombreCorto = fichero['name'].split('.');
    var extension = nombreCorto[nombreCorto.length - 1];
    var extenValidas = ['pdf', 'PDF'];
    if (extenValidas.indexOf(extension) < 0) {
      return { status: false, error: `Debe de seleccionar una extensión válida: ${extenValidas.join(', ')}` };
    }
    if (!fichero) {
      return { status: false, error: 'No es un fichero' };
    }
    if (fichero.size > 5000000) {
      return { status: false, error: 'El fichero es demasiado grande (max. 5mb)' };
    }
    return { status: true, msj: 'Fichero correcto' };
  }

  // Dinámico

  public mailSelec: boolean = false;
  public webSelec: boolean = false;
  public pdfSelec: boolean = false;
  public casilleroSelec: boolean = false;
  public loadingMail: boolean = false;
  public loadingWeb: boolean = false;
  public loadingCsv: boolean = false;
  public loadingCasillero: boolean = false;
  public loadingPdf: boolean = false;


  public selecCancelar(): void {
    this.mailSelec = false;
    this.webSelec = false;
    this.pdfSelec = false;
    this.loadingMail = false;
    this.loadingWeb = false;
    this.loadingCsv = false;
    this.casilleroSelec = false;
    this.loadingCasillero = false;
    this.loadingPdf = false;
  }

  public selecMail(): void {
    this.mailSelec = true;
    this.webSelec = false;
    this.pdfSelec = false;
    this.loadingMail = false;
    this.loadingWeb = false;
    this.loadingCsv = false;
    this.casilleroSelec = false;
    this.loadingCasillero = false;
    this.loadingPdf = false;
  }

  public selecWeb(): void {
    this.mailSelec = false;
    this.webSelec = true;
    this.pdfSelec = false;
    this.loadingMail = false;
    this.loadingWeb = false;
    this.loadingCsv = false;
    this.casilleroSelec = true;
    this.loadingCasillero = false;
    this.loadingPdf = false;
  }

  public selecPdf(): void {
    this.mailSelec = false;
    this.webSelec = false;
    this.pdfSelec = true;
    this.loadingMail = false;
    this.loadingWeb = false;
    this.loadingCsv = false;
    this.casilleroSelec = false;
    this.loadingCasillero = false;
    this.loadingPdf = false;
  }

  // public selecWebCasillero(): void {
  //   this.mailSelec = false;
  //   this.webSelec = false;
  //   this.csvSelec = false;
  //   this.loadingMail = false;
  //   this.loadingWeb = false;
  //   this.loadingCsv = false;
  //   this.casilleroSelec = true;
  //   this.loadingCasillero = false;
  // }

  public ejemploTextArea() {
    this._swal.launchImgSwal("assets/img/ejemploTextArea.png");
  }

  public ejemploTextArea2() {
    this._swal.launchImgSwal("assets/img/ejemploTextArea2.png");
  }

  public ejemploCsv() {
    this._swal.launchImgSwal("assets/img/ejemploCsv.png");
  }

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  scrollTo(eId: any) {
    const elto = document.getElementById(eId);
    if (elto) {
      elto.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

}
