import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  visible = false;
  breakpoint = 1300;
  visibleMenu = false;
  bpMenu = 764;

  constructor() {
    if (window.innerWidth >= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
    if (window.innerWidth >= this.bpMenu) {

      this.visibleMenu = true;
    } else {
      this.visibleMenu = false;
    }
  }

  onResize(event: { target: { innerWidth: any; }; }) {
    const w = event.target.innerWidth;
    if (w >= this.breakpoint) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  onResizeMenu(event: { target: { innerWidth: any; }; }) {
    const w = event.target.innerWidth;
    if (w >= this.bpMenu) {
      this.visibleMenu = true;
    } else {
      this.visibleMenu = false;
    }
  }

  nombreMay(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  apellidoMay(data: string) {
    const apellido = data.split(' ');
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < apellido.length; i++) {
      apellido[i] = apellido[i].charAt(0).toUpperCase() + apellido[i].slice(1);
    }
    return apellido.join(' ');
  }

  ordenarFechas(lista: any[]) {
    lista.sort((a: { creado: any; }, b: { creado: any; }) => {
      const aa = new Date(a.creado);
      const bb = new Date(b.creado);
      return aa.getTime() - bb.getTime();
    });

    return lista;
  }

  ordenarFechasEventos(lista: any[]) {
    lista.sort((a, b) => {
      const aa = new Date(this.transformar_fecha(a.fecha_inicio, a.hora_inicio == '(LT)' ? '00:00' : a.hora_inicio));
      const bb = new Date(this.transformar_fecha(b.fecha_inicio, b.hora_inicio == '(LT)' ? '00:00' : b.hora_inicio));
      return aa.getTime() - bb.getTime();
    });

    return lista;
  }

  transformar_fecha(fecha, hora) {
    let f = fecha.split('/');
    let h = hora.split(':');
    let date = `${f[2]}-${f[1]}-${f[0]}T${h[0]}:${h[1]}:00`;
    return date;
  }


  eventosMinutos(fechaInicio: any, horaInicio: any, fechaFinal: any, horaFinal: any) {
    const HORAS_MAX = 24;
    const MINUTOS_MAX = 60;
    let fh_inicio = this.transformarFecha(fechaInicio, horaInicio);
    let fh_final = this.transformarFecha(fechaFinal, horaFinal);
    let minutos_finales = 0;
    if (fechaInicio == fechaFinal) {
      let horas = fh_final.hora - fh_inicio.hora - 1;
      let minutos = MINUTOS_MAX - fh_inicio.minuto + fh_final.minuto;
      minutos_finales = (horas * MINUTOS_MAX) + minutos;
    } else {
      if (fh_inicio.mes == fh_final.mes) {
        let dias = fh_final.dia - fh_inicio.dia - 1;
        let horas = HORAS_MAX - fh_inicio.hora - 1 + fh_final.hora;
        let minutos = MINUTOS_MAX - fh_inicio.minuto + fh_final.minuto;
        if (dias == 0) {
          minutos_finales = (horas * MINUTOS_MAX) + minutos;
        } else {
          minutos_finales = ((dias * HORAS_MAX) * MINUTOS_MAX) + (horas * MINUTOS_MAX) + minutos;
        }
      } else {
        let diasMesInicio = this.diasEnUnMes(fh_inicio.mes, fh_inicio.anio);
        let meses = fh_final.mes - fh_inicio.mes - 1;
        let dias = diasMesInicio - fh_inicio.dia + fh_final.dia;
        let horas = HORAS_MAX - fh_inicio.hora - 1 + fh_final.hora;
        let minutos = MINUTOS_MAX - fh_inicio.minuto + fh_final.minuto;
        if (meses == 0) {
          minutos_finales = ((dias * HORAS_MAX) * MINUTOS_MAX) + (horas * MINUTOS_MAX) + minutos;
        } else {
          let dias_meses = 0;
          for (let x = 1; x <= meses; x++) {
            let mesMasX = fh_inicio.mes + x;
            let anio = fh_inicio.anio;
            if (mesMasX >= 13) {
              mesMasX = 1;
              anio = anio + 1;
            }
            dias_meses = dias_meses + this.diasEnUnMes(mesMasX, anio);
          }
          minutos_finales = ((dias_meses * HORAS_MAX) * MINUTOS_MAX) + ((dias * HORAS_MAX) * MINUTOS_MAX) + (horas * MINUTOS_MAX) + minutos;
        }
      }
    }
    return minutos_finales;
  }

  transformarFecha(fecha: any, hora: any) {
    try {
      let f = fecha.split('/');
      let h = hora.split(':');
      let resultado = {
        dia: Number(f[0]),
        mes: Number(f[1]),
        anio: Number(f[2]),
        hora: Number(h[0]),
        minuto: Number(h[1])
      };
      return resultado;
    } catch (error) {
      let resultado = {
        dia: Number(0),
        mes: Number(0),
        anio: Number(0),
        hora: Number(0),
        minuto: Number(0)
      };
      return resultado;
    }
  }

  separarFecha(fecha: any) {
    let fec = fecha.split(' ');
    return { fecha: fec[0], hora: fec[1] }
  }

  diasEnUnMes(mes: any, anio: any) {
    return new Date(anio, mes, 0).getDate();
  }
}
