import { Pipe, PipeTransform } from '@angular/core';
import { Conflicto } from '../models/conflicto.model';

@Pipe({
  name: 'filterAnio'
})
export class FilterAnioPipe implements PipeTransform {

  transform(value: Conflicto[], arg: number): Conflicto[] {
    let conflictos: Conflicto[] = [];
    if (arg === 0) {
      conflictos = value;
    } else {
      value.forEach(c => {
        const fecha: Date = new Date(c.fecha);
        if (fecha.getFullYear() === arg) {
          conflictos.push(c);
        }
      });
    }
    return conflictos;
  }

}
