import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/providers/api.service';
import { AuthService } from 'src/app/providers/auth.service';
import { StorageService } from 'src/app/providers/storage.service';

@Component({
  selector: 'app-aeropuertos',
  templateUrl: './aeropuertos.component.html',
  styleUrls: ['../dashboard.component.scss', './listas.component.scss']
})
export class AeropuertosComponent implements OnInit {

  lista;
  pageActual = 1;
  mostrarTodo = true;
  buscar = '';
  seleccionado = {
    actualizado: "",
    aeropuerto: "",
    continente: "",
    creado: "",
    iata: "",
    id: 0,
    localizacion: "",
    momentZone: "",
    oaci: "",
    pais: "",
    utc: ""
  }
  seleccionadoForm: FormGroup;
  checkAdmin = false;
  mostrarEditar = false;
  mensajeInformativo = '';

  constructor(private _auth: AuthService, private _api: ApiService, private _formB: FormBuilder, private _storage: StorageService) {
    this.crearFormulario(this.seleccionado, null);
  }

  ngOnInit(): void {
    this._api.getAeropuertos().subscribe({
      next: (aeropuertos) => {
        if (aeropuertos.status) {
          this.onCheckUser();
          this.extraerAeropuertos(aeropuertos.aeropuertos);
        }
      },
      error: (error) => {
      }
    });
  }

  onCheckUser() {
    if (this._storage.isAuthenticated()) {
      this._auth.getPiloto().subscribe({
        next: (result) => {
          if (result.status) {
            this.extraerUsuario(result);
          }
        },
        error: (error) => {
        }
      });
    } else {
      this.checkAdmin = false;
    }
  }

  extraerUsuario(result) {
    if (result.status) {
      this.esAdmin(result.piloto.usuario.tipo);
    } else {
      this.checkAdmin = false;
      this._storage.logout();
    }
  }

  esAdmin(admin) {
    if (admin == 'admin') {
      this.checkAdmin = true;
    } else {
      this.checkAdmin = false;
    }
  }

  crearFormulario(seleccionado, $e) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
    if (seleccionado.id == 0) {
      this.mostrarEditar = false;
    } else {
      this.mostrarEditar = true;
    }
    this.mensajeInformativo = '';

    this.seleccionado = seleccionado;
    this.seleccionadoForm = this._formB.group({
      aeropuerto: [seleccionado != undefined ? seleccionado.aeropuerto : '', [Validators.required, Validators.maxLength(100)]],
      continente: [seleccionado != undefined ? seleccionado.continente : '', [Validators.required, Validators.maxLength(40)]],
      iata: [seleccionado != undefined ? seleccionado.iata : '', [Validators.required, Validators.maxLength(4)]],
      id: [seleccionado != undefined ? seleccionado.id : '', [Validators.required, Validators.maxLength(40)]],
      localizacion: [seleccionado != undefined ? seleccionado.localizacion : '', [Validators.required, Validators.maxLength(40)]],
      momentZone: [seleccionado != undefined ? seleccionado.momentZone : '', [Validators.required, Validators.maxLength(200)]],
      oaci: [seleccionado != undefined ? seleccionado.oaci : '', [Validators.required, Validators.maxLength(4)]],
      pais: [seleccionado != undefined ? seleccionado.pais : '', [Validators.required, Validators.maxLength(40)]],
      utc: [seleccionado != undefined ? seleccionado.utc : '', [Validators.required, Validators.maxLength(3)]],
    });
  }

  enviarAeropuerto() {
    if (this.seleccionadoForm.valid) {
      this._api.setAeropuerto(this.seleccionadoForm.value).subscribe({
        next: (aeropuerto) => {
          if (aeropuerto.status) {
            this.ngOnInit();
            this.mensajeInformativo = 'Aeropuerto editado correctamente';
          } else {
            this.mensajeInformativo = 'Error al editar el aeropuerto contacte con el servicio técnico';
          }
        },
        error: (error) => {
          this.mensajeInformativo = 'Error al editar el aeropuerto contacte con el servicio técnico';
        }
      });
    }
  }


  extraerAeropuertos(aeropuertos) {
    this.lista = aeropuertos;
  }

  mostrar() {
    if (this.mostrarTodo) {
      this.mostrarTodo = false;
    } else {
      this.mostrarTodo = true;
    }
  }

  iconos(icono: number) {
    switch (icono) {
      case 1: {
        return 'fas fa-plane';
      }
      case 2: {
        return 'fas fa-home';
      }
      case 3: {
        return 'fas fa-globe';
      }
      case 4: {
        return 'fas fa-plane';
      }
      case 5: {
        return 'fas fa-calendar';
      }
      default: {
        return 'fas fa-plane';
      }
    }
  }

  //Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

}
