<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <div>
                        <i class="bi bi-person-fill iconoLegend"></i> Perfil
                    </div>
                </legend>

                <div class="row d-flex justify-content-center">
                    <form class="col-12 col-md-10 col-lg-8" [formGroup]=" perfilForm" (ngSubmit)="enviar()"
                        novalidate="novalidate">
                        <div class="row formRow">
                            <div class="col-12 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-envelope iconoInput"></i> Email
                                </p>
                                <input class="textoInput" [attr.disabled]="true" formControlName="email" type="text">
                            </div>
                        </div>
                        <div class="row formRow">
                            <div class="col-12 col-md-4 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-person iconoInput"></i> Nombre
                                </p>
                                <input class="textoInput" formControlName="nombre" type="text">
                            </div>
                            <div class="col-12 col-md-4 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-person iconoInput"></i> Primer Apellido
                                </p>
                                <input class="textoInput" formControlName="apellido1" type="text">
                            </div>
                            <div class="col-12 col-md-4 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-person iconoInput"></i> Segundo Apellido
                                </p>
                                <input class="textoInput" formControlName="apellido2" type="text">
                            </div>
                        </div>
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-signpost iconoInput"></i> País
                                </p>
                                <select class="textoSelect" formControlName="pais">
                                    <option *ngFor="let pais of paises">{{pais}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-signpost iconoInput"></i> Base Operativa
                                </p>
                                <select class="textoSelect" formControlName="base">
                                    <option *ngFor="let base of bases" [value]="base.value">{{base.nombre}}
                                    </option>
                                </select>
                            </div>
                            <!-- <div class="col-12 col-md-4 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-person iconoInput"></i> Usuario casillero
                                </p>
                                <input class="textoInput" formControlName="usuarioCasillero" type="text">
                            </div>
                            <div class="col-12 col-md-4 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-person iconoInput"></i> Contraseña casillero
                                </p>
                                <input class="textoInput" formControlName="passwordCasillero" type="password">
                            </div> -->
                        </div>
                        <div class="row formRow">
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-sliders iconoInput"></i> Flota
                                </p>
                                <select class="textoSelect" formControlName="flota">
                                    <option *ngFor="let flota of flotas">{{flota}}</option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-sliders iconoInput"></i> Nivel
                                </p>
                                <select class="textoSelect" formControlName="nivel">
                                    <option *ngFor="let nivel of niveles" [value]="nivel.id">
                                        {{nivel.nivel}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-sliders iconoInput"></i> Categoría
                                </p>
                                <select class="textoSelect" formControlName="categoria">
                                    <option value="P1">P1</option>
                                    <option value="P2">P2</option>

                                </select>
                            </div>
                            <div class="col-12 col-md-6 formCol">
                                <p class="tituloInput">
                                    <i class="bi bi-sliders iconoInput"></i> Reducción de Jornada
                                </p>
                                <select class="textoSelect" name="reduccion_jornada" formControlName="reduccion_jornada">
                                    <option value="1">Ninguna</option>
                                    <option value="0.875">1/8</option>
                                    <option value="0.83333333">1/6</option>
                                    <option value="0.8">1/5</option>
                                    <option value="0.75">1/4</option>
                                    <option value="0.666666">1/3</option>
                                    <option value="0.5">1/2</option>
                                </select>
                            </div>
                        </div>

                        <div class="row btnRow">
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit"
                                    class="{{!perfilForm.valid ? 'btnGuardarDisabled' : 'btnGuardar'}}"
                                    [disabled]="!perfilForm.valid">
                                    <i class="bi bi-sd-card"></i>
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="row backRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>