<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-file-earmark-text-fill iconoLegend"></i> Mis Nóminas
                    <select [(ngModel)]="anioSeleccionado" class="selectorAnio">
                        <option *ngFor="let anio of aniosSeleccionables; index as i" class="selectorAnioOption"
                            [ngValue]="anio">{{anio}}</option>
                    </select>
                    <i class="bi bi-caret-down-fill iconoSelector"></i>
                </legend>

                <div *ngIf="mesesAniosBonitos[(anioSeleccionado)] != undefined && mesesAniosBonitos[(anioSeleccionado)].length != 0"
                    class="row d-flex justify-content-center">
                    <div *ngFor="let mes of mesesAniosBonitos[(anioSeleccionado)]; index as i"
                        class="col-6 col-md-2 d-flex justify-content-center">
                        <fieldset class="fieldNominaMes {{mes === 'nc' ? 'fieldNominaMesNC' : ''}}">
                            <legend class="legendNominaMes">
                                {{mes === 'nc' ? mesesNombres[i] : mes}}
                            </legend>
                            <div *ngIf="mes != 'nc'" class="row rowMesNomina">
                                <div (click)="actualizarNomina(anioSeleccionado,mes)"
                                    class="colIcoVer col-6 d-flex justify-content-center align-items-center">
                                    <i class="iconoNominaVer bi bi-eye-fill"></i>
                                </div>
                                <div (click)="eliminarNomina(anioSeleccionado,mes)"
                                    class="colIcoEliminar col-6 d-flex justify-content-center align-items-center">
                                    <i class="iconoNominaEliminar bi bi-trash-fill"></i>
                                </div>
                            </div>
                            <div *ngIf="mes === 'nc'" class="row rowMesNomina">
                                <div (click)="addNomina(mesesNombres[i]); scrollTo(formNomina);"
                                    class="colIcoAdd col-12 d-flex justify-content-center">
                                    <i class="iconoNominaAdd bi bi-file-earmark-plus-fill"></i>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div *ngIf="mesesAniosBonitos[(anioSeleccionado)] === undefined || mesesAniosBonitos[(anioSeleccionado)].length === 0"
                    class="row d-flex justify-content-center">
                    <div class="cardInfoNominas col-10">
                        <p class="textInfoNominas">
                            <i class="iconInfoNominas bi bi-exclamation-lg"></i> No has generado nóminas para el año
                            seleccionado.
                        </p>
                    </div>
                </div>

                <div #formNomina class="row formNominaRow d-flex justify-content-center">
                    <div class="col-12 col-lg-8">
                        <fieldset class="cardForm">

                            <legend class="tituloForm" (click)="mostrarGenerarNomina();">
                                <i class="bi bi-file-earmark-plus-fill iconoTituloForm"></i>
                                <span class="textoTituloForm">
                                    {{!mostrarGenerar ? 'Crear Nómina' : 'Creando Nómina'}}
                                </span>
                            </legend>

                            <div *ngIf="loading" class="row loadingRow">
                                <div class="col-12 d-flex justify-content-center align-items-center">
                                    <div class="spinner-border text-info" role="status">
                                        <span class="sr-only">Cargando...</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="mostrarGenerar && !loading" class="row">
                                <form class="col-12" [formGroup]="nominasForm" (ngSubmit)="crearNomina()" method="post">

                                    <div class="row d-flex justify-content-center mesAnioRow">
                                        <div
                                            class="col-12 col-md-4 d-flex justify-content-center align-items-center formCol">
                                            <i class="bi bi-calendar-month-fill iconPrepend"></i>
                                            <p class="textPrepend">
                                                Mes
                                            </p>
                                            <input type="text" class="inputText" placeholder="0"
                                                (click)="iniciar('mes_hoy')" formControlName="mes_hoy">
                                        </div>
                                        <div
                                            class="col-12 col-md-4 d-flex justify-content-center align-items-center formCol">
                                            <i class="bi bi-calendar-date-fill iconPrepend"></i>
                                            <p class="textPrepend">
                                                Año
                                            </p>
                                            <select [(ngModel)]="anioSeleccionado" class="inputText pointer-class"
                                                formControlName="anio_hoy">
                                                <option *ngFor="let anio of aniosSeleccionables; index as i"
                                                    class="selectorAnioOption" [ngValue]="anio">{{anio}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row d-flex justify-content-center">
                                        <div class="separador"></div>
                                    </div>

                                    <div class="row d-flex justify-content-center selectoresRow">
                                        <div class="col-12 col-md-6 formCol">
                                            <p class="seleTitulo">
                                                <i class="bi bi-arrow-counterclockwise seleIcono"></i> Reducción de
                                                Jornada
                                            </p>
                                            <select class="selector" formControlName="reduccion_jornada"
                                                id="reduccion_jornada">
                                                <option value="1">Ninguna</option>
                                                <option value="0.875">1/8</option>
                                                <option value="0.83333333">1/6</option>
                                                <option value="0.8">1/5</option>
                                                <option value="0.75">1/4</option>
                                                <option value="0.666666">1/3</option>
                                                <option value="0.5">1/2</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-md-6 formCol">
                                            <p class="seleTitulo">
                                                <i class="bi bi-send seleIcono"></i> Flota
                                            </p>
                                            <select class="selector" formControlName="flota" id="flota">
                                                <option *ngFor="let flota of flotas" [value]="flota">{{flota}}</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-md-6 formCol">
                                            <p class="seleTitulo">
                                                <i class="bi bi-award seleIcono"></i> Categoría
                                            </p>
                                            <select class="selector" formControlName="categoria" id="categoria">
                                                <option *ngFor="let categoria of categorias" [value]="categoria">
                                                    {{categoria}}</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-md-6 formCol">
                                            <p class="seleTitulo">
                                                <i class="bi bi-thermometer-half seleIcono"></i> Nivel
                                            </p>
                                            <select class="selector" formControlName="nivel" id="nivel">
                                                <option *ngFor="let nivel of niveles" [value]="nivel.id">
                                                    {{nivel.nivel}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-12 mt-2 separador"></div>
                                    </div>

                                    <div class="row d-flex justify-content-center">
                                        <!-- <div class="col-12 col-md-4 formCol">
                                            <div class="col-12">
                                                <p class="tituloOpcion tituloOpcionOpcional">Bases
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('bases')" formControlName="bases">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 formCol">
                                            <div class="col-12">
                                                <p class="tituloOpcion tituloOpcionOpcional">
                                                    <i class="bi bi-percent opcionIcon"></i> Contingencias comunes
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('contingenciasComunes')"
                                                        formControlName="contingenciasComunes">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 formCol">
                                            <div class="col-12">
                                                <p class="tituloOpcion tituloOpcionOpcional">
                                                    <i class="bi bi-percent opcionIcon"></i> Desempleo trabajador
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('desempleo')"
                                                        formControlName="desempleo">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 formCol">
                                            <div class="col-12">
                                                <p class="tituloOpcion tituloOpcionOpcional">
                                                    <i class="bi bi-percent opcionIcon"></i> Formación profesional trabajador
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('formacionProfesional')"
                                                        formControlName="formacionProfesional">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 formCol">
                                            <div class="col-12">
                                                <p class="tituloOpcion tituloOpcionOpcional">
                                                    <i class="bi bi-percent opcionIcon"></i> IRPF
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('irpf_porciento')"
                                                        formControlName="irpf_porciento">
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-12 col-md-4 formCol">
                                            <div class="col-12">
                                                <p class="tituloOpcion tituloOpcionOpcional">
                                                    Pérdida de licencia
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('perdidaLicencia')"
                                                        formControlName="perdidaLicencia">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2 separador"></div> -->
                                    </div>
                                    <div class="row d-flex justify-content-center">
                                        <div class="col-12 col-md-3 formCol">
                                            <div class="row opcionRow">
                                                <div class="col-12">
                                                    <p class="tituloOpcion">
                                                        <i class="bi bi-basket opcionIcon"></i> Precio día vacaciones
                                                        (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputText" placeholder="0"
                                                            (click)="iniciar('vacaciones_devengos')"
                                                            formControlName="vacaciones_devengos">
                                                        <!-- <button type="button" class="btnSub ml-1"
                                                            (click)="calcularPrecioVacaciones()"><i
                                                                class="bi bi-arrow-clockwise actualizar"></i></button> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 mt-2 formCol">
                                            <p class="tituloOpcion">
                                                ¿Tienes dos niveles en la
                                                nómina? (Inserta el nuevo)
                                            </p>
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="segundo_nivel_boolean_si" type="radio"
                                                        formControlName="segundo_nivel_boolean" value='Sí'>
                                                    <label for="segundo_nivel_boolean_si" class="lblOpcion">Sí</label>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="segundo_nivel_boolean_no" type="radio"
                                                        formControlName="segundo_nivel_boolean" value='No'>
                                                    <label for="segundo_nivel_boolean_no" class="lblOpcion">No</label>
                                                </div>
                                            </div>
                                            <div *ngIf="nominasForm.value.segundo_nivel_boolean != 'No'"
                                                class="row rowOpcional">
                                                <div class="col-6">
                                                    <p class="seleTitulo">
                                                        <i class="bi bi-thermometer-half seleIcono"></i> Segundo Nivel
                                                    </p>
                                                    <select class="selector" formControlName="segundo_nivel"
                                                        id="segundo_nivel">
                                                        <option *ngFor="let nivel of niveles" [value]="nivel.id">
                                                            {{nivel.nivel}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-6">
                                                    <p class="tituloOpcion tituloOpcionOpcional">
                                                        <i class="bi bi-piggy-bank opcionIcon"></i> Días segundo nivel
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputTextOpcional" placeholder="0"
                                                            (click)="iniciar('dias_segundo_nivel')"
                                                            formControlName="dias_segundo_nivel">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt-2 separador"></div>
                                    </div>
                                    <!-- 
                                    <div class="row d-flex justify-content-center opcionesRow">

                                        <div class="col-12 col-md-3 formCol">
                                            <div class="row opcionRow">
                                                <div class="col-12">
                                                    <p class="tituloOpcion">
                                                        <i class="bi bi-cash-coin opcionIcon"></i> Billetes Free (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputText" placeholder="0"
                                                            (click)="iniciar('valor_billetes_free')"
                                                            formControlName="valor_billetes_free">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-3 formCol">
                                            <div class="row opcionRow">
                                                <div class="col-12">
                                                    <p class="tituloOpcion">
                                                        <i class="bi bi-basket opcionIcon"></i> Comida Crew (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputText" placeholder="0"
                                                            (click)="iniciar('comida_crew')"
                                                            formControlName="comida_crew">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-3 formCol">
                                            <div class="row opcionRow">
                                                <div class="col-12">
                                                    <p class="tituloOpcion">
                                                        <i class="bi bi-cart-plus opcionIcon"></i> Anticipo Dieta (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputText" placeholder="0"
                                                            (click)="iniciar('anticipo_dietas')"
                                                            formControlName="anticipo_dietas">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3 formCol">
                                            <div class="row opcionRow">
                                                <div class="col-12">
                                                    <p class="tituloOpcion">
                                                        <i class="bi bi-basket opcionIcon"></i> Precio día vacaciones
                                                        (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputText" placeholder="0"
                                                            (click)="iniciar('vacaciones_devengos')"
                                                            formControlName="vacaciones_devengos">
                                                        <button type="button" class="btnSub ml-1"
                                                            (click)="calcularPrecioVacaciones()"><i
                                                                class="bi bi-arrow-clockwise actualizar"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row d-flex justify-content-center opcionesRow">

                                        <div class="col-12 col-md-4 formCol">
                                            <p class="tituloOpcion">
                                                <i class="bi bi-piggy-bank opcionIcon"></i> Plan de Pensiones Propio
                                            </p>
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="plan_pensiones_boolean_si" type="radio"
                                                        formControlName="plan_pensiones_boolean" value='Sí'>
                                                    <label for="plan_pensiones_boolean_si" class="lblOpcion">Sí</label>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="plan_pensiones_boolean_no" type="radio"
                                                        formControlName="plan_pensiones_boolean" value='No'>
                                                    <label for="plan_pensiones_boolean_no" class="lblOpcion">No</label>
                                                </div>
                                            </div>
                                            <div *ngIf="nominasForm.value.plan_pensiones_boolean != 'No'"
                                                class="row rowOpcional">
                                                <div class="col-12">
                                                    <p class="tituloOpcion tituloOpcionOpcional">
                                                        <i class="bi bi-piggy-bank opcionIcon"></i> Cantidad Mensual (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputTextOpcional" placeholder="0"
                                                            (click)="iniciar('plan_pensiones')"
                                                            formControlName="plan_pensiones">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4 formCol">
                                            <p class="tituloOpcion">
                                                <i class="bi bi-hospital opcionIcon"></i> Seguro Médico Propio
                                            </p>
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="seguro_medico_boolean_si" type="radio"
                                                        formControlName="seguro_medico_boolean" value='Sí'>
                                                    <label for="seguro_medico_boolean_si" class="lblOpcion">Sí</label>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="seguro_medico_boolean_no" type="radio"
                                                        formControlName="seguro_medico_boolean" value='No'>
                                                    <label for="seguro_medico_boolean_no" class="lblOpcion">No</label>
                                                </div>
                                            </div>
                                            <div *ngIf="nominasForm.value.seguro_medico_boolean != 'No'"
                                                class="row rowOpcional">
                                                <div class="col-12">
                                                    <p class="tituloOpcion tituloOpcionOpcional">
                                                        <i class="bi bi-hospital opcionIcon"></i> Cantidad Seguro (€)
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputTextOpcional" placeholder="0"
                                                            (click)="iniciar('seguro_medico')"
                                                            formControlName="seguro_medico">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="nominasForm.value.seguro_medico_boolean != 'No'"
                                                class="row rowOpcional">
                                                <div class="col-12">
                                                    <p class="tituloOpcion tituloOpcionOpcional">
                                                        <i class="bi bi-hospital opcionIcon"></i> Número de Familiares
                                                    </p>
                                                    <div class="row d-flex justify-content-center">
                                                        <input type="text" class="inputTextOpcional" placeholder="0"
                                                            (click)="iniciar('miembros_seguro_medico')"
                                                            formControlName="miembros_seguro_medico">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-4 formCol">
                                            <p class="tituloOpcion">
                                                <i class="bi bi-globe2 opcionIcon"></i> Globalia flex:
                                            </p>
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="globalia_flex_boolean_si" type="radio"
                                                        formControlName="globalia_flex_boolean" value='Sí'>
                                                    <label for="globalia_flex_boolean_si" class="lblOpcion">Sí</label>
                                                </div>
                                                <div class="col-4 d-flex justify-content-center align-items-center">
                                                    <input id="globalia_flex_boolean_no" type="radio"
                                                        formControlName="globalia_flex_boolean" value='No'>
                                                    <label for="globalia_flex_boolean_no" class="lblOpcion">No</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-md-4 mt-2 formCol">
                                        <p class="tituloOpcion">
                                            ¿Tienes dos niveles en la
                                            nómina? (Inserta el nuevo)
                                        </p>
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-4 d-flex justify-content-center align-items-center">
                                                <input id="segundo_nivel_boolean_si" type="radio"
                                                    formControlName="segundo_nivel_boolean" value='Sí'>
                                                <label for="segundo_nivel_boolean_si" class="lblOpcion">Sí</label>
                                            </div>
                                            <div class="col-4 d-flex justify-content-center align-items-center">
                                                <input id="segundo_nivel_boolean_no" type="radio"
                                                    formControlName="segundo_nivel_boolean" value='No'>
                                                <label for="segundo_nivel_boolean_no" class="lblOpcion">No</label>
                                            </div>
                                        </div>
                                        <div *ngIf="nominasForm.value.segundo_nivel_boolean != 'No'"
                                            class="row rowOpcional">
                                            <div class="col-6">
                                                <p class="seleTitulo">
                                                    <i class="bi bi-thermometer-half seleIcono"></i> Segundo Nivel
                                                </p>
                                                <select class="selector" formControlName="segundo_nivel" id="segundo_nivel">
                                                    <option *ngFor="let nivel of niveles" [value]="nivel.id">
                                                        {{nivel.nivel}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-6">
                                                <p class="tituloOpcion tituloOpcionOpcional">
                                                    <i class="bi bi-piggy-bank opcionIcon"></i> Días segundo nivel
                                                </p>
                                                <div class="row d-flex justify-content-center">
                                                    <input type="text" class="inputTextOpcional" placeholder="0"
                                                        (click)="iniciar('dias_segundo_nivel')"
                                                        formControlName="dias_segundo_nivel">
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="row subRow">
                                        <div class="col-12 d-flex justify-content-end">
                                            <button type="submit"
                                                class="{{!nominasForm.valid ? 'btnSubDisabled' : 'btnSub'}}"
                                                [disabled]="!nominasForm.valid">
                                                <i class="bi bi-file-earmark-plus-fill"></i>
                                                Crear Nómina
                                            </button>
                                        </div>
                                    </div>

                                </form>

                            </div>

                        </fieldset>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>