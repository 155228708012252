import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/providers/auth.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { StorageService } from 'src/app/providers/storage.service';
import { SwalService } from 'src/app/providers/swal.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['../dashboard.component.scss', './perfil.component.scss']
})
export class ConfiguracionComponent implements OnInit {

  configuracionForm: FormGroup;

  constructor(
    private _form: FormBuilder,
    public _dash: DashboardService,
    private _store: StorageService,
    private _auth: AuthService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
    this._auth.getPiloto().subscribe({
      next: (result) => {
        if (result.status) {
          this.crearFormulario(result.piloto)
        }
      },
      error: (error) => {
      }
    });
  }

  crearFormulario(piloto?) {
    this.configuracionForm = this._form.group({
      contingencias_comunes_porciento: [piloto ? piloto.contingencias_comunes_porciento : ''],
      desempleo_trabajador_porciento: [piloto ? piloto.desempleo_trabajador_porciento : ''],
      formacion_prof_porciento: [piloto ? piloto.formacion_prof_porciento : ''],
      irpf_porciento: [piloto ? piloto.irpf_porciento : '', [Validators.required]],
      perdida_licencia: [piloto ? piloto.perdida_licencia : '', [Validators.required]]
    });
  }

  enviar() {
    this.configuracionForm.value.contingencias_comunes_porciento = this.configuracionForm.value.contingencias_comunes_porciento.replace(',', '.');
    this.configuracionForm.value.desempleo_trabajador_porciento = this.configuracionForm.value.desempleo_trabajador_porciento.replace(',', '.');
    this.configuracionForm.value.formacion_prof_porciento = this.configuracionForm.value.formacion_prof_porciento.replace(',', '.');
    this.configuracionForm.value.irpf_porciento = this.configuracionForm.value.irpf_porciento.replace(',', '.');
    this.configuracionForm.value.perdida_licencia = this.configuracionForm.value.perdida_licencia.replace(',', '.');
    this._auth.editPilotoNomina(this.configuracionForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Datos guardados", () => { });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  // Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

}
