<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <div>
                        <i class="bi bi-award-fill iconoLegend"></i> Administración
                    </div>
                </legend>
                <!-- <h3>FCom. Económico anexo IV</h3> -->
                <div class="row">
                    <!-- <div class="col-4 formCol">
                        <p class="tituloInput">
                            <i class="bi bi-calendar-month-fill iconoInput"></i> Més
                        </p>
                        <input class="textoInput" name="mesSindicato" [(ngModel)]="mesSindicato" type="text">
                    </div>
                    <div class="col-4 formCol">
                        <p class="tituloInput">
                            <i class="bi bi-calendar-month-fill iconoInput"></i> Año
                        </p>
                        <input class="textoInput" name="anioSindicato" [(ngModel)]="anioSindicato" type="text">
                    </div>
                    <div class="col-4 formCol" style="margin-top: '29px'">
                        <input type="file" class="custom-file-input" id="fichero"
                            (change)="changeFichero($event.target.files[0])" style="cursor:pointer;">
                        <label class="textoInput custom-file-label elige" for="fichero"
                            style="cursor:pointer;">{{nombreFichero}}</label>
                        <p *ngIf="errFichero" class="alert alert-danger m-2">{{errFichero}}</p>
                        <p *ngIf="msjFichero" class="alert alert-success m-2">{{msjFichero}}</p>
                    </div> -->
                    <div class="col-lg-12">
                        <div class="card shadow mb-4">
                            <div class="card-body row align-items-center justify-content-center text-center">
                                <div class="col-md-3 col-sm-3"></div>
                                <div class="col-md-3 col-sm-6">
                                    <span *ngIf="!mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar todo</button></span>
                                    <span *ngIf="mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar menos</button></span>
                                </div>
                                <div class="col-md-3 col-sm-6">
                                    <button type="button" class="btn botonSeplaAct"
                                        [routerLink]="['/sepla/lista-perfiles']">Perfiles Vuelo</button>
                                </div>
                                <div class="col-12" *ngIf="!mostrarTodo">
                                    <div class="row m-2"
                                        *ngFor="let elemento of lista | paginate: {itemsPerPage: 6, currentPage: pageActual}">
                                        <div
                                            class="col-md-6 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            {{elemento.usuario.email}}
                                        </div>
                                        <div
                                            class="col-md-4 col-sm-12 fondoUno list-group-item list-group-item-secondary">
                                            {{elemento.apellido1}} {{elemento.apellido2}}, {{elemento.nombre}}
                                        </div>
                                        <div
                                            class="col-md-2 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            <button type="button" class="btn btn-success btn-sm"><i
                                                    class="fa fa-database"
                                                    (click)="editStatus(elemento.usuarioId)"></i></button>
                                            <button type="button" class="btn btn-danger btn-sm" style="margin-left: 2px"
                                                (click)="editStatus(elemento.usuarioId)"><i
                                                    class="fa fa-trash"></i></button>

                                        </div>
                                    </div>
                                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente"
                                        *ngIf="lista !== undefined && !mostrarTodo" class="text-left"
                                        (pageChange)="pageActual = $event"></pagination-controls>
                                </div>
                                <div class="col-12" *ngIf="mostrarTodo">
                                    <div
                                        class="col-lg-12 p-2 card align-items-center justify-content-center text-center">
                                        <input type="text" class="col-lg-6 form-control m-4" name="filsterPost"
                                            placeholder="Buscar..." [(ngModel)]="filterPost">
                                        <form class="dashboard-form" [formGroup]="userForm">
                                            <input class="m-2" type="radio" name="centro" formControlName="centro"
                                                value="0">Nombre
                                            <input class="m-2" type="radio" name="centro" formControlName="centro"
                                                value="1">Primer Apellido
                                            <input class="m-2" type="radio" name="centro" formControlName="centro"
                                                value="2">Segundo Apellido
                                            <input class="m-2" type="radio" name="centro" formControlName="centro"
                                                value="3">Correo electrónico
                                        </form>
                                    </div>
                                    <div class="row m-2"
                                        *ngFor="let elemento of lista| filterUser:[filterPost,seleccionado]">
                                        <div
                                            class="col-md-4 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            {{elemento.usuario.email}}
                                        </div>
                                        <div
                                            class="col-md-4 col-sm-12 fondoUno list-group-item list-group-item-secondary">
                                            {{elemento.apellido1}} {{elemento.apellido2}}, {{elemento.nombre}}
                                        </div>
                                        <div
                                            class="col-md-2 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            <button *ngIf="!elemento.usuario.estado" type="button"
                                                class="btn btn-danger btn-sm"><i class="fa fa-database"
                                                    (click)="editStatus(elemento.usuario.id)"></i></button>
                                            <button *ngIf="elemento.usuario.estado" type="button"
                                                class="btn btn-success btn-sm" style="margin-left: 2px"
                                                (click)="editStatus(elemento.usuario.id)"><i
                                                    class="fa fa-database"></i></button>
                                            <button *ngIf="elemento.usuario.intentosPassword >= 5" type="button"
                                                class="btn btn-danger btn-sm" style="margin-left: 2px"
                                                (click)="resetAttempts(elemento.usuarioId)"><i
                                                    class="fa fa-thumbs-o-down"></i></button>

                                            <button *ngIf="elemento.usuario.intentosPassword < 5" type="button"
                                                class="btn btn-success btn-sm" style="margin-left: 2px"
                                                (click)="resetAttempts(elemento.usuarioId)"><i
                                                    class="fa fa-thumbs-o-up"></i></button>
                                        </div>

                                        <div
                                            class="col-md-2 col-sm-12 fondoDos list-group-item list-group-item-secondary">
                                            <button *ngIf="!elemento.usuario.sindicato" type="button"
                                                class="btn btn-danger btn-sm"
                                                (click)="editSindicato(elemento.usuario.id)">
                                                No es del sindicato</button>
                                            <button *ngIf="elemento.usuario.sindicato" type="button"
                                                class="btn btn-success btn-sm" style="margin-left: 2px"
                                                (click)="editSindicato(elemento.usuario.id)">Es del sindicato</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>