export const environment = {
  production: false,
  // path: 'https://localhost:7080/',
  path: 'https://mensoftbiotec.com:7080/',
  // path: 'http://serverdata-mensoft-labs.duckdns.org:7080/',
  // path787: 'https://localhost:7081/',
  path787: 'https://mensoftbiotec.com:7081/',
  // path787: 'http://serverdata-mensoft-labs.duckdns.org:7081/',
  auth: '9dVPVVsqPXpwJGGDm/5DkXTesFJaNMc6UkH+vHWvSki530vN4tdoyz4FyyILe2YaGlBDNVsb/xcn0rfq9nwdTA==9dVPVVsqPXpwJGGDm/5DkXTesFJaNMc6UkH+vHWvSki530vN4tdoyz4FyyILe2YaGlBDNVsb/xcn0rfq9nwdTA==9dVPVVsqPXpwJGGDm/5DkXTesFJaNMc6UkH+vHWvSki530vN4tdoyz4FyyILe2YaGlBDNVsb/xcn0rfq9nwdTA==9dVPVVsqPXpwJGGDm/5DkXTesFJaNMc6UkH+vHWvSki530vN4tdoyz4FyyILe2YaGlBDNVsb/xcn0rfq9nwdTA==9dVPVVsqPXpwJGGDm/5DkXTesFJaNMc6UkH+vHWvSki530vN4tdoyz4FyyILe2YaGlBDNVsb/xcn0rfq9nwdTA==',
};
