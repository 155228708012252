<div class="container-fluid">

    <div class="titleRow row d-flex justify-content-center">
        <div class="col-6 d-flex flex-column align-items-center">
            <img class="img-fluid imgLogoSepla" src="assets/img/logepla.png">
            <img class="img-fluid imgLogoAir" src="assets/img/logair.png">
        </div>
    </div>

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">

            <fieldset>

                <legend>
                    Área Privada
                </legend>

                <form [formGroup]="loginForm" (ngSubmit)="login();">

                    <div class="row form-group">
                        <div class="col-12">
                            <label class="labelInput">
                                <i class="iconInput bi bi-envelope"></i>
                                Correo Electrónico
                            </label>
                        </div>
                        <div class="col-12">
                            <input type="text" class="textInput" formControlName="email">
                        </div>
                    </div>

                    <div class="row form-group">
                        <div class="col-12">
                            <label class="labelInput">
                                <i class="iconInput passIcon bi {{passVer ? 'bi-unlock' : ' bi-lock' }}"
                                    (click)="passEye();"></i>
                                Contraseña
                            </label>
                        </div>
                        <div class="col-12">
                            <input type="{{passVer ? 'text' : 'password' }}" class="textInput"
                                formControlName="password">
                        </div>
                    </div>

                    <div class="row btnRow">
                        <div class="col-12 d-flex justify-content-between flex-row-reverse">
                            <button [disabled]="!loginForm.valid"
                                class="{{!loginForm.valid ? 'btnAccesoDisabled': 'btnAcceso'}}" type="submit">
                                Acceder
                            </button>
                            <button class="btnRegistro" [routerLink]="['/register']">
                                Registro
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <a class="forgotLink" [routerLink]="['/forgot']">¿Has olvidado tu contraseña?</a>
                        </div>
                    </div>

                </form>

            </fieldset>
            <p class="motto">&copy; {{today | date: 'yyyy'}} <a class="mottoLink" href="https://www.mensoft.es">Mensoft
                    Consultores</a>
            </p>

        </div>
    </div>

</div>