import { Component, OnInit, ViewChild } from '@angular/core';

// Full Calendar
import interactionGridPlugin from "@fullcalendar/interaction";
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { ApiService } from 'src/app/providers/api.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { ModalManager } from 'ngb-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalService } from 'src/app/providers/swal.service';

@Component({
  selector: 'app-calendario-list',
  templateUrl: './calendario-list.component.html',
  styleUrls: ['../dashboard.component.scss', './calendario.component.scss']
})
export class CalendarioListComponent implements OnInit {

  calElement: any;
  events = [];
  eventosOriginal = [];
  eventosOriginalRealizados = [];
  eventsFull = [];
  options: any;
  calendar: Calendar;
  @ViewChild('modalEvento') eventoModal: any;
  private modalE: any;
  eventoForm: FormGroup;

  constructor(
    private _formB: FormBuilder,
    private _modal: ModalManager,
    private _api: ApiService,
    public _dashboard: DashboardService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
    this._api.getEventos().subscribe({
      next: (programado) => {
        if (programado.status) {
          this._api.getTodosEventosServicio().subscribe({
            next: (realizado) => {
              this.cargarEventos(programado.eventos);
              this.eventosOriginal = programado.eventos;
              if (realizado.status) {
                this.eventosOriginalRealizados = realizado.eventos;
              }
              this.crearCalendario();
            },
            error: (error) => {
            }
          });
        }
      },
      error: (error) => {
      }
    });
  }

  cargarEventos(eventos: any) {
    for (const evento of eventos) {
      let objeto = this.transformarEvento(evento);
      this.events.push(objeto);
    };
    this.eventsFull = this.events;
  }

  transformarEvento(evento: any) {
    let start = this._dashboard.transformar_fecha(evento.fecha_inicio, evento.hora_inicio);
    let end = this._dashboard.transformar_fecha(evento.fecha_final, evento.hora_final);
    let title = '';
    if (evento.vuelo != '') {
      title = `${evento.trayecto_inicio}-${evento.trayecto_final}`
    } else {
      title = `${evento.codigo}`
    }
    let objeto = {
      title,
      start,
      end,
      color: evento.color,
      description: title,
      textColor: '#fff',
      allDay: false,
      tipo: evento.codigo,
      id_evento: evento.id,
      vuelo: evento.vuelo,
    };
    return objeto;
  }

  crearFormulario(evento?: any) {
    let realizado = this.eventosOriginalRealizados.find(x => x.id == evento.relacion);
    let fInicioR = realizado != undefined ? realizado.fecha_inicio.split('/') : '';
    let fFinalR = realizado != undefined ? realizado.fecha_final.split('/') : '';
    let fInicio = evento != undefined ? evento.fecha_inicio.split('/') : '';
    let fFinal = evento != undefined ? evento.fecha_final.split('/') : '';
    this.eventoForm = this._formB.group({
      id: [evento != undefined ? evento.id : '', [Validators.required]],
      cod_fun: [evento != undefined ? evento.cod_fun : '', [Validators.maxLength(3)]],
      codigo: [evento != undefined ? evento.codigo : '', [Validators.required, Validators.maxLength(3)]],
      extra: [evento != undefined ? evento.extra : '', [Validators.maxLength(4)]],
      fecha_final: [evento != undefined ? `${fFinal[2]}-${fFinal[1]}-${fFinal[0]}` : '', [Validators.required]],
      fecha_inicio: [evento != undefined ? `${fInicio[2]}-${fInicio[1]}-${fInicio[0]}` : '', [Validators.required]],
      hora_final: [evento != undefined ? evento.hora_final : '', [Validators.required, Validators.maxLength(5)]],
      hora_inicio: [evento != undefined ? evento.hora_inicio : '', [Validators.required, Validators.maxLength(5)]],
      vuelo_fecha_inicio: [realizado != undefined ? `${fInicioR[2]}-${fInicioR[1]}-${fInicioR[0]}` : '', [Validators.required]],
      vuelo_fecha_final: [realizado != undefined ? `${fFinalR[2]}-${fFinalR[1]}-${fFinalR[0]}` : '', [Validators.required]],
      vuelo_hora_final: [realizado != undefined ? realizado.hora_final : '', [Validators.required, Validators.maxLength(5)]],
      vuelo_hora_inicio: [realizado != undefined ? realizado.hora_inicio : '', [Validators.required, Validators.maxLength(5)]],
      trayecto_final: [evento != undefined ? evento.trayecto_final : '', [Validators.required, Validators.maxLength(3)]],
      trayecto_inicio: [evento != undefined ? evento.trayecto_inicio : '', [Validators.required, Validators.maxLength(3)]],
      vuelo: [evento != undefined ? evento.vuelo : '', [Validators.maxLength(10)]],
      color: [evento != undefined ? evento.color : '',],
      charter: [evento != undefined ? evento.charter : false,],
      alerta: [evento != undefined ? evento.charter : false,],
      nacional_internacional: [evento != undefined ? evento.nacional_internacional : '',],
    });

    this.eventoForm.controls.fecha_inicio.valueChanges.subscribe(() => {
      if (this.eventoForm.controls.fecha_inicio.value > this.eventoForm.controls.fecha_final.value) {
        this.eventoForm.controls.fecha_inicio.setValue(this.eventoForm.controls.fecha_final.value);
      }
    });
  }

  crearCalendario() {
    this.calElement = document.getElementById('calendarlist');
    this.montarCalendario(this.events);
  }

  montarCalendario(eventos, fecha?) {
    let defaultDate;
    if (!fecha) {
      defaultDate = new Date();
    } else {
      defaultDate = fecha;
    }
    this.calendar = new Calendar(this.calElement, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, interactionGridPlugin],
      header: {
        left: 'prev,next',
        center: 'title',
        right: 'listMonth,listWeek'
      },
      height: 600,
      defaultView: 'listMonth',
      buttonText: {
        listMonth: 'Mes',
        listWeek: 'Semana'
      },
      selectable: true,
      // eventLimit: true,
      defaultDate,
      navLinks: true,
      businessHours: true,
      editable: true,
      locale: 'es',
      firstDay: 1,
      events: eventos,
      dateClick: (date) => { },
      eventClick: (event) => {
        let id = event.event._def.extendedProps.id_evento;
        let evento = this.eventosOriginal.find(x => x.id == id);
        const opciones = {
          size: 'ms',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true
        };
        this.modalE = this._modal.open(this.eventoModal, opciones);
        this.crearFormulario(evento);
      },
      eventDrop: () => { }
    });
    this.calendar.render();
  }

  seleccionarEventos(tipo: any) {
    this.events = this.eventsFull;
    let eventos = []
    if (tipo != 'TODOS') {
      eventos = [];
      for (let x = 0; x < this.events.length; x++) {
        const evento = this.events[x];
        if (tipo == 'LI-LN-LD') {
          if (evento.tipo == 'LI' || evento.tipo == 'LN' || evento.tipo == 'LD') {
            eventos.push(evento);
          }
        } else if (tipo == 'VUELOS') {
          if (evento.vuelo != '') {
            eventos.push(evento);
          }
        } else if (evento.tipo == tipo) {
          eventos.push(evento);
        }
      }
    } else {
      eventos = this.eventsFull;
    }
    let fecha = this.calendar.getDate();
    this.calendar.destroy();
    this.montarCalendario(eventos, fecha);
  }

  enviar() {
    this._api.editarDatosEvento(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento editado", () => {
            let indexOri = this.eventosOriginal.findIndex(x => x.id == result.buscarEvento.id);
            let indexEv = this.events.findIndex(x => x.id_evento == result.buscarEvento.id);
            if (indexOri != -1 && indexEv != -1) {
              this.events.splice(indexEv, 1);
              this.eventosOriginal.splice(indexOri, 1);
              let objeto = this.transformarEvento(result.buscarEvento);
              this.events.push(objeto);
              this.eventosOriginal.push(result.buscarEvento);
              this.eventsFull = this.events;
              let fecha = this.calendar.getDate();
              this.calendar.destroy();
              this.montarCalendario(this.eventsFull, fecha);
              this.modalE.close();
            }
          });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  eliminarEvento() {
    this._api.eliminarDatosEvento(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento eliminado", () => {
            let indexOri = this.eventosOriginal.findIndex(x => x.id == result.buscarEvento.id);
            let indexEv = this.events.findIndex(x => x.id_evento == result.buscarEvento.id);
            if (indexOri != -1 && indexEv != -1) {
              this.events.splice(indexEv, 1);
              this.eventosOriginal.splice(indexOri, 1);
              let fecha = this.calendar.getDate();
              this.calendar.destroy();
              this.montarCalendario(this.eventsFull, fecha);
              this.modalE.close();
            }
          });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  // Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

}
