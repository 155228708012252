import { Component, OnInit, ViewChild } from '@angular/core';

// Full Calendar
import interactionGridPlugin from "@fullcalendar/interaction";
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

import { ApiService } from 'src/app/providers/api.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { createPopper } from '@popperjs/core';
import { ModalManager } from 'ngb-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SwalService } from 'src/app/providers/swal.service';

@Component({
  selector: 'app-calendario-vuelo',
  templateUrl: './calendario-vuelo.component.html',
  styleUrls: ['../dashboard.component.scss', './calendario.component.scss']
})
export class CalendarioVueloComponent implements OnInit {

  calElement: any;
  tooltip: any;
  events = [];
  eventosOriginal = [];
  eventsFull = [];
  options: any;
  calendar: Calendar;
  textoPopper: any = '';
  fechaSelecCrear: any;
  @ViewChild('modalEvento') eventoModal: any;
  @ViewChild('modalCrearEvento') crearEventoModal: any;
  private modalE: any;
  private modalC: any;
  eventoForm: FormGroup;


  constructor(
    private _formB: FormBuilder,
    private _modal: ModalManager,
    private _api: ApiService,
    public _dashboard: DashboardService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.crearFormulario();
    this._api.getEventosServicio().subscribe({
      next: (eventos) => {
        if (eventos.status) {
          this.cargarEventos(eventos.eventos);
          this.eventosOriginal = eventos.eventos;
        }
        this.crearCalendario();
      },
      error: (error) => {
        this.loading = false;
      }
    });
  }

  cargarEventos(eventos: any) {
    try {
      for (const evento of eventos) {
        let objeto = this.transformarEvento(evento);
        this.events.push(objeto);
      };
    } catch (error) {
      console.log(error);
    }
    this.eventsFull = this.events;
  }

  transformarEvento(evento: any) {
    let start = this._dashboard.transformar_fecha(evento.fecha_inicio, evento.hora_inicio);
    let end = this._dashboard.transformar_fecha(evento.fecha_final, evento.hora_final);
    let title = '';
    if (evento.vuelo != '') {
      title = `${evento.trayecto_inicio}-${evento.trayecto_final}`
    } else {
      title = `${evento.codigo}`
    }
    let objeto = {
      title,
      start,
      end,
      color: evento.color,
      description: title,
      textColor: '#fff',
      allDay: false,
      tipo: evento.codigo,
      id_evento: evento.id,
      vuelo: evento.vuelo
    };
    return objeto;
  }

  crearFormulario(evento?: any) {
    let fInicio = evento != undefined ? evento.fecha_inicio.split('/') : this.fechaSelecCrear;
    let fFinal = evento != undefined ? evento.fecha_final.split('/') : this.fechaSelecCrear;

    this.eventoForm = this._formB.group({
      id: [evento != undefined ? evento.id : ''],
      cod_fun: [evento != undefined ? evento.cod_fun : '', [Validators.maxLength(3)]],
      codigo: [evento != undefined ? evento.codigo : '', [Validators.required, Validators.maxLength(3)]],
      extra: [evento != undefined ? evento.extra : '', [Validators.maxLength(4)]],
      fecha_final: [evento != undefined ? `${fFinal[2]}-${fFinal[1]}-${fFinal[0]}` : this.fechaSelecCrear, [Validators.required]],
      fecha_inicio: [evento != undefined ? `${fInicio[2]}-${fInicio[1]}-${fInicio[0]}` : this.fechaSelecCrear, [Validators.required]],
      hora_final: [evento != undefined ? evento.hora_final : '', [Validators.required, Validators.maxLength(5)]],
      hora_inicio: [evento != undefined ? evento.hora_inicio : '', [Validators.required, Validators.maxLength(5)]],
      trayecto_final: [evento != undefined ? evento.trayecto_final : '', [Validators.required, Validators.maxLength(3)]],
      trayecto_inicio: [evento != undefined ? evento.trayecto_inicio : '', [Validators.required, Validators.maxLength(3)]],
      vuelo: [evento != undefined ? evento.vuelo : '', [Validators.maxLength(10)]],
      color: [evento != undefined ? evento.color : '',],
      charter: [evento != undefined ? evento.charter : 'No'],
      alerta: [evento != undefined ? evento.alerta : 'No'],
      nacional_internacional: [evento != undefined ? evento.nacional_internacional : '',],
    });
    this.eventoForm.controls.fecha_inicio.valueChanges.subscribe(() => {
      if (this.eventoForm.controls.fecha_inicio.value > this.eventoForm.controls.fecha_final.value) {
        this.eventoForm.controls.fecha_inicio.setValue(this.eventoForm.controls.fecha_final.value);
      }
    });
  }

  crearCalendario() {
    this.calElement = document.getElementById('calendar');
    this.tooltip = document.getElementById('tooltip');
    this.montarCalendario(this.events);
    this.loading = false;
  }

  montarCalendario(eventos: any, fecha?: any) {
    let defaultDate;
    if (!fecha) {
      defaultDate = new Date();
    } else {
      defaultDate = fecha;
    }
    this.calendar = new Calendar(this.calElement, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, interactionGridPlugin],
      header: {
        left: 'today,prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      height: 600,      
      selectable: true,
      eventLimit: true,
      buttonText: {
        today: 'Hoy',
        month: 'Mes',
        week: 'Sem',
        day: 'Día'
      },
      defaultDate,
      navLinks: true,
      businessHours: true,
      editable: false,
      locale: 'es',
      firstDay: 1,
      displayEventTime: false,
      events: eventos,
      eventMouseEnter: (info) => {
        this.textoPopper = info.event.title;
        createPopper(info.el, this.tooltip, {
          placement: 'top'
        });
      },
      eventMouseLeave: (info) => {
        this.textoPopper = '';
      },
      dateClick: (date) => {
        const opciones = {
          size: 'ms',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true
        };
        this.fechaSelecCrear = date.dateStr;
        this.modalC = this._modal.open(this.crearEventoModal, opciones);
        this.crearFormulario();
      },
      eventClick: (event) => {
        let id = event.event._def.extendedProps.id_evento;
        let evento = this.eventosOriginal.find(x => x.id == id);
        const opciones = {
          size: 'ms',
          hideCloseButton: false,
          centered: true,
          backdrop: true,
          animation: true,
          keyboard: false,
          closeOnOutsideClick: true
        };
        this.modalE = this._modal.open(this.eventoModal, opciones);
        this.crearFormulario(evento);
      },
      eventDrop: (calEvent) => { }
    });
    this.calendar.render();
  }

  seleccionarEventos(tipo: any) {
    this.events = this.eventsFull;
    let eventos = []
    if (tipo != 'TODOS') {
      eventos = [];
      for (let x = 0; x < this.events.length; x++) {
        const evento = this.events[x];
        if (tipo == 'LI-LN-LD') {
          if (evento.tipo == 'LI' || evento.tipo == 'LN' || evento.tipo == 'LD') {
            eventos.push(evento);
          }
        } else if (tipo == 'VUELOS') {
          if (evento.vuelo != '') {
            eventos.push(evento);
          }
        } else if (evento.tipo == tipo) {
          eventos.push(evento);
        }
      }
    } else {
      eventos = this.eventsFull;
    }
    let fecha = this.calendar.getDate();
    this.calendar.destroy();
    this.montarCalendario(eventos, fecha);
  }

  enviar() {
    this._api.editarDatosEventoServicio(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento editado", () => {
            let indexOri = this.eventosOriginal.findIndex(x => x.id == result.buscarEvento.id);
            let indexEv = this.events.findIndex(x => x.id_evento == result.buscarEvento.id);
            if (indexOri != -1 && indexEv != -1) {
              this.events.splice(indexEv, 1);
              this.eventosOriginal.splice(indexOri, 1);
              let objeto = this.transformarEvento(result.buscarEvento);
              this.events.push(objeto);
              this.eventosOriginal.push(result.buscarEvento);
              this.eventsFull = this.events;
              let fecha = this.calendar.getDate();
              this.calendar.destroy();
              this.montarCalendario(this.eventsFull, fecha);
              this.modalE.close();
            }
          });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  crearEvento() {
    this._api.agregarDatosEvento(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento guardado", () => {
            let objeto = this.transformarEvento(result.eventoServicioFinal);
            this.events.push(objeto);
            this.eventosOriginal.push(result.eventoServicioFinal);
            this.eventsFull = this.events;
            let fecha = this.calendar.getDate();
            this.calendar.destroy();
            this.montarCalendario(this.eventsFull, fecha);
            this.modalC.close();
          });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  eliminarEvento() {
    this._api.eliminarDatosEventoServicio(this.eventoForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Evento eliminado", () => {
            let indexOri = this.eventosOriginal.findIndex(x => x.id == result.buscarEventoServicio.id);
            let indexEv = this.events.findIndex(x => x.id_evento == result.buscarEventoServicio.id);
            if (indexOri != -1 && indexEv != -1) {
              this.events.splice(indexEv, 1);
              this.eventosOriginal.splice(indexOri, 1);
              let fecha = this.calendar.getDate();
              this.calendar.destroy();
              this.montarCalendario(this.eventsFull, fecha);
              this.modalE.close();
            }
          });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  // Dinámico

  public loading: boolean = false;

  volver($e: any) {
    if ($e){
    $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
}
}