import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/providers/auth.service';
import { StorageService } from 'src/app/providers/storage.service';
import { SwalService } from 'src/app/providers/swal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  auth2: any;
  loginForm: FormGroup;
  submitted = false;
  passVer = false;
  mensaje: string;
  today = new Date();

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _auth: AuthService,
    private _storage: StorageService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
  }

  crearFormulario() {
    this.loginForm = this._fb.group({
      email: [this._storage.getEmail(), [Validators.email, Validators.required]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      remember: [this._storage.getRemember()]
    });
  }

  passEye() {
    this.passVer = !this.passVer;
  }

  login() {
    if (this.loginForm.get('remember').value) {
      this._storage.setEmail(this.loginForm.get('email').value);
    } else {
      this._storage.removeEmail();
    }
    this._auth.login(this.loginForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this.correctLogin(result.token);
          this._swal.launchCorrectSwal("¡Despegando!", () => {
            this._router.navigate(['/sepla']);
          });
        } else {
          this._swal.launchErrorSwal(result.error, () => { });
        }
      },
      error: (error) => {
        this._swal.launchErrorSwal("No tienes conexión, contacta con administración si el problema persiste", () => { });
      }
    });
  }

  private correctLogin(newToken) {
    this._storage.setCurrentSessionToken(newToken);
  }

}
