<div class="container-fluid">

    <div class="row d-flex justify-content-center">
        <div class="col-12 col-md-8 col-lg-4">

            <fieldset>

                <legend>
                    Cambiar Contraseña
                </legend>

                <form ngNativeValidate [formGroup]="forgotForm" (ngSubmit)="forgot()" novalidate="novalidate">
                    <div class="row">
                        <input class="form-control formInput" type="text" placeholder="Correo Electrónico"
                            formControlName="email">
                    </div>
                    <div class="row btnRow d-flex justify-content-end">
                        <button [ngClass]="{'btnBack': forgotForm.valid, 'btnEnviarDisabled':!forgotForm.valid}"
                            type="submit" [disabled]="!forgotForm.valid">Cambiar Contraseña</button>
                    </div>
                </form>

            </fieldset>

        </div>
    </div>

</div>