export class Conflicto {
    constructor(
        public id: number,
        public evento_programado1: any,
        public evento_programado2: any,
        public id_uduario: number,
        public fecha: any
    ) { }
}

export class ConflictoEditar {
    constructor(
        public id_conflicto: number,
        public seleccionado: string
    ) { }
}

export class MiniEvento {
    constructor(
        public id: number,
        public evento: any,
        public esSA: boolean
    ) { }
}