import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUser'
})
export class FilterUserPipe implements PipeTransform {

  transform(value: any, arg: any[]): any {
    if (arg[0]==''){
      return value;
    }
    let resultPosts = [];
    if (value != undefined) {
      for (const lista of value) {
        switch (arg[1]) {
          case 'email':
            if (lista.email.toLowerCase().indexOf(arg[0].toLowerCase()) > -1) {
              resultPosts.push(lista);
            }
            break;
          case 'nombre':
            if (lista.nombre.toLowerCase().indexOf(arg[0].toLowerCase()) > -1) {
              resultPosts.push(lista);
            }
            break;
          case 'apellido1':
            if (lista.apellido1.toLowerCase().indexOf(arg[0].toLowerCase()) > -1) {
              resultPosts.push(lista);
            }
            break;
          case 'apellido2':
            if (lista.apellido2.toLowerCase().indexOf(arg[0].toLowerCase()) > -1) {
              resultPosts.push(lista);
            }
            break;
          default:
            break;
        }
      }
      return resultPosts;
    }
    return value;
  }

}
