<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-clipboard-data-fill iconoLegend"></i> Resumen
                    <select [(ngModel)]="anioSeleccionado" class="selectorAnio" (change)="cambiarAnio()"
                        [disabled]="cargando">
                        <option *ngFor="let anio of aniosSeleccionables; index as i" class="selectorAnioOption"
                            [ngValue]="anio">{{anio}}</option>
                    </select>
                    <i class="bi bi-caret-down-fill iconoSelector"></i>
                </legend>

                <div *ngIf="cargando" class="loadingScreen d-flex justify-content-center align-items-center">
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Cargando...</span>
                    </div>
                </div>

                <div *ngIf="!cargando" class="row mesesCargadosRow d-flex justify-content-between">
                    <div *ngFor="let item of mesesProcesados; let i = index;"
                        class="colMesCargado col-2 col-md-1 d-flex justify-content-center">
                        <div class="badge nombreMesCargado" style="cursor:pointer;" [ngClass]="item"
                            (click)="eliminarMes(i)">
                            {{mesesNombres[i]}} <i class="bi bi-trash"></i>
                        </div>
                    </div>
                </div>

                <div *ngIf="!cargando" class="row contenidoRow">

                    <div class="colDato col-12 col-md-4">
                        <fieldset class="cardDato linkDato" [routerLink]="['/sepla/horas-vuelo', anioSeleccionado]">

                            <legend class="tituloDato">
                                <i class="bi bi-send-fill iconoTituloDato"></i> Horas Vuelo
                            </legend>

                            <p class="textoDato">
                                {{datosObj.sumaVuelos | number: '.1-2'}}

                            </p>

                        </fieldset>
                    </div>

                    <div class="colDato col-12 col-md-4">
                        <fieldset class="cardDato linkDato" [routerLink]="['/sepla/dias-libres',anioSeleccionado]">

                            <legend class="tituloDato">
                                <i class="bi bi-calendar-fill iconoTituloDato"></i> Días Libres
                            </legend>

                            <p class="textoDato">
                                {{datosObj.diasLibres}}
                            </p>

                        </fieldset>
                    </div>

                    <div class="colDato col-12 col-md-4">
                        <fieldset class="cardDato linkDato" [routerLink]="['/sepla/dias-va',anioSeleccionado]">

                            <legend class="tituloDato">
                                <i class="bi bi-sun-fill iconoTituloDato"></i> Días de Vacaciones
                            </legend>

                            <p class="textoDato">
                                {{datosObj.sumaVacaciones}}
                            </p>

                        </fieldset>
                    </div>

                    <div class="colDato col-12 col-md-6">
                        <fieldset class="cardDato">

                            <legend class="tituloDato">
                                <i class="bi bi-basket-fill iconoTituloDato"></i> Salario Anual
                            </legend>

                            <p class="textoDato">
                                {{datosObj.sumaSalario | number: '.1-2'}}€
                            </p>

                        </fieldset>
                    </div>

                    <div class="colDato col-12 col-md-6">
                        <fieldset class="cardDato">

                            <legend class="tituloDato">
                                <i class="bi bi-clock-fill iconoTituloDato"></i> Horas de Actividad
                            </legend>

                            <p class="textoDato">
                                {{datosObj.sumaActividad | number: '.1-2'}}
                                <!-- {{datosObj.sumaActividad }} -->
                            </p>

                        </fieldset>
                    </div>
                    <!-- 
                    <div class="colDato col-12 col-md-6">
                        <fieldset class="cardDato">

                            <legend class="tituloDato">
                                <i class="bi bi-bar-chart-fill iconoTituloDato"></i>
                                Proceso de Vuelo
                            </legend>

                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                        {{datosObj.procesoVuelo}}%
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="progress progress-sm mr-2">
                                        <div class="progress-bar bg-info" role="progressbar"
                                            [ngStyle]="{'width': datosObj.procesoVuelo}" aria-valuenow="50"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>
                    </div>

                    <div class="colDato col-12 col-md-6">
                        <fieldset class="cardDato">

                            <legend class="tituloDato">
                                <i class="bi bi-hourglass-split iconoTituloDato"></i>
                                Proceso de Días Libres
                            </legend>

                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                                        {{datosObj.procesoDiasLibres}}%
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="progress progress-sm mr-2">
                                        <div class="progress-bar bg-warning" role="progressbar"
                                            [ngStyle]="{'width': datosObj.procesoDiasLibres}" aria-valuenow="50"
                                            aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>

                        </fieldset>
                    </div> -->

                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>