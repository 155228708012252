import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  constructor() { }

  public launchCorrectSwal(text: string, f: () => void): void {
    Swal.fire({
      titleText: text,
      showConfirmButton: true,
      icon: "success",
      iconColor: "rgb(115, 220, 245)",
      background: "rgb(30, 90, 160)",
      color: "rgb(255, 255, 255)",
    }).then(() => {
      f();
    });
  }

  public launchErrorSwal(text: string, f: () => void): void {
    Swal.fire({
      titleText: text,
      showConfirmButton: true,
      icon: "error",
      iconColor: "rgb(230, 100, 70)",
      background: "rgb(30, 90, 160)",
      color: "rgb(255, 255, 255)",
    }).then(() => {
      f();
    });
  }

  public launchWarningSwal(text: string, f: () => void): void {
    Swal.fire({
      titleText: text,
      showConfirmButton: true,
      icon: "warning",
      iconColor: "rgb(225, 175, 25)",
      background: "rgb(30, 90, 160)",
      color: "rgb(255, 255, 255)",
    }).then(() => {
      f();
    });
  }

  public launchQuestionSwal(title: string, content: string, cbText: string, dbText: string, f: () => void): void {
    Swal.fire({
      titleText: title,
      text: content,
      showConfirmButton: true,
      confirmButtonText: cbText,
      confirmButtonColor: "rgb(115, 220, 245)",
      showDenyButton: true,
      denyButtonText: dbText,
      denyButtonColor: "rgb(230, 100, 70)",
      icon: "question",
      iconColor: "rgb(115, 220, 245)",
      background: "rgb(30, 90, 160)",
      color: "rgb(255, 255, 255)",
      timerProgressBar: true
    }).then(r => {
      if (r.isConfirmed) {
        f();
      }
    });
  }

  launchInfoSwal(titleText: string, text: string, y: () => void): void {
    Swal.fire({
      titleText,
      text,
      icon: "info",
      iconColor: "rgb(40, 100, 140)",
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: "rgb(40, 100, 140)",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      focusConfirm: false
    }).then(r => {
      if (r.isConfirmed)
        y();
    });
  }

  launchInfoSwalHTML(title: string, html: string): void {
    Swal.fire({
      title,
      html,
      icon: "info",
      iconColor: "rgb(40, 100, 140)",
      showConfirmButton: true,
      confirmButtonText: 'Aceptar',
      confirmButtonColor: "rgb(40, 100, 140)",
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      focusConfirm: false
    })
  }

  public launchImgSwal(imgUrl: string): void {
    Swal.fire({
      background: "rgb(30, 90, 160)",
      confirmButtonColor: "rgb(115, 220, 245)",
      confirmButtonText: "Ok",
      imageUrl: imgUrl,
      imageHeight: 300,
      imageAlt: 'Img'
    })
  }

}
