import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/providers/auth.service';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { StorageService } from 'src/app/providers/storage.service';
import { SwalService } from 'src/app/providers/swal.service';
import { ApiService } from '../../../providers/api.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['../dashboard.component.scss', './perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  perfilForm: FormGroup;
  niveles: any;
  paises = new Array('España', 'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antarctica', 'Antigua and Barbuda',
    'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados',
    'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana',
    'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burma', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde',
    'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia', 'Comoros', 'Congo, Democratic Republic',
    'Congo, Republic of the', 'Costa Rica', 'Cote Ivoire', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark',
    'Djibouti', 'Dominica', 'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
    'Eritrea', 'Estonia', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana',
    'Greece', 'Greenland', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong',
    'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan',
    'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Korea, North', 'Korea, South', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia',
    'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macedonia', 'Madagascar',
    'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia',
    'Moldova', 'Mongolia', 'Morocco', 'Monaco', 'Mozambique', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand',
    'Nicaragua', 'Niger', 'Nigeria', 'Norway', 'Oman', 'Pakistan', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru',
    'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Samoa', 'San Marino', ' Sao Tome',
    'Saudi Arabia', 'Senegal', 'Serbia and Montenegro', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia',
    'Solomon Islands', 'Somalia', 'South Africa', 'Sri Lanka', 'Sudan', 'Suriname', 'Swaziland', 'Sweden',
    'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tonga', 'Trinidad and Tobago',
    'Tunisia', 'Turkey', 'Turkmenistan', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States',
    'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe');

  bases = new Array({ nombre: 'Madrid', value: 'Madrid' }, { nombre: 'Las Palmas', value: 'Palmas' }, { nombre: 'Palma de Mallorca', value: 'Mallorca' }, { nombre: 'Barcelona', value: 'Barcelona' }, { nombre: 'Tenerife Norte', value: 'Tenerife' }, { nombre: 'Tenerife Sur', value: 'Granadilla de Abona, Tenerife' }, { nombre: 'Málaga', value: 'Málaga' }, { nombre: 'Valencia', value: 'Valencia' });
  flotas = new Array('B787', 'B737');

  constructor(
    private _form: FormBuilder,
    public _dash: DashboardService,
    private _store: StorageService,
    private _auth: AuthService,
    private _api: ApiService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.crearFormulario();
    this._auth.getNiveles().subscribe({
      next: (result) => {
        if (result.status) {
          this.extraerNiveles(result.niveles);
        }
      },
      error: (error) => {
      }
    });
    this._auth.getPiloto().subscribe({
      next: (result) => {
        if (result.status) {
          this.crearFormulario(result.piloto)
        } else {
          this.crearFormulario();
        }
      },
      error: (error) => {
      }
    });
  }

  extraerNiveles(niveles) {
    this.niveles = niveles;
  }

  crearFormulario(piloto?) {
    this.perfilForm = this._form.group({
      email: [piloto ? piloto.usuario.email : ''],
      nombre: [piloto ? piloto.nombre : '', [Validators.required, Validators.maxLength(50)]],
      apellido1: [piloto ? piloto.apellido1 : '', [Validators.required, Validators.maxLength(50)]],
      apellido2: [piloto ? piloto.apellido2 : '', [Validators.required, Validators.maxLength(50)]],
      nivel: [piloto ? piloto.nivel.id : '', [Validators.required]],
      direccion: [piloto ? piloto.direccion : ''],
      ciudad: [piloto ? piloto.ciudad : ''],
      reduccion_jornada: [piloto ? piloto.reduccion_jornada : '', [Validators.required]],
      pais: [piloto ? piloto.pais : '', [Validators.required]],
      codigo_postal: [piloto ? piloto.codigo_postal : ''],
      flota: [piloto ? piloto.flota : '', [Validators.required]],
      categoria: [piloto ? piloto.categoria : '', [Validators.required]],
      base: [piloto ? piloto.base : '', [Validators.required]],
      usuarioCasillero: [piloto ? piloto.usuario.usuarioCasillero : ''],
      passwordCasillero: [piloto ? piloto.usuario.passwordCasillero : ''],
    });
  }

  enviar() {
    this._auth.editPiloto(this.perfilForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Datos perfil guardados", () => { });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
    this._api.setUserCasillero(this.perfilForm.value).subscribe({
      next: (result) => {
        if (result.status) {
          this._swal.launchCorrectSwal("Datos casillero guardados", () => { });
        } else {
          this._swal.launchErrorSwal("Algo ha fallado", () => { });
        }
      },
      error: (error) => {
      }
    });
  }

  // Dinámico

  volver($e: any) {
    if ($e) {
      $e.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

}
