import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardService } from 'src/app/providers/dashboard.service';
import { StorageService } from 'src/app/providers/storage.service';
import { SwalService } from 'src/app/providers/swal.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public usuario = { nombre: '', apellido1: '', apellido2: '' };
  public piloto: any;
  public imagen: any;
  public checkUser = true;
  public checkAdmin = false;
  public esLogout: boolean;

  @Output() newItemEvent = new EventEmitter();
  @Input() class: Boolean = true;

  constructor(
    public _dash: DashboardService,
    private _storage: StorageService,
    private _swal: SwalService
  ) { }

  ngOnInit(): void {
    this.esLogout = false;
    this.onCheckUser();
  }

  sidebar(status: boolean) {
    if (status) {
      this.newItemEvent.emit('');
    } else {
      this.newItemEvent.emit('sb-sidenav-toggled');
    }
    this.class = !this.class;
  }

  onCheckUser() {
    if (this._storage.isAuthenticated()) {
      let token = this._storage.getCurrentToken();
      this._storage.checkUser(token).subscribe({
        next: (result) => {
          this.extraerUsuario(result);
        },
        error: (error) => {
          this._swal.launchErrorSwal("Algo ha salido mal", () => { })
        }
      });
    } else {
      this.checkUser = false;
    }
  }

  extraerUsuario(result: any) {
    if (result.status) {
      this.usuario = result.user;
      this.esAdmin(result.user.tipo);
      this.checkUser = true;
    } else {
      this.checkUser = false;
      this._storage.logout();
    }
  }

  esAdmin(admin: any) {
    if (admin == 'admin') {
      this.checkAdmin = true;
    } else {
      this.checkAdmin = false;
    }
  }

  logout() {
    this._swal.launchCorrectSwal("¡Aterrizando!", () => { this._storage.logout(); });
  }

}
