<div #i class="containerCalendario container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-calendar iconoLegend"></i> Calendario Vuelos
                </legend>

                <div *ngIf="loading" class="row loadingRow">
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <div class="spinner-border text-info" role="status">
                            <span class="sr-only">Cargando...</span>
                        </div>
                    </div>
                </div>

                <div class="rowCalendario row">
                    <div class="col-12">
                        <div id='calendar' class=""></div>
                        <div [ngClass]="{'mitooltip' : textoPopper != ''}" id="tooltip" role="tooltip">
                            {{textoPopper}}
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>

<modal #modalEvento>
    <modal-header>
        <p class="tituloModal">
            <i class="bi bi-calendar-range-fill iconoTitulo"></i> Editar Evento
        </p>
    </modal-header>
    <modal-content>
        <form [formGroup]="eventoForm">
            <div class="alert alert-danger"> Fechas / Horas en UTC</div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">cod_fun</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="cod_fun" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Código</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="codigo" type="text">
                </div>
            </div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">extra</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="extra" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha inicio</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_inicio" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora inicio</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_inicio" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Origen</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_inicio" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha final</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_final" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora final</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_final" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Destino</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_final" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Nº Vuelo</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo" type="text">
                </div>
            </div>
            <div class="row btnRow">
                <div class="col-12 d-flex justify-content-between">
                    <button type="button" class="btnEliminar" (click)="eliminarEvento()">
                        <i class="bi bi-trash-fill"></i>
                    </button>
                    <button class="btnEnviar" (click)="enviar()">
                        <i class="bi bi-calendar-range-fill iconoBoton"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    </modal-content>
</modal>

<modal #modalCrearEvento>
    <modal-header>
        <p class="tituloModal">
            <i class="bi bi-calendar-plus-fill iconoTitulo"></i> Crear Evento
        </p>
    </modal-header>
    <modal-content>
        <form [formGroup]="eventoForm">
            <div class="alert alert-danger"> Fechas / Horas en UTC</div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">cod_fun</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="cod_fun" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Código</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="codigo" type="text">
                </div>
            </div>
            <!-- <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">extra</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="extra" type="text">
                </div>
            </div> -->
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha inicio</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_inicio" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora inicio</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_inicio" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Origen</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_inicio" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Fecha final</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="fecha_final" type="date">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Hora final</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="hora_final" type="time">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Destino</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="trayecto_final" type="text">
                </div>
            </div>
            <div class="row formRow">
                <div class="col-4 d-flex justify-content-start align-items-center flex-wrap">
                    <p class="tituloInput">Nº Vuelo</p>
                </div>
                <div class="col-8 d-flex justify-content-end align-items-center flex-wrap">
                    <input class="inputModal" formControlName="vuelo" type="text">
                </div>
            </div>
            <div class="row btnRow">
                <div class="col-12 d-flex justify-content-end">
                    <button class="{{!eventoForm.valid ? 'btnEnviarDisabled' : 'btnEnviar'}}" (click)="crearEvento()" [disabled]="!eventoForm.valid">
                        <i class="bi bi-calendar-plus-fill iconoBoton"></i>
                        Guardar
                    </button>
                </div>
            </div>
        </form>
    </modal-content>
</modal>