<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <i class="bi bi-cloud-fog2-fill iconoLegend"></i>
                    Perfiles
                </legend>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="card shadow mb-4">
                            <div class="card-body row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4 m-5">
                                        <div
                                            class="card-header titulo py-3 align-items-center justify-content-center text-left">
                                            <h6 class="m-0 font-weight-bold">SUBIR PERFILES</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-7 col-md-7">
                                                    <h6>Introduce los datos</h6>
                                                </div>
                                                <div class="col-lg-3 col-md-3">
                                                    <button type="button" class="btn btn-outline-secondary"
                                                        (click)="leerDatos()">
                                                        Leer Datos</button>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <form class="rounded" [formGroup]="textAreaForm">
                                                        <textarea type="text" id="texto"
                                                            class="lg-textarea-auto form-control" cols="10" rows="10"
                                                            mdbInput name="texto" formControlName="texto"></textarea>
                                                        Flota:
                                                        <select class="form-control" name="flota"
                                                            formControlName="flota">
                                                            <option value="B787">B787</option>
                                                            <option value="B737">B737</option>
                                                        </select>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body" *ngIf="datosTextArea.length > 0">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <h6>CANTIDAD DE EVENTOS: {{datosTextArea.length}}</h6>
                                                </div>
                                                <div class="col-md-12">
                                                    <table class="table table-responsive-lg p-1">
                                                        <thead class="thead-light">
                                                            <tr>
                                                                <th>Trayecto</th>
                                                                <th>Tipo</th>
                                                                <th>Tiempo</th>
                                                                <th>Observacion</th>
                                                                <th>Flota</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="background-color: #fff">
                                                            <tr
                                                                *ngFor="let i = index;let text of datosTextArea | paginate: { itemsPerPage: 10, currentPage: pageActual }">
                                                                <td>{{text.trayecto}}</td>
                                                                <td>{{text.tipo}}</td>
                                                                <td>{{text.tiempo}}</td>
                                                                <td>{{text.observacion}}</td>
                                                                <td>{{text.flota}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente"
                                                        class="text-left" (pageChange)="pageActual = $event">
                                                    </pagination-controls>
                                                </div>
                                                <div class="col-lg-1 col-md-1"></div>
                                                <div class="col-lg-10 col-md-10">
                                                    <button type="submit" class="btn botonSeplaAct mt-2"
                                                        (click)="enviarTextArea(1)"
                                                        [disabled]="!textAreaForm.valid">Enviar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7">
                                    <span *ngIf="!mostrarTodo"><button type="button" class="btn botonSeplaAct"
                                            (click)="mostrar()">Mostrar todo</button></span>
                                    <span *ngIf="mostrarTodo"><button type="button" class="btn botonSeplaAct col-6"
                                            (click)="mostrar()">Mostrar menos</button></span>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                    <div *ngIf="mostrarTodo" class="btn botonSeplaAct">BUSCADOR</div>
                                    <input type="text" class="col-lg-6 form-control m-4" name="filsterPost"
                                        placeholder="Buscar por trayecto..." [(ngModel)]="filterPost">
                                </div>
                                <div class="col-xl-11 col-lg-11 col-md-11 col-12" *ngIf="!mostrarTodo">
                                    <div class="row m-2"
                                        *ngFor="let elemento of lista | paginate: {itemsPerPage: 4, currentPage: pageActual}">
                                        <div
                                            class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> FLOTA:</b> {{elemento.flota}}
                                        </div>
                                        <div
                                            class="col-md-8 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> TRAYECTO:</b> {{elemento.trayecto}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <b> TIPO:</b> {{elemento.tipo}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> FECHA ACTUALIZACIÓN:</b> {{elemento.fecha}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> TIEMPO DE VUELO:</b> {{elemento.tiempo}}
                                        </div>
                                    </div>
                                    <pagination-controls previousLabel="Anterior" nextLabel="Siguiente"
                                        *ngIf="lista !== undefined && !mostrarTodo" class="text-left"
                                        (pageChange)="pageActual = $event"></pagination-controls>
                                </div>
                                <div class="col-xl-11 col-lg-11 col-md-11 col-12" *ngIf="mostrarTodo">
                                    <div class="row m-2"
                                        *ngFor="let elemento of lista| filterPerfilesPipe:[filterPost]">
                                        <div
                                            class="col-md-4 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> FLOTA:</b> {{elemento.flota}}
                                        </div>
                                        <div
                                            class="col-md-8 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> TRAYECTO:</b> {{elemento.trayecto}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoUno list-group-item list-group-item-secondary">
                                            <b> TIPO:</b> {{elemento.tipo}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> FECHA ACTUALIZACIÓN:</b> {{elemento.fecha}}
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 fondoDos list-group-item list-group-item-secondary">
                                            <b> TIEMPO DE VUELO:</b> {{elemento.tiempo}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>