<div #i class="container-fluid">
    <div class="row">
        <div class="col-12">
            <fieldset>

                <legend>
                    <div>
                        <i class="bi bi-file-earmark-plus iconoLegend"></i> Añadir Eventos
                    </div>
                </legend>

                <div class="row btnRow">
                    <div class="col-12 d-flex justify-content-center flex-wrap">
                        <button class="{{mailSelec ? 'btnSeleccionado' : 'btnSeleccionar'}}" (click)="selecMail();">
                            <i class="bi bi-mailbox2 iconoSeleccionar"></i>
                            Programado (Mail)
                        </button>
                        <button class="{{pdfSelec ? 'btnSeleccionado' : 'btnSeleccionar'}}" (click)="selecPdf();">
                            <i class="bi bi-filetype-pdf iconoSeleccionar"></i>
                            Programado (PDF)
                        </button>
                        <button class="{{webSelec ? 'btnSeleccionado' : 'btnSeleccionar'}}" (click)="selecWeb();">
                            <i class="bi bi-globe iconoSeleccionar"></i>
                            Realizado (Web)
                        </button>
                    </div>
                </div>

                <div *ngIf="mailSelec" class="row d-flex justify-content-center">
                    <fieldset class="fieldsetSecondary col-11">

                        <legend class="legendSecondary">
                            <i class="bi bi-mailbox2 iconoLegendSecondary"></i> Programado
                        </legend>

                        <div class="row">
                            <div class="col-6 d-flex justify-content-start align-items-center">
                                <p class="textoTipo">
                                    Introduce los datos desde el correo electrónico:
                                </p>
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                <button class="btnSeleccionar btnEjemplo" (click)="ejemploTextArea()">
                                    <i class="bi bi-question-lg iconoEjemplo"></i>
                                    Ejemplo
                                </button>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <form class="rounded" [formGroup]="textAreaForm">
                                            <textarea type="text" id="texto" class="lg-textarea-auto form-control"
                                                cols="10" rows="10" mdbInput name="texto"
                                                formControlName="texto"></textarea>
                                        </form>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-end">
                                        <button class="btnSeleccionar btnCancelar" (click)="selecCancelar();">
                                            <i class="bi bi-x iconoCancelar"></i>
                                            Cancelar
                                        </button>
                                        <button class="btnSeleccionar btnLeer" (click)="leerDatos()">
                                            <i class="bi bi-eyeglasses"></i>
                                            Leer Datos
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="loadingMail" class="row loadingRow">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="spinner-border text-info" role="status">
                                    <span class="sr-only">Cargando...</span>
                                </div>
                            </div>
                        </div>

                        <div id="eventosM" class="row" *ngIf="datosTextArea.length > 0 && !loadingMail">
                            <div class="col-12">
                                <h6>CANTIDAD DE EVENTOS: {{datosTextArea.length}}</h6>
                            </div>
                            <div class="col-12">
                                <table class="table table-responsive-lg p-1">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Código</th>
                                            <th>Código Fun</th>
                                            <th>Fecha Inicio</th>
                                            <th>Hora Inicio</th>
                                            <th>Fecha Final</th>
                                            <th>Hora Final</th>
                                            <th>Inicio</th>
                                            <th>Final</th>
                                            <th>Extra</th>
                                            <th>Vuelo</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background-color: #fff">
                                        <tr
                                            *ngFor="let i = index;let text of datosTextArea | paginate: { itemsPerPage: 10, currentPage: pageActual }">
                                            <td>{{text.codigo}}</td>
                                            <td>{{text.cod_fun}}</td>
                                            <td>{{text.fecha_inicio}}</td>
                                            <td>{{text.hora_inicio}}</td>
                                            <td>{{text.fecha_final}}</td>
                                            <td>{{text.hora_final}}</td>
                                            <td>{{text.trayecto_inicio}}</td>
                                            <td>{{text.trayecto_final}}</td>
                                            <td>{{text.extra}}</td>
                                            <td>{{text.vuelo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" class="text-left"
                                    (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit" class="btnSeleccionar btnEnvio" (click)="enviarTextArea(1)"
                                    [disabled]="!textAreaForm.valid">
                                    <i class="bi bi-arrow-up-short"></i>
                                    Enviar
                                </button>
                            </div>
                        </div>

                    </fieldset>
                </div>

                <div *ngIf="webSelec" class="row d-flex justify-content-center">
                    <fieldset class="fieldsetSecondary col-11">

                        <legend class="legendSecondary">
                            <i class="bi bi-globe iconoLegendSecondary"></i> Realizado
                        </legend>

                        <div class="row">
                            <div class="col-6 d-flex justify-content-start align-items-center">
                                <p class="textoTipo">
                                    Introduce los datos desde la web:
                                </p>
                            </div>
                            <div class="col-6 d-flex justify-content-end">
                                <button class="btnSeleccionar btnEjemplo" (click)="ejemploTextArea2()">
                                    <i class="bi bi-question-lg iconoEjemplo"></i>
                                    Ejemplo
                                </button>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <form class="rounded" [formGroup]="textAreaForm2">
                                            <textarea type="text" id="texto2"
                                                class="lg-textarea-auto form-control textoArea" cols="10" rows="10"
                                                mdbInput name="texto2" formControlName="texto2"></textarea>
                                        </form>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-end">
                                        <button class="btnSeleccionar btnCancelar" (click)="selecCancelar();">
                                            <i class="bi bi-x iconoCancelar"></i>
                                            Cancelar
                                        </button>
                                        <button class="btnSeleccionar btnLeer" (click)="leerDatos2()">
                                            <i class="bi bi-eyeglasses"></i>
                                            Leer Datos
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="loadingWeb" class="row loadingRow">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="spinner-border text-info" role="status">
                                    <span class="sr-only">Cargando...</span>
                                </div>
                            </div>
                        </div>

                        <div id="eventosW" class="row" *ngIf="datosTextArea2.length > 0 && !loadingWeb">
                            <div class="col-12">
                                <h6>CANTIDAD DE EVENTOS: {{datosTextArea2.length}}</h6>
                            </div>
                            <div class="col-12">
                                <table class="table table-responsive-lg p-1">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Código</th>
                                            <th>Código Fun</th>
                                            <th>Fecha Inicio</th>
                                            <th>Hora Inicio</th>
                                            <th>Fecha Final</th>
                                            <th>Hora Final</th>
                                            <th>Inicio</th>
                                            <th>Final</th>
                                            <th>Extra</th>
                                            <th>Vuelo</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background-color: #fff">
                                        <tr
                                            *ngFor="let i = index;let text2 of datosTextArea2 | paginate: { itemsPerPage: 10, currentPage: pageActual }">
                                            <td>{{text2.codigo}}</td>
                                            <td>{{text2.cod_fun}}</td>
                                            <td>{{text2.fecha_inicio}}</td>
                                            <td>{{text2.hora_inicio}}</td>
                                            <td>{{text2.fecha_final}}</td>
                                            <td>{{text2.hora_final}}</td>
                                            <td>{{text2.trayecto_inicio}}</td>
                                            <td>{{text2.trayecto_final}}</td>
                                            <td>{{text2.extra}}</td>
                                            <td>{{text2.vuelo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" class="text-left"
                                    (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit" class="btnSeleccionar btnEnvio" (click)="enviarTextArea(2)"
                                    [disabled]="!textAreaForm2.valid">
                                    <i class="bi bi-arrow-up-short"></i>
                                    Enviar
                                </button>
                            </div>
                        </div>

                    </fieldset>
                </div>

                <!-- <div *ngIf="casilleroSelec" class="row d-flex justify-content-center">
                    <fieldset class="fieldsetSecondary col-11">

                        <legend class="legendSecondary">
                            <i class="bi bi-globe iconoLegendSecondary"></i> Casillero
                        </legend>

                        <div class="row">
                            <div class="col-6 d-flex justify-content-start align-items-center">
                                <p class="textoTipo">
                                    Introduce el mes y el año:
                                </p>
                            </div>
                            <form class="col-12 col-md-10 col-lg-8" [formGroup]="casilleroForm">
                                <div class="row formRow">
                                    <div class="col-12 col-md-6 formCol">
                                        <p class="tituloInput">
                                            <i class="bi bi-signpost iconoInput"></i> Mes
                                        </p>
                                        <select class="textoSelect" formControlName="mes">
                                            <option value="1">Enero</option>
                                            <option value="2">Febrero</option>
                                            <option value="3">Marzo</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Mayo</option>
                                            <option value="6">Junio</option>
                                            <option value="7">Julio</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Septiembre</option>
                                            <option value="10">Octubre</option>
                                            <option value="11">Noviembre</option>
                                            <option value="12">Diciembre</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6 formCol">
                                        <p class="tituloInput">
                                            <i class="bi bi-signpost iconoInput"></i> Año
                                        </p>
                                        <select class="textoSelect" formControlName="anio">
                                            <option value="2023">2023
                                            </option>
                                            <option value="2022">2022
                                            </option>
                                            <option value="2022">2021
                                            </option>
                                            <option value="2022">2020
                                            </option>
                                            <option value="2022">2019
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <button class="btnSeleccionar btnLeer" (click)="leerDatos3()">
                                <i class="bi bi-eyeglasses"></i>
                                Descargar Datos
                            </button>
                        </div>

                        <div *ngIf="loadingCasillero" class="row loadingRow">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="spinner-border text-info" role="status">
                                    <span class="sr-only">Cargando...</span>
                                </div>
                            </div>
                        </div>


                        <div id="eventosCasillero" class="row" *ngIf="datosCasillero.length > 0 && !loadinCasillero">
                            <div class="col-12">
                                <h6>CANTIDAD DE EVENTOS: {{datosCasillero.length}}</h6>
                            </div>
                            <div class="col-12">
                                <table class="table table-responsive-lg p-1">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Código</th>
                                            <th>Código Fun</th>
                                            <th>Fecha Inicio</th>
                                            <th>Hora Inicio</th>
                                            <th>Fecha Final</th>
                                            <th>Hora Final</th>
                                            <th>Inicio</th>
                                            <th>Final</th>
                                            <th>Extra</th>
                                            <th>Vuelo</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background-color: #fff">
                                        <tr
                                            *ngFor="let i = index;let text of datosCasillero | paginate: { itemsPerPage: 10, currentPage: pageActual }">
                                            <td>{{text.codigo}}</td>
                                            <td>{{text.cod_fun}}</td>
                                            <td>{{text.fecha_inicio}}</td>
                                            <td>{{text.hora_inicio}}</td>
                                            <td>{{text.fecha_final}}</td>
                                            <td>{{text.hora_final}}</td>
                                            <td>{{text.trayecto_inicio}}</td>
                                            <td>{{text.trayecto_final}}</td>
                                            <td>{{text.extra}}</td>
                                            <td>{{text.vuelo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" class="text-left"
                                    (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit" class="btnSeleccionar btnEnvio" (click)="enviarTextArea(3)"
                                    [disabled]="!casilleroForm.valid">
                                    <i class="bi bi-arrow-up-short"></i>
                                    Enviar
                                </button>
                            </div>
                        </div>

                    </fieldset>
                </div> -->

                <div *ngIf="pdfSelec" class="row d-flex justify-content-center">
                    <fieldset class="fieldsetSecondary col-11">

                        <legend class="legendSecondary">
                            <i class="bi bi-globe iconoLegendSecondary"></i> Programado PDF
                        </legend>

                        <div class="row">
                            <div class="col-6 d-flex justify-content-start align-items-center">
                                <p class="textoTipo">
                                    Introduce el mes y el año:
                                </p>
                            </div>
                            <form class="col-12 col-md-10 col-lg-8" [formGroup]="pdfForm" enctype="multipart/form-data">
                                <div class="row formRow">
                                    <div class="col-12 col-md-6 formCol">
                                        <p class="tituloInput">
                                            <i class="bi bi-signpost iconoInput"></i> Mes
                                        </p>
                                        <select class="textoSelect" formControlName="mes">
                                            <option value="1">Enero</option>
                                            <option value="2">Febrero</option>
                                            <option value="3">Marzo</option>
                                            <option value="4">Abril</option>
                                            <option value="5">Mayo</option>
                                            <option value="6">Junio</option>
                                            <option value="7">Julio</option>
                                            <option value="8">Agosto</option>
                                            <option value="9">Septiembre</option>
                                            <option value="10">Octubre</option>
                                            <option value="11">Noviembre</option>
                                            <option value="12">Diciembre</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6 formCol">
                                        <p class="tituloInput">
                                            <i class="bi bi-signpost iconoInput"></i> Año
                                        </p>
                                        <select class="textoSelect" formControlName="anio">
                                            <option *ngFor="let anio of anios" value={{anio}}>{{anio}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md-6 formCol m-4">
                                        <p class="tituloInput">
                                            <i class="bi bi-signpost iconoInput"></i> Fichero
                                        </p>
                                        <input type="file" class="custom-file-input" id="fichero"
                                            (change)="changeFichero($event.target.files[0])" style="cursor:pointer;">
                                        <label class="custom-file-label elige" for="fichero"
                                            style="cursor:pointer;">{{nombreFichero}}</label>
                                        <p *ngIf="errFichero" class="alert alert-danger m-2">{{errFichero}}</p>
                                        <p *ngIf="msjFichero" class="alert alert-success m-2">{{msjFichero}}</p>
                                    </div>
                                </div>
                            </form>
                            <button class="btnSeleccionar btnLeer" (click)="subirFichero()">
                                <i class="bi bi-eyeglasses"></i>
                                Transformar PDF
                            </button>
                        </div>

                        <div *ngIf="loadingPdf" class="row loadingRow">
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <div class="spinner-border text-info" role="status">
                                    <span class="sr-only">Cargando...</span>
                                </div>
                            </div>
                        </div>


                        <div id="eventosPdf" class="row" *ngIf="datosPdf.length > 0 && !loadinPdf">
                            <div class="col-12">
                                <h6>CANTIDAD DE EVENTOS: {{datosPdf.length}}</h6>
                            </div>
                            <div class="col-12">
                                <table class="table table-responsive-lg p-1">
                                    <thead class="thead-light">
                                        <tr>
                                            <th>Código</th>
                                            <th>Código Fun</th>
                                            <th>Fecha Inicio</th>
                                            <th>Hora Inicio</th>
                                            <th>Fecha Final</th>
                                            <th>Hora Final</th>
                                            <th>Inicio</th>
                                            <th>Final</th>
                                            <th>Extra</th>
                                            <th>Vuelo</th>
                                        </tr>
                                    </thead>
                                    <tbody style="background-color: #fff">
                                        <tr
                                            *ngFor="let i = index;let text of datosPdf | paginate: { itemsPerPage: 10, currentPage: pageActual }">
                                            <td>{{text.codigo}}</td>
                                            <td>{{text.cod_fun}}</td>
                                            <td>{{text.fecha_inicio}}</td>
                                            <td>{{text.hora_inicio}}</td>
                                            <td>{{text.fecha_final}}</td>
                                            <td>{{text.hora_final}}</td>
                                            <td>{{text.trayecto_inicio}}</td>
                                            <td>{{text.trayecto_final}}</td>
                                            <td>{{text.extra}}</td>
                                            <td>{{text.vuelo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" class="text-left"
                                    (pageChange)="pageActual = $event"></pagination-controls>
                            </div>
                            <div class="col-12 d-flex justify-content-end">
                                <button type="submit" class="btnSeleccionar btnEnvio" (click)="enviarTextArea(4)"
                                    [disabled]="!pdfForm.valid">
                                    <i class="bi bi-arrow-up-short"></i>
                                    Enviar
                                </button>
                            </div>
                        </div>

                    </fieldset>
                </div>

                <div class="row extraRow">
                    <div class="col-12 d-flex justify-content-end">
                        <button type="button" class="btnBack" (click)="volver(i);">
                            Subir
                        </button>
                    </div>
                </div>

            </fieldset>
        </div>
    </div>
</div>